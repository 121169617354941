var namespace = "Season.Store:: "

var currentIndex = 0;

export default {
    namespaced: true,
    state: {
        currentIndex: 0,
        currentSeason: null,
        showTakeover: false,
        showWrapup: false,
        seasons: [],

    },
    getters: {
        currentIndex: state => state.currentIndex,
        currentSeason: state => state.currentSeason,
        showTakeover: state => state.showTakeover,
        showWrapup: state => state.showWrapup,
        seasons: state => state.seasons,
    },
    mutations: {
        load(state){
            state.seasons = require("@/assets/data/game/seasons.json");
            state.currentSeason = state.seasons[0];
        },
        increment(state){
            if (state.currentIndex < state.seasons.length - 1){
                state.currentIndex++;
            }
            else{
                state.showWrapup = true;
            }
            state.currentSeason = state.seasons[state.currentIndex];
            //console.log("currentSeason: " + state.currentSeason);

            state.showTakeover = true;
        },

        setTakeover(state, data){
            state.showTakeover = data;
        },

        reset(state){
            state.currentIndex = 0;
            state.currentSeason = state.seasons[state.currentIndex];
            state.showTakeover = false;
            state.showWrapup = false;
        }
    },

    actions: {
        load({commit}, data){
            commit('load', data);
        },
        increment({commit}){
            commit('increment');
        },

        setTakeover({commit}, data){
            commit('setTakeover', data);
        },

        reset({commit}){
            commit('reset');
        },

    }
}
