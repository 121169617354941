var namespace = "Orientation.Store:: "


export default {
    namespaced: true,
    state: {
        requiresLandscapeLock: false,
        locked: false,
        fullScreen: false,
    },
    getters: {
        requiresLandscapeLock: state => state.requiresLandscapeLock,
        locked: state => state.locked,
        fullScreen: state => state.fullScreen,
    },
    mutations: {
        setRequirement(state, data){
            state.requiresLandscapeLock = data;
        },
        setLock(state, data){
            state.locked = data;
        },
        setFullScreen(state, data){
            state.fullScreen = data;
        }
    },

    actions: {
        requireLock({commit}, data){
            commit('setRequirement', data);
        },
        setLock({commit}, data){
            commit('setLock', data);
        },
        fullScreen({commit}, data){
            commit('setFullScreen', data);
        },
    }
}
