<template>
  <div id="app" ref="app" @mousedown="interact" @touchstart="interact" v-bind:class="{'game-view': !isSiteRoute}">
    <Nav v-if="isSiteRoute"></Nav>
    <main id="main">
      <router-view></router-view>
    </main>
    <Footer v-if="isSiteRoute"></Footer>
    <vue-announcer />
  </div>
</template>

<script>
import Nav from '@/components/Nav';
import Footer from '@/components/Footer';

import { mapGetters } from "vuex"

export default {
  name: 'App',
  components: {
      Nav,
      Footer
  },
  watch: {
    '$route.name' (newRoute, oldRoute) {
      this.setLock(newRoute);
    }
  },
  computed: {
    ...mapGetters({
        interactedOnce: "global/interactedOnce",
    }),
    isSiteRoute () {
      return !this.$route.path.includes('game');
    }
  },
  methods: {
    setLock (route) {
       let requiresLandscape = [
        'ecosystem',
        'introduction',
        'conclusion',
      ]

      if (requiresLandscape.includes(route)) {
        const match = window.matchMedia('(pointer:fine)');
        if (match && !match.matches) {
          this.$store.dispatch('orientation/requireLock', true)
        }
      } else {
          this.$store.dispatch('orientation/requireLock', false)
      }

    },
    interact(){
      if (!this.interactedOnce){
        this.$store.dispatch('global/setInteractedOnce', true)
      }
    }
  },
  mounted(){
    this.$setAppElement(this.$refs.app);

    document.onkeydown = (evt) => {
      if (evt.key === "t") {
        this.$captureAndDownload("moose-capture-test.jpg");
      }
    }

    this.setLock(this.$route.name)
  }
}
</script>

<style lang="scss">

html, body {
   width: 100%;
   height: 100%;
} 


#app {
  font-family: $font-family-body;

  // &.game-view { 
  //   margin: 0; overflow: hidden; 
  //   height: 100%;

  //   #main {
  //     height: 100%;
  //   }
  // }
}

</style>
