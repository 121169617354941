<template>
  <div class="mooseChart">
      <figure class="chartGroup">
        <Chart class="chart" :chartData="chartData" :chartOptions="chartOptions" ratioClass="ct-double-octave"/>
        <figcaption class="sr-only">{{$g("acc-graph-intro-text")}} {{ graphDescription }} </figcaption>
      </figure>
      <div class="graphLine yAxis"></div>
      <div class="graphLine xAxis"></div>
      <div class="healthyRange"></div>
      <div class="offset">
        <label class="healthyLabel">{{$g("graph-healthy-label")}}</label>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Chart from "@/components/Chart"
import Chartist from 'chartist';
import ctAxisTitle from 'chartist-plugin-axistitle'

export default {
  components: { Chart },
  name: 'MooseChart',
  props:{
      Chart
  },
    data(){
        return{
            chartData : {
            labels: [],
            series:[]
        }
        }
    },
  computed:{
      ...mapGetters({
           populationData : "ecosystem/populationOverTime",
      }),
      graphDescription () {
        if (!this.populationData) return '';
        let springData = this.trendText(this.populationData.spring);
        let summerData = this.trendText(this.populationData.summer);
        let fallData = this.trendText(this.populationData.fall);
        let winterData = this.trendText(this.populationData.winter);
        return  `${this.$g('acc-graph-spring-intro')} ${springData}, ` +
                `${this.$g('acc-graph-summer-intro')} ${summerData}, ` +
                `${this.$g('acc-graph-fall-intro')} ${fallData}, ` +
                `${this.$g('acc-graph-winter-intro')} ${winterData} `;
      },
      chartOptions(){
          return {
            height: "120px",
            showPoint: false,
            low: 0,
            high: 25,
            axisX: {
                showGrid: false,
                showLabel: true,
                labelOffset: {
                    x: 0,
                    y: 5
                },
            },
            axisY: {
                showGrid: false,
                showLabel: false,
            },
            plugins: [
                Chartist.plugins.ctAxisTitle({
                    axisY: {
                        axisTitle: this.$g("graph-y-axis"),
                        axisClass: "y-axis-label",
                        offset: {
                            x: 0,
                            y: 35
                        },
                        flipTitle: true
                    }
                })
            ]
        }
      }
  },
  methods: {
    trendText(data) {
      let length = data.length;
      if (!length) return 'healthy';
      let healthyRange = [12,23]

      let healthyNums = data.filter(num => (num >= healthyRange[0]) && (num <= healthyRange[1]));
      let underNums = data.filter(num => num < healthyRange[0]);
      let overNums = data.filter(num => num > healthyRange[1]);

      let healthyRate = healthyNums.length / length;
      let overRate = overNums.length / length;
      let underRate = underNums.length / length;
      
      let rates = [{
          status: 'healthy',
          rate: healthyRate
        }, {
          status: 'low',
          rate: underRate
        }, {
          status: 'over',
          rate: overRate
        },
      ]

      let mostData = Math.max(healthyRate, underRate, overRate);
      let rate = rates.find(r => r.rate == mostData);
      let status = rate ? rate[status] : 'healthy';

      let direction = data[0] < data[length - 1] ? 'going up' : 'going down';
      if (data[0] == data[length -1]) direction = 'stayed the same';

      return ` ${status} and ${direction}`
    }
  },
  mounted(){
      var pop = this.populationData;
      // pop = {"spring":[2,2,2,2,5,5,8,7,8,9,9,9,5,5],"summer":[5,5,5,5,12,12,8,8,8,11,11,12,12,13,13,13,10,8,8,7,7],"fall":[4,23,23,23,28,26,16,5,3],"winter":[2,5,5,5,2,2,5,5,5,3,3]};

      var labels = [];
      var series = [];

      // console.log(JSON.stringify(pop));

      if (pop){
        for (var season in pop){
            var seasonData = pop[season];
            for (var i = 0; i < seasonData.length; i++){
                if (i === 0){
                    labels.push(season);
                }
                else{
                    labels.push("");
                }
                series.push(seasonData[i]);
            }
        }
      }
      else{
        labels = ["spring" , "summer", "fall", "winter"];
        series = [0, 0, 0, 0];
      }

      this.chartData.labels = labels;
      this.chartData.series.push(series);

  }

}
</script>

<style lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

$chart-width: calc(90% - 70px);
$chart-height: 120px;
$third-height: 30px;

  .mooseChart{
      width: 100%;
      height: $chart-height;
      margin-left: -25px;
    
      .chart{
        position: absolute;
      }
  }

  .chartGroup{
    margin: 0;
    position: absolute;
    width: 90%;
  }

  .ct-labels span, .y-axis-label {
    color: $color-black;
    font-family: $font-family-header;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.1px;
  }

  .chart-container {
    width: 100%;
  }

  .ct-grids line {
    stroke: $color-black;
    stroke-dasharray: 0;
    opacity: .25;
  }

  .ct-series-a .ct-line{
    stroke: $color-orange;
  }

  .ct-labels span {
    color: $color-black;
  }

  .graphLine{
    position: absolute;
    margin-left: 50px;
    background-color: $color-medium-grey;

    &.yAxis{
      width: 2px;
      height: 85px;
    }

    &.xAxis{
      width: $chart-width;
      height: 2px;
      margin-top: 85px;
    }
  }

 .healthyRange{
   position: absolute;
   margin-left: 50px;
   margin-top: $third-height;
   width: $chart-width;
   height: $third-height;
   background-color: $color-medium-green;
   opacity: .5;
 }

 .offset{
   position: absolute;
   left: $chart-width;

   .healthyLabel{
     position: absolute;
     font-size: 15px !important;
     color: $color-black !important;
     margin-left: 60px;
     margin-top: 37px;
   }
  }

</style>
