<template>
  <div class="parasites">
      <img alt="parasites" v-for="i in 3" :class="`opacity-${i}`" :key="'parasites' + i" :src="getImage(i)" :style="[{width: width + 'px'}, {height: height + 'px'}]" />
  </div>
</template>


<script>

import Assets from "@/components/game/utils/assets"

export default {
    name: 'Moose',
    props:{
        width: Number,
        height: Number,
    },
    data(){
        return{
            currentIndex: 1,
        }
    },
    methods: {
        getImage(index){
          return Assets.load("ecosystem", "parasites-" + index, "png")
        }
    }
}
</script>

<style scoped lang="scss">

.parasites{
    position: absolute;
}
img {
  position: absolute;
  pointer-events: none;

  &.opacity-1 {
    opacity: 0;
    animation: fadeOne 450ms infinite alternate ease-in-out both;    
  }
  &.opacity-2 {
    opacity: 0;
    animation: fadeTwo 450ms infinite alternate ease-in-out both;
  }
  &.opacity-3 {
    opacity: 0;
    animation: fadeThree 450ms infinite alternate ease-in-out both;
  }
}

@keyframes fadeOne {
    0% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeTwo {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeThree {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

</style>
