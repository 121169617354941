/**
 * Root: Provide global `$root` to get the current project root.
 */
 export default {
    install (Vue) {
        Vue.prototype.$rootUrl = function() {
            return process.env.VUE_APP_PUBLIC_PATH || '/'
        }
    }
}
