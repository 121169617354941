<template>
    <div>
        <figure :id="'video-' + index">
            <iframe aria-live="polite" width="560" height="315" :src="`https://www.youtube.com/embed/${selectedVideoUrl}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </figure>
        <div>
            <span class="text-green audio-label"> {{ video_content.audio }}</span>
            <button 
                class="button-link text-green" 
                :class="{'selected': !displayAllyVideo}"
                @click="toggleAllyVideo">
                {{ video_content.audio_on }}
            </button>
            <span class="text-green audio-label">/</span>
            <button 
                class="button-link text-green" 
                :class="{'selected': displayAllyVideo}"
                @click="toggleAllyVideo">
                {{ video_content.audio_off }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoPlayer',
    props: {
        'video_content': Object,
        'video': Object,
        'index': Number
    },
    data () {
        return {
            displayAllyVideo: false
        }
    },
    computed: {
        selectedVideoUrl () {
            return this.displayAllyVideo ? this.video.audio_url_id : this.video.url_id;
        }
    },
    methods: {
        toggleAllyVideo () {
            this.displayAllyVideo = !this.displayAllyVideo;
        }
    }

}
</script>

<style scoped lang="scss">
@import "@/styles/microsite.scss";

    figure {
        margin: 0;
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        
        iframe {
            background: $color-blue-black;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border: 0;
        }

        @media screen and (max-width: $mq-max-width-mobile) {
            iframe {
                width: 100%;
                height: 100%;
                max-width: 375px;
                max-height: 180px;
            }
        }
    }

    .audio-label {
        font-size: $small-text2;
    }

    .button-link {
        font-size: $small-text2;
        background: none;
        border: none;
        outline: none;
        
        &.selected {
            text-decoration: underline;
        }
    }
</style>
