<template>
  <button :class="['captionsButton', 'simple']" @click="toggle" ref="captionToggle" aria-pressed="false" >
    <span class="cc-icon" :class="{'on': showCaptions}">CC</span>
    <span class="sr-only">{{$g("acc-captions-button")}}</span>
  </button>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"


export default {
  name: 'CaptionsButton',
  computed:{
    ...mapGetters({
        showCaptions: "global/captions",
    }),
    noCaptions() {
      return true;
    }
  },
  methods: {
    toggle(){
      this.$refs.captionToggle.setAttribute('aria-pressed', !this.showCaptions);
      this.$store.dispatch("global/setCaptions", !this.showCaptions);
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.captionsButton{
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 10px;
  left: 200px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $color-teal !important;
  border-radius: 50%;

  .cc-icon {
    color: $color-aqua;
    font-size: 18px;

    &.on {
      color: $color-white;
      background: $color-aqua;
      border-radius: 3px;
      padding: 0 5px;
    }
  }

  @media screen and (max-width: $mq-max-width-tablet) {
    left: 10px;
    bottom: 98px;
    height: 40px;
    width: 40px;
  }
}


</style>
