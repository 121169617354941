import { indexOf, splice } from "core-js/fn/array";
import { random } from "core-js/fn/number";

var namespace = "Ecosystem.Store:: "

var elementTypes;
var mooseTypes;

var events = [];

var eventSequence = {};
var playedEvents = [];
var currentSeason = "";
var midSeasonIndex = 0;

//multiPhaseEvent
var MPEinProgress = false;
var MPEstartPlayCount = 0;
var MPEdelayCount = 4;
var MPEindex = 0;
var MPEactions = [];
var MPEid = "";

var minBulls = 0;
var minCows = 0;
var maternityRate = .8;
var twinRate = .333;

var currentBulls = 0;
var currentCows = 0;

var eventIndex = 0



var previousElementPopulation = {};

function limit(num, min, max){
    return Math.max(min, Math.min(max, num));
}

function addAdultMoose(count, list){
    for (var i = 0; i < count; i++){
        if (currentBulls < minBulls){
            currentBulls++;
            list.push(mooseTypes.bull);
        }
        else if (currentCows < minCows){
            currentCows++;
            list.push(mooseTypes.cow);
        }
        else{
            if (Math.random() < .5){
                currentBulls++;
                list.push(mooseTypes.bull);
            }
            else{
                currentCows++;
                list.push(mooseTypes.cow);
            }
        }
    }
    return list;
}

function addBabyMoose(count, list){
    for (var i = 0; i < count; i++){
        list.push(mooseTypes.calf);
    }
    return list;
}

function removeRandom(list){
    var random = Math.floor(Math.random() * list.length);
    return list.splice(random, 1);
}

function getEventObject(id){
    for (var i = 0; i < events.length; i++){
        if (events[i].id === id){
            return events[i];
        }
    }

    return null;
}

function filterOutPlayedEvents(events){
    var filtered = [];
    for (var i = 0; i < events.length; i++){
        if (playedEvents.indexOf(events[i]) === -1){
            filtered.push(events[i])
        }
    }

    return filtered;
}


export default {
    namespaced: true,
    state: {
        error: false,
        error_msg: null,
        loading: false,
        loaded: false,

        eventPlayCount: 8,
        lastEventInSeason: false,

        moosePopulation: [],
        mooseTypes: null,
        minMoose: 0,
        minBulls: 0,
        minCows: 0,
        minTargetMoose: 10,
        maxTargetMoose: 20,
        maxMoose: 30,

        populationOverTime: null,

        playCount: 0,

        elementPopulation: {},

        //should be objects eventually
        currentEvent: null,
        showEvent: false,
        currentElementTypes: [],
        accEventDescription: "",

        deerParasiteInfectionRate: 0,
        mooseParasiteInfectionRate: 0,
        mooseParasiteDeathRate: 0,
        wolfVirusInfectionRate: 0,
        wolfVirusDeathRate: 0,

        earnedBadges: [],
        latestBadge: null,
        showBadgeAlert: false,

        screenshot: null,

        unroundedMoose: 0,
        moosePopHealth: ''
    },
    getters: {
        error: state => state.error,
        error_msg: state => state.error_msg,
        loading: state => state.loading,
        loaded: state => state.loaded,

        eventPlayCount: state => state.eventPlayCount,
        lastEventInSeason: state => state.lastEventInSeason,

        moosePopulation: state => state.moosePopulation,
        mooseTypes: state => state.mooseTypes,
        minMoose: state => state.minMoose,
        minBulls: state => state.minBulls,
        minCows: state => state.minCows,
        minTargetMoose: state => state.minTargetMoose,
        maxTargetMoose: state => state.maxTargetMoose,
        maxMoose: state => state.maxMoose,

        populationOverTime: state => state.populationOverTime,

        playCount: state => state.playCount,

        elementPopulation: state=> state.elementPopulation,

        deerParasiteInfectionRate: state => state.deerParasiteInfectionRate,
        mooseParasiteInfectionRate: state => state.mooseParasiteInfectionRate,
        mooseParasiteDeathRate: state => state.mooseParasiteDeathRate,
        wolfVirusInfectionRate: state => state.wolfVirusInfectionRate,
        wolfVirusDeathRate: state => state.wolfVirusDeathRate,

        currentEvent: state => state.currentEvent,
        showEvent: state => state.showEvent,
        eventType: state => state.eventType,
        accEventDescription: state => state.accEventDescription,

        currentElementTypes: state => state.currentElementTypes,

        badges: state => state.badges,
        earnedBadges: state => state.earnedBadges,
        latestBadge: state => state.latestBadge,
        showBadgeAlert: state => state.showBadgeAlert,

        screenshot: state => state.screenshot,
        moosePopHealth: state => state.moosePopHealth

    },
    mutations: {
        load(state){
            console.log("----- LOADING -----");

            eventSequence = require("@/assets/data/game/event-sequence.json");
            events = require("@/assets/data/game/events.json");
            elementTypes = require("@/assets/data/game/elements.json");
            state.mooseTypes = require("@/assets/data/game/moose.json");
            mooseTypes = require("@/assets/data/game/moose.json");
            state.badges = require("@/assets/data/game/badges.json");

            minBulls = mooseTypes.bull.minPop;
            minCows = mooseTypes.cow.minPop;

            state.minBulls = minBulls;
            state.minCows = minCows;
            state.minMoose = minBulls + minCows;

            //console.log(JSON.stringify(state.badges));
        },

        addDraggable(state, data){
            state.elementPopulation[data.type]++;

            state.deerParasiteInfectionRate = 0;
            state.mooseParasiteInfectionRate = 0;
            state.mooseParasiteDeathRate = 0;
            state.wolfVirusInfectionRate = 0;
            state.wolfVirusDeathRate = 0;

            if (data.type === "wolf" || data.type === "deer"){
              var nextDeerPop = state.elementPopulation["deer"];
              if (Math.random() < .15 * Math.min(state.elementPopulation["wolf"], 5)){
                nextDeerPop--;
              }
              state.elementPopulation["deer"] = Math.max(0, nextDeerPop);
            }

            if (data.type === "wolf"){
              if (Math.random() < .08 * Math.min(state.elementPopulation["wolf"], 5)){
                removeRandom(state.moosePopulation);
                state.unroundedMoose--;
              }
            }


            // if event with multiple actions is in progress
            // trigger an action 
            if (!data.initial){
                state.playCount++;
                if (MPEinProgress && state.playCount === MPEstartPlayCount + MPEdelayCount * MPEindex){

                    var action = MPEactions[MPEindex]

                    for (var j = 0; j < action.length; j++){
                        var change = action[j];

                        var type = change.elementType;
                        var operation = change.operation;
                        var value = change.value;

                        // console.log("---Action---");
                        // console.log("type: " + type);
                        // console.log("operation: " + operation);
                        // console.log("value: " + value);

                        if (type !== "moose"){
                            if (operation === "multiply"){
                                //console.log("updated population of " + type + ": " + state.elementPopulation[type]);
                                state.elementPopulation[type] = Math.floor(state.elementPopulation[type] * value);

                            }
                            else if (operation === "add"){
                                //console.log("updated population of " + type + ": " + state.elementPopulation[type]);
                                state.elementPopulation[type] = Math.floor(state.elementPopulation[type] + value);
                            }
                        }
                    }

                    if (type === "deer" && operation === "parasiteInfection"){
                        state.deerParasiteInfectionRate = value;
                    }
                    else if (type === "moose" && operation === "parasiteInfection"){
                        state.mooseParasiteInfectionRate = value;
                    }
                    else if (type === "moose" && operation === "parasiteDeath"){
                        state.mooseParasiteDeathRate = value;
                    }
                    else if (type === "wolf" && operation === "virusInfection"){
                        state.wolfVirusInfectionRate = value;
                    }
                    else if (type === "wolf" && operation === "virusDeath"){
                        state.wolfVirusDeathRate = value;
                    }

                    if (MPEindex + 1 >= MPEactions.length){
                        MPEinProgress = false;
                        setTimeout(() => {
                          playedEvents.push(MPEid);
                        }, 2000);

                    }
                    else{
                        MPEindex++;
                    }
                }
            }
        },
        removeDraggable(state, data){
            state.elementPopulation[data.type]--;
            //state.playCount++;
        },
        removeMoose(state, data){
            //console.log("removing moose type: " + data.type);
            // console.log(JSON.stringify(state.moosePopulation));
            for (var i = 0; i < state.moosePopulation.length; i++){
                if (state.moosePopulation[i].type === data.type){
                    state.moosePopulation.splice(i, 1);
                }
            }
            state.unroundedMoose = state.moosePopulation.length;

            //console.log("moosePopulation: " + state.moosePopulation.length);
        },
        resetPlayCount(state){
            state.playCount = 0;
        },

        reset(state){
            console.log("----- RESETTING -----");
            state.playCount = 0;
            state.eventPlayCount = 8;
            state.moosePopulation = [];
            if (elementTypes){
                for (var i = 0; i < elementTypes.length; i++){
                    state.elementPopulation[elementTypes[i].type] = 0;
                }
            }

            state.currentEvent = null;
            state.lastEventInSeason = false;
            state.currentElementTypes = [];
            currentSeason = "";
            playedEvents = [];

            MPEinProgress = false;
            MPEstartPlayCount = 0;
            MPEdelayCount = 4;
            MPEindex = -1;
            MPEactions = [];
            MPEid = "";

            state.earnedBadges = [];

            state.populationOverTime = {};
            state.populationOverTime.spring = [];
            state.populationOverTime.summer = [];
            state.populationOverTime.fall = [];
            state.populationOverTime.winter = [];

            previousElementPopulation = {};
            state.unroundedMoose = 0;
            currentBulls = 0;
            currentCows = 0;

            eventIndex = 0;
        },

        setEvent(state, data){

            state.showEvent = data.show;
            if (state.showEvent){
              eventIndex++;

              state.accEventDescription = "";

                if (midSeasonIndex === -1){
                    currentSeason = data.season;
                    midSeasonIndex = 0;
                    state.playCount = 0;
                    state.lastEventInSeason = false;
                }
                else{
                    midSeasonIndex++;
                    state.playCount = 0;
                }
                var seasonalEvents = eventSequence[currentSeason];

                if (!seasonalEvents){
                  console.log("could not find seasonal events, will not continue...")
                  return;
                }

                if (seasonalEvents && midSeasonIndex >= seasonalEvents.length - 1){
                    state.lastEventInSeason = true;
                }

                var possibleEvents = seasonalEvents[midSeasonIndex];


                var filteredEvents = filterOutPlayedEvents(possibleEvents);

                if (!filteredEvents){
                  console.log("filtered events returned null, will not continue...");
                  return;
                }

                var random = Math.random() * filteredEvents.length;
                var eventId = filteredEvents[Math.floor(random)];

                if (filteredEvents.indexOf("more-wolves") !== -1 && state.elementPopulation["wolf"] === 0){ //forces wolf event if no wolf have been added players can see effects of wolf virus
                  console.log("no wolves in scene, forcing wolf event...");
                  eventId = "more-wolves";
                }

                if (eventId === "hunting-season" && state.elementPopulation["deer"] === 0){
                  console.log("no deer to hunt, skipping 'hunting-season' event...")
                  playedEvents.push("hunting-season");
                  filteredEvents = filterOutPlayedEvents(possibleEvents);
                  random = Math.random() * filteredEvents.length;
                  eventId = filteredEvents[Math.floor(random)];
                }

                var event = getEventObject(eventId);

                if (event === null){
                    console.log("event is null, will not continue...");
                    return;
                }
                else{

                    if (event.totalMoves){
                        state.eventPlayCount = event.totalMoves;
                    }
                    if (event.movesPerAction && event.actions){
                        state.eventPlayCount = event.movesPerAction * event.actions.length;
                    }
                }

                // console.log("event: " + eventId);
                // console.log("playedEvents: " + JSON.stringify(playedEvents));

                if (event.actions){
                    var actions = event.actions;

                    if (actions.length > 1){
                        MPEinProgress = true;
                        MPEstartPlayCount = state.playCount;
                        MPEindex = 1;
                        MPEactions = actions;
                        MPEstartPlayCount = state.playCount;
                        MPEid = eventId;
                    }
                    else{
                      playedEvents.push(eventId);
                    }

                    if (actions.length > 0){
                        var action = actions[0]

                        for (var j = 0; j < action.length; j++){
                            var change = action[j];

                            var type = change.elementType;
                            var operation = change.operation;
                            var value = change.value;

                            // console.log("---Action---");
                            // console.log("type: " + type);
                            // console.log("operation: " + operation);
                            // console.log("value: " + value);
                            var diff;
                            var nextValue;


                            if (type !== "moose" && type !== "moose-calf"){
                                if (operation === "multiply"){
                                    //console.log("updated population of " + type + ": " + state.elementPopulation[type]);
                                    nextValue = Math.floor(state.elementPopulation[type] * value);
                                    if (nextValue > state.elementPopulation[type]){
                                      diff = nextValue - state.elementPopulation[type];
                                      state.accEventDescription += diff + " " + type + " added to habitat ";
                                    }
                                    else{
                                      diff = state.elementPopulation[type] - nextValue;
                                      state.accEventDescription += diff + " " + type + " removed from habitat ";
                                    }
                                    state.elementPopulation[type] = nextValue;


                                }
                                else if (operation === "add"){
                                    //console.log("updated population of " + type + ": " + state.elementPopulation[type]);
                                    nextValue = Math.floor(state.elementPopulation[type] + value);

                                    if (nextValue > state.elementPopulation[type]){
                                      diff = nextValue - state.elementPopulation[type];
                                      state.accEventDescription += diff + " " + type + " added to habitat ";
                                    }
                                    else{
                                      diff = state.elementPopulation[type] - nextValue;
                                      state.accEventDescription += diff + " " + type + " removed from habitat ";
                                    }
                                    nextValue = Math.floor(state.elementPopulation[type] + value);
                                    state.elementPopulation[type] = nextValue;
                                }
                            }

                            if (type === "moose"){
                              if (operation === "multiply"){
                                state.unroundedMoose *= value;
                              }
                              else if (operation === "add"){
                                state.unroundedMoose += value;
                              }

                              let oldMoosePopulation = state.moosePopulation.length;
                              if (state.unroundedMoose > state.moosePopulation.length){
                                  state.moosePopulation = addAdultMoose(state.unroundedMoose - state.moosePopulation.length, state.moosePopulation);
                                  let diff = Math.abs(oldMoosePopulation - state.moosePopulation.length);
                                  state.accEventDescription += diff + " moose added to habitat ";
                                }
                                else if (state.unroundedMoose < state.moosePopulation.length){
                                    var removeCount = state.moosePopulation.length - state.unroundedMoose;
                                    for (var n = 0; n < removeCount; n++){
                                        removeRandom(state.moosePopulation);
                                    }
                                    state.accEventDescription += Math.ceil(removeCount) + " moose removed from habitat ";
                              }
                            }

                            if (type === "moose-calf"){
                                value = currentCows * maternityRate * (1 + twinRate);
                                //console.log("MOOSE CALF: " + Math.round(value));
                                state.moosePopulation = addBabyMoose(Math.round(value), state.moosePopulation);
                                state.accEventDescription += Math.round(value) + " moose calves added to habitat ";
                            }
                            if (type === "deer" && operation === "parasiteInfection"){
                                state.deerParasiteInfectionRate = value;
                            }
                            else if (type === "moose" && operation === "parasiteInfection"){
                                state.mooseParasiteInfectionRate = value;
                            }
                            else if (type === "moose" && operation === "parasiteDeath"){
                                state.mooseParasiteDeathRate = value;
                            }
                            else if (type === "wolf" && operation === "virusInfection"){
                                state.wolfVirusInfectionRate = value;
                            }
                            else if (type === "wolf" && operation === "virusDeath"){
                                state.wolfVirusDeathRate = value;
                            }
                        }
                    }

                    state.currentEvent = event;
                }
            }
        },

        setBadge(state, data){
            state.showBadge = data;
        },

        calculateMoose(state, data){
            //console.log("calculating moose...");
            var start = state.unroundedMoose;

            if (data && data.initial){
              start = state.moosePopulation.length;
            }

            for (var i = 0; i < state.currentElementTypes.length; i++){
              var element = state.currentElementTypes[i];
              if (element.type !== "wolf" && element.type !== "deer"){
                start += element.populationEffect * Math.min(state.elementPopulation[element.type], 5);
              }
            }

            state.unroundedMoose = limit(start, state.minMoose, state.maxMoose);
            var nextMoosePop = Math.round(state.unroundedMoose);


            if (nextMoosePop > state.moosePopulation.length){
                state.moosePopulation = addAdultMoose(nextMoosePop - state.moosePopulation.length, state.moosePopulation);
            }
            else if (nextMoosePop < state.moosePopulation.length){
                var removeCount = state.moosePopulation.length - nextMoosePop;
                for (var j = 0; j < removeCount; j++){
                    removeRandom(state.moosePopulation);
                }
            }

            if (!data || !data.initial){
                if (currentSeason === ""){
                    state.populationOverTime.spring.push(state.moosePopulation.length);
                }
                else{
                    state.populationOverTime[currentSeason].push(state.moosePopulation.length);
                }
                //console.log(JSON.stringify(state.populationOverTime));
            }
            //console.log("moose population: " + state.moosePopulation.length);
        },

        updateCurrentElements(state){
            var updated = [];
            for (var i = 0; i < elementTypes.length; i++){
                updated.push(elementTypes[i]);
            }

            state.currentElementTypes = updated;
        },

        checkForBadges(state){
            //console.log(JSON.stringify(state.badges));

            for (var i = 0; i < state.badges.length; i++){
                var badge = state.badges[i];

                if (badge.seasons.indexOf(currentSeason) !== -1 && badge.levels){
                    var levels = badge.levels;

                    var allTrue = true;

                    if (badge.requiredEvent){
                      if (playedEvents.indexOf(badge.requiredEvent) === -1){
                        allTrue = false;
                      }
                    }

                    for (var j = 0; j < levels.length; j++){
                        var level = levels[j];
                        var type = level.elementType;
                        var value = level.value;
                        var added = level.added;
                        // console.log("current aspen: " + state.elementPopulation["aspen"]);

                        if (type === "moose-final"){
                            if (state.moosePopulation.length >= 20) {
                                allTrue = false;
                            }
                            if (state.moosePopulation.length <= 11) {
                                allTrue = false;
                            }
                        } else if (type === "moose"){
                            if (state.moosePopulation.length < value){
                                allTrue = false;
                            }
                        }
                        else if (added && previousElementPopulation && previousElementPopulation[type]){
                          var currentAdded = state.elementPopulation[type] - previousElementPopulation[type];
                          if (state.elementPopulation[type] - previousElementPopulation[type] < added){
                            allTrue = false;
                          }
                        }
                        else{
                            if (state.elementPopulation[type] < value){
                                allTrue = false;
                            }
                        }
                    }

                    if (allTrue && levels.length > 0 && state.earnedBadges.indexOf(badge) === -1){
                        //console.log("earned badge! " + badge.id);

                        state.earnedBadges.push(badge);

                        //empty text fields are being added to state.earnedBadges somewhere. i have no idea how, but this fixes it.
                        var clean = [];
                        for (var n = 0; n < state.earnedBadges.length; n++){
                            if (state.earnedBadges[n] !== ""){
                                clean.push(state.earnedBadges[n]);
                            }
                        }
                        state.earnedBadges = clean;

                        //console.log("earnedBadges:" + JSON.stringify(state.earnedBadges));
                        state.latestBadge = badge;
                        state.showBadgeAlert = true;

                        setTimeout(() => {
                            state.showBadgeAlert = false;
                          }, 5000);
                    }
                }
            }
        },

        setScreenshot(state, data){
            // console.log("screenshot set!");
            state.screenshot = data.screenshot;
        },

        setLoading(state, status) {
            state.loading = status;
        },
        setLoaded(state, status) {
            state.loaded = status;
        },

        setError(state, errorMessage){
            state.error = true;
            state.error_msg = errorMessage;
        },

        setSeason(state, data){
          currentSeason = data;
          midSeasonIndex = -1;
          state.playCount = 0;
          state.lastEventInSeason = false;

          var index = 0;
          for (var type in state.elementPopulation){
            previousElementPopulation[type] = state.elementPopulation[type];
            // console.log("previousElementPopulation " + type + ": " + state.elementPopulation[type]);
            index++;
          }
        },

        setMooseHealth(state,data) {
            state.moosePopHealth = data;
        }
    },

    actions: {
        load({commit}){
            commit('setLoaded', false);
            commit('setLoading', true);

            commit('load');

            commit('setLoading', false);
            commit('setLoaded', true)
        },

        addDraggable({commit}, data){
            commit('addDraggable', data);

            if (data.type !== "wolf" && data.type !== "deer"){
              commit('calculateMoose', data);
            }
            commit('checkForBadges');
        },
        updateDraggable({commit}, data){
            commit('updateDraggable', data);
        },
        removeDraggable({commit}, data){
            commit('removeDraggable', data);
            commit('calculateMoose');
        },
        removeMoose({commit}, data){
            commit('removeMoose', data);
        },

        resetPlayCount({commit}){
            commit('resetPlayCount');
        },
        reset({commit}){
            commit('setLoaded', false);
            commit('setLoading', true);
            commit('setScreenshot', {data: null});

            commit('reset');
            commit('updateCurrentElements');
            commit('calculateMoose');

            setTimeout(() => {
                commit('setLoading', false);
                commit('setLoaded', true);
            }, 10);


        },

        setEvent({commit}, data){
            commit('setEvent', data);
            commit('updateCurrentElements');
            //if (data.show){
                commit('calculateMoose', {initial : true});
            //
            //commit('resetPlayCount');
        },

        hideEvent({commit, state}){
            state.showEvent = false;
        },

        setBadge({commit}, data){
            commit('setBadge', data);
        },

        setScreenshot({commit}, data){
            commit('setScreenshot', data);
        },
        setSeason({commit}, data){
            commit('setSeason', data);
        },
        setMoosePopHealth({commit}, data){
            commit('setMooseHealth', data);
        },
    }
}
