<template>
  <div class="hint">
      <transition appear name="fade">
          <label v-if="show">
              <div class="content shadow">
                <p> {{ $g("bar-hint")}} </p>
              </div>
              <div class="triangle"></div>
          </label>
      </transition>
  </div>
</template>

<script>


export default {
  name: 'Hint',
  props: {
    show: Boolean
  },
  watch: {
    show () {
      if (this.show) {
        this.$announcer.set(this.$g("bar-hint"));
      }
    }
  }

}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.hint{
  position: absolute;
  z-index: 9999;

  label{
    position: absolute;
    left: 50vw;
    width: 200px;
    transform: translateX(-50%);
    bottom: 110px;
    letter-spacing: 0px !important;

    .content{
      padding: 10px;
      background-color: $color-white;
      border-radius: 10px;
    }

    p{
      font-size: 16px !important;
      color: $color-teal !important;
      text-transform: none !important;
      text-align: center;
      margin: 0px;
      padding: 5px 10px 0px 10px;
    }
    .triangle{
      width: 0;
      height: 0;
      margin-left: 50%;
      transform: translateX(-50%);
      border-style: solid;
      border-width: 12px 10px 0 10px;
      border-color: $color-white transparent transparent transparent;
    }
  }

  @media screen and (max-width: $mq-max-width-tablet) {
    label {
      bottom: 80px;
      width: 300px;
    }
  }
  
  @media screen and (max-height: 600px) {
    label {
      bottom: 60px;
    }
  }
}

</style>
