import Vue from 'vue'
import Vuex from 'vuex'
import globalStore from './global.js'
import ecosystemStore from './ecosystem.js'
import seasonStore from './season.js'
import orientationStore from './orientation.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    namespaced: true,
    modules:{
        global: globalStore,
        ecosystem: ecosystemStore,
        season: seasonStore,
        orientation: orientationStore,
    }
})
