import Vue from 'vue'
import VueRouter from 'vue-router'

import Index from '../views/Index.vue'
import Moose from '../views/Moose.vue'
import Research from '../views/Research.vue'
import Introduction from '../views/game/Introduction.vue'
import Ecosystem from '../views/game/Ecosystem.vue'
import Conclusion from '../views/game/Conclusion.vue'
import NotFound from '../views/NotFound.vue'

Vue.use(VueRouter)


const routes = [
    {
        path: `/`,
        name: 'home',
        component: Index,
    },
    {
        path: `/about-moose`,
        name: 'about-moose',
        component: Moose,
    },
    {
        path: `/research`,
        name: 'research',
        component: Research,
    },
    {
        path: `/game/introduction`,
        name: 'introduction',
        component: Introduction,
    },
    {
        path: `/game/ecosystem`,
        name: 'ecosystem',
        component: Ecosystem,
    },
    {
        path: `/game/conclusion`,
        name: 'conclusion',
        component: Conclusion,
    },
    {
        path: "*",
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router