<template>
  <div class="scene" v-if="ecosystemLoaded">
    <div class="creators">
        <h2 class="sr-only">{{ $g('acc_creator_button_title') }}</h2>
        <DraggableCreator v-for="(creator, i) in elementTypes"
                          :key="creator.id" :ref="creator.id"
                          :class="['draggableCreator', creator.id]"
                          childType="Draggable"
                          :creator="creator.creator"
                          :type="creator.type"
                          :model="elementTypes[i]"
                          :bus="bus" 
                          @addRandom="makeDraggableWithKeyboard"/>
    </div>

    <div class="elements" aria-describedby="ecosystem-desc">
      <h3 class="sr-only">{{ $g('acc_scene_title') }}</h3>
        <Draggable v-for="draggable in draggables"
                  :key="draggable.id"
                  :type="draggable.type"
                  :id="draggable.id"
                  :startX="draggable.startX"
                  :startY="draggable.startY"
                  :infectedWithParasites="draggable.infectedWithParasites"
                  :keySoundEffect="draggable.keySoundEffect"
                  :infectedWithVirus="draggable.infectedWithVirus"
                  :bus="bus"
                  :model="elementTypes[draggable.creatorIndex]"
                  :addedByEvent="draggable.addedByEvent"
                  :initial="draggable.initial"
                  :createdByKeyboard="draggable.createdByKeyboard"
                  @hold="holdHandler"
                  @click="playClick"
                  @drop="dropHandler"
                  @firstDrop="draggableAddedToScene(draggable.type, draggable.creatorIndex, draggable.id)">
        </Draggable>
        <!-- <transition-group  name="moose" tag="Moose"> -->
        <Moose v-for="moose in meese"
              :key="moose.id"
              :id="moose.id"
              :bus="bus"
              :type="moose.type"
              :startPosition="moose.startPosition"
              :infectedWithParasites="moose.infectedWithParasites"
              :keySoundEffect="moose.keySoundEffect"
              @hold="playClick"
              @removed="playRemoved"
              @added="playAdded"
              @addCowPosition="addCowPosition">
        </Moose>
        <!-- </transition-group> -->

    </div>

    <audio ref="clickSfx" :src="clickSource" preload />
    <audio ref="addSfx" :src="addSource"  preload/>
    <audio ref="removeSfx" :src="removeSource"  preload/>

  </div>
</template>

<script>
import DraggableCreator from "@/components/game/elements/DraggableCreator"
import Draggable from "./Draggable.vue"
import Moose from "@/components/game/elements/Moose"

import Assets from "@/components/game/utils/assets"

import Vue from "vue";

import { mapGetters } from "vuex"

export default {
  name: 'Ecosystem',
  components: {
    DraggableCreator,
    Draggable,
    Moose,
  },
  data(){
    return{
      draggables: [],
      meese: [],
      range: {x: 0, y: 200, width: 900, height: 450},

      bus: new Vue(),
      typeCount: 0,

      cowPositions: [], //array of {x, y} positions of moose cows to base positions of calves
      currentMom: 0,
      sceneStatus: "",
    }
  },
  computed:{
      ...mapGetters({
         ecosystemLoaded: "ecosystem/loaded",
         moosePopulation: "ecosystem/moosePopulation",
         elementPopulation: "ecosystem/elementPopulation",
         showEvent : "ecosystem/showEvent",
         elementTypes: "ecosystem/currentElementTypes",
         playCount: "ecosystem/playCount",
         deerParasiteInfectionRate: "ecosystem/deerParasiteInfectionRate",
         mooseParasiteInfectionRate: "ecosystem/mooseParasiteInfectionRate",
         mooseParasiteDeathRate: "ecosystem/mooseParasiteDeathRate",
         wolfVirusInfectionRate: "ecosystem/wolfVirusInfectionRate",
         wolfVirusDeathRate: "ecosystem/wolfVirusDeathRate",
         minBulls: "ecosystem/minBulls",
         minCows: "ecosystem/minCows",
         muted: "global/muted"
      }),
      mooseLength(){
        return this.moosePopulation.length;
      },
      clickSource(){
        return Assets.load("sfx", "ui_click", "mp3")
      },
      removeSource(){
        return Assets.load("sfx", "play_event_moose-remove", "mp3")
      },
      addSource(){
        return Assets.load("sfx", "play_event_moose-add", "mp3")
      },

  },
  methods:{
    clear(){
      this.draggables = [];
      this.meese = [];
      this.cowPositions = [];
      this.currentMom = 0;
      this.typeCount = 0;
    },
    playClick() {
      if (this.$refs.clickSfx && !this.muted){
        this.$refs.clickSfx.play();
      }
    },
    playAdded() {
      if (this.$refs.addSfx && !this.muted){
        this.$refs.addSfx.play();
      }
    },
    playRemoved() {
      if (this.$refs.removeSfx && !this.muted){
        this.$refs.removeSfx.play();
      }
    },
    holdHandler(type){
      this.bus.$emit("hideLabel", type);
    },
    dropHandler(type){
      this.bus.$emit("showLabel");
      let totalOfType = this.getDraggablesOfType(type).length;
      this.$announcer.set(`1 ${type} added to the habitat. There ${totalOfType <= 1 ? 'is' : 'are'} now ${totalOfType + 1}`)
    },
    getRandomPosition(){
        return { x : Math.floor(Math.random() * this.range.width) + this.range.x,
                 y : Math.floor(Math.random() * this.range.height) + this.range.y };
    },
    getPosition(index){

      var element = document.getElementsByClassName("draggableCreator")[index];
      if (element){
        var rect = element.getBoundingClientRect()
        return {x: rect.left, y: rect.top};
      }

      return {x: 0, y: 0}
    },
    makeStartDraggable(type, index){
      var creatorRef = type + "Creator";
      var position = this.getPosition(index)
      var draggable = this.makeDraggable(type, index, position);
      this.draggables.push(draggable);
    },
    makeDraggableWithKeyboard(type, index) {
      var position = this.getRandomPosition();
      var draggable = this.makeDraggable(type, index, position);
      draggable.createdByKeyboard = true;
      
      draggable.inScene = true;
      this.draggables.push(draggable);
      let totalOfType = this.getDraggablesOfType(type).length;
      this.$announcer.set(`1 ${type} added to the habitat. There ${totalOfType == 1 ? 'is' : 'are'} now ${totalOfType}`)
    },
    makeRandomDraggable(type, index, addedByEvent){
      var position = this.getRandomPosition();
      var draggable = this.makeDraggable(type, index, position);
      
      if (addedByEvent) {
        draggable.addedByEvent = true;
      }
      else {
        draggable.initial = true;
      }

      draggable.inScene = true;
      this.draggables.push(draggable);
    },
    makeDraggable(type, index, position){
      //called on mounted and every time last created type of draggable is dropped
      //creates draggable, places on top of draggable creator

      var draggable = {};
      draggable.type = type;
      draggable.creatorIndex = index;
      draggable.id = this.makeId(6);
      draggable.addedByEvent = false;
      draggable.inScene = false;
      draggable.active = true;
      draggable.startX = position.x;
      draggable.startY = position.y;
      draggable.infectedWithParasites = false;
      draggable.infectedWithVirus = false;
      draggable.keySoundEffect = false;

      return draggable;
    },
    draggableAddedToScene(type, creatorIndex, id){
      for (var i = 0; i < this.draggables.length; i++){
        if (this.draggables[i].id === id){
          this.draggables[i].inScene = true;
        }
      }
      this.makeStartDraggable(type, creatorIndex);
    },
    makeId(length) {
        var result = [];
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++ ) {
          result.push(characters.charAt(Math.floor(Math.random() * charactersLength)));
        }
        return result.join('');
      },
    getDraggablesOfType(type){
      var list = [];
      for (var i = 0; i < this.draggables.length; i++){
        var draggable = this.draggables[i];
        if (draggable.type === type && draggable.inScene && draggable.active){
          list.push(draggable);
        }
      }
      return list;
    },

    getActiveMeese(){
      var list = [];
      for (var i = 0; i < this.meese.length; i++){
        if (this.meese[i].active){
          list.push(this.meese[i]);
        }
      }
      return list;
    },
    getActiveMeeseOfType(type){
      var list = [];
      for (var i = 0; i < this.meese.length; i++){
        if (this.meese[i].active && this.meese[i].type === type){
          list.push(this.meese[i]);
        }
      }
      return list;
    },

    shuffle(array) {
      var currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array;
    },

    checkDraggablePopulation(type, index){
      var list = this.getDraggablesOfType(type);
      var population = this.elementPopulation[type];

      if (list.length < population){
        this.makeDraggables(type, index);
      }
      else if (list.length > population){
        this.removeDraggables(type);
      }
    },

    makeDraggables(type, index){
      var list = this.getDraggablesOfType(type);
      var population = this.elementPopulation[type];
      var addCount = population - list.length;

      for (var i = 0; i < addCount; i++){
        this.makeRandomDraggable(type, index, true);
      }
    },

    removeDraggables(type){
      var list = this.getDraggablesOfType(type);
      var shuffled = this.shuffle(list);
      var pop = this.elementPopulation[type];

      var removeCount = Math.max(0, shuffled.length - pop);


      for (var i = 0; i < removeCount; i++){
        if (shuffled[i] && shuffled[i].active){
          shuffled[i].active = false;
          this.bus.$emit("remove", shuffled[i].id);
        }
      }
      this.$announcer.set(`${removeCount} ${type} removed from habitat. 
                          There are ${this.getDraggablesOfType(type).length} ${type} remaining`)
    },
    checkMoosePopulation(){
      var activeMeese = this.getActiveMeese();
      var activeMeesePop = activeMeese.length;

      if (activeMeesePop < this.moosePopulation.length){
        for (var i = activeMeesePop; i < this.moosePopulation.length; i++){
          this.makeMoose(this.moosePopulation[i].type);
        }

        let diff = this.moosePopulation.length - activeMeese.length;
        this.$announcer.set(`${diff} moose added to habitat`)
      }
      else if (activeMeesePop > this.moosePopulation.length){
        this.removeMeese(activeMeesePop - this.moosePopulation.length);
      }
    },
    makeMoose(type){
        var moose = {};
        moose.id = this.makeId(6);
        moose.active = true;
        moose.type = type;
        moose.infectedWithVirus = false;
        moose.keySoundEffect = false;
        if (type === "calf"){

          var calfPosition;
          if (this.currentMom < this.cowPositions.length){
            calfPosition = this.cowPositions[this.currentMom];
          }
          else{
            this.currentMom = 0;
            calfPosition = this.cowPositions[this.currentMom];
          }

          //randomize calf position to be slightly offset from their mom
          calfPosition.x += Math.floor(Math.random() * 60) - 30;
          calfPosition.y += Math.floor(Math.random() * 30) + 20;
          moose.startPosition = calfPosition;

          //if (Math.random() < .66){ //twins
            this.currentMom++;
          //}
        }
        else{
          moose.startPosition = {x : 0, y : 0};
        }
        this.meese.push(moose);
    },
    removeMeese(count){
      //var shuffled = this.shuffle(this.getActiveMeese());
      var activeBulls = this.shuffle(this.getActiveMeeseOfType("bull"));
      var activeCows = this.shuffle(this.getActiveMeeseOfType("cow"));
      var activeCalves = this.shuffle(this.getActiveMeeseOfType("calf"));

      var mooseRemoved = 0;
      var currentBullIndex = 0;
      var currentCowIndex = 0;
      var currentCalfIndex = 0;

      var maxAttempts = 50;
      var attempt = 0;

      while (mooseRemoved < count && attempt < maxAttempts){
        if (activeBulls.length - currentBullIndex > this.minBulls + 1){
          activeBulls[currentBullIndex].active = false;
          this.bus.$emit("remove", activeBulls[currentBullIndex].id);
          currentBullIndex++;
          mooseRemoved++;
        }
        if (activeCows.length - currentCowIndex > this.minCows + 1){
          activeCows[currentCowIndex].active = false;
          this.bus.$emit("remove", activeCows[currentCowIndex].id);
          currentCowIndex++;
          mooseRemoved++;
        }
        if (attempt % 4 === 0 && currentCalfIndex < activeCalves.length){
          activeCalves[currentCalfIndex].active = false;
          this.bus.$emit("remove", activeCalves[currentCalfIndex].id);
          currentCalfIndex++;
          mooseRemoved++;
        }
        attempt++;
      }

      if (mooseRemoved > 0) {
        this.$announcer.set(`${mooseRemoved} moose removed from habitat`)
      }
    },

    infectMoose(){
      var deerCount = this.getDraggablesOfType("deer").length;
      var percent = Math.max(0, Math.min((1 + deerCount) / 12, 1)) * this.mooseParasiteInfectionRate; //rate is based on how many deer are in scene, but cannot be completely avoided
      var shuffled = this.shuffle(this.getActiveMeese());
      var count = Math.round(shuffled.length * percent);

      for (var i = 0; i < count; i++){
        shuffled[i].infectedWithParasites = true;

        if (i === 0){
          shuffled[0].key = true;
        }
      }
      if (count) this.$announcer.set(`${count} moose infected by parasites`)

    },
    infectDeer(){
      var percent = Math.max(0, Math.min(this.deerParasiteInfectionRate, 1));
      var list = this.getDraggablesOfType("deer");
      var shuffled = this.shuffle(list);
      var count = Math.round(shuffled.length * percent);

      for (var i = 0; i < count; i++){
        shuffled[i].infectedWithParasites = true;

        if (i === 0){
          shuffled[0].key = true;
        }
      }
      if (count) this.$announcer.set(`${count} deer infected by parasites`)
    },
    infectWolves(){
      var percent = Math.max(0, Math.min(this.wolfVirusInfectionRate, 1));
      var list = this.getDraggablesOfType("wolf");
      var shuffled = this.shuffle(list);
      var count = Math.round(shuffled.length * percent);

      for (var i = 0; i < count; i++){
        shuffled[i].infectedWithVirus = true;
      }
    },
    killInfectedMoose(){
      var percent = Math.max(0, Math.min(this.mooseParasiteDeathRate, 1));
      var shuffled = this.shuffle(this.getActiveMeese());
      var count = Math.round(shuffled.length * percent);
      var currentCount = 0;

      for (var i = 0; i < shuffled.length; i++){
        if (shuffled[i].infectedWithParasites && currentCount < count){
          shuffled[i].active = false;
          this.bus.$emit("remove", shuffled[i].id);
          currentCount++;
        }
      }

      if (currentCount) this.$announcer.set(`${currentCount} moose died from parasites`)
    },
    killInfectedWolves(){
      var percent = Math.max(0, Math.min(this.wolfVirusDeathRate, 1));
      var list = this.getDraggablesOfType("wolf");
      var shuffled = this.shuffle(list);
      var count = Math.round(shuffled.length * percent);
      var currentCount = 0;

      for (var i = 0; i < shuffled.length; i++){
        if (shuffled[i].infectedWithVirus && currentCount < count){
          shuffled[i].active = false;
          this.bus.$emit("remove", shuffled[i].id);
          currentCount++;
        }
      }
      if (currentCount) this.$announcer.set(`${currentCount} infected wolves died`)
    },
    resize(){
      let desktopSmlWidth = 1090; // matches CSS breakpoint
      let maxY = window.innerWidth < desktopSmlWidth ? 170 : 200;
      this.range.width = window.innerWidth - 230;
      this.range.height = window.innerHeight - (maxY * 1.7);

      if (window.innerWidth < desktopSmlWidth) {
        this.range.y = 100;
      } 


      for (var i = 0; i < this.elementTypes.length; i++){
          var type = this.elementTypes[i].type;
          var creatorRef = type + "Creator";
          var position = this.getPosition(i);
          this.bus.$emit("setStartPosition", type, position);
        }
    },
    addCowPosition(position){
     this.cowPositions.push(position);
   },
   clearParasites(){
     for (var i = 0; i < this.draggables.length; i++){
       this.draggables[i].infectedWithParasites = false;
     }
   },
   setMute() {
      this.$refs.clickSfx.muted = this.muted;
      this.$refs.addSfx.muted = this.muted;
      this.$refs.removeSfx.muted = this.muted;
   }
  },

  watch:{
    muted (newVal, oldVal) {
      if (newVal != oldVal) {
        this.setMute();
      }
    },
    mooseLength(){
      this.checkMoosePopulation();
    },
    playCount(){
      for (var i = 0; i < this.typeCount; i++){
          var type = this.elementTypes[i].type;
          this.checkDraggablePopulation(type, i);
      }
      this.checkMoosePopulation();
    },
    mooseParasiteInfectionRate(){
      this.infectMoose();
    },
    deerParasiteInfectionRate(){
      this.infectDeer();
    },
    mooseParasiteDeathRate(){
      this.killInfectedMoose();

      setTimeout(() => {
        this.clearParasites();
      }, 3000);
    },
    wolfVirusInfectionRate(newR, oldR){
      if (newR) this.infectWolves()
      let infected = this.getDraggablesOfType("wolf").filter(w => w.infectedWithVirus).length;
      if (infected) this.$announcer.set(`${infected} wolves infected`)
    },
    wolfVirusDeathRate(){
      this.killInfectedWolves();
    },
  },
  mounted(){
    this.resize();
    window.addEventListener('resize', this.resize);
    this.clear();

    setTimeout(() => {
      this.checkMoosePopulation();
      this.typeCount = this.elementTypes.length;
      for (var i = 0; i < this.typeCount; i++){
        var typeModel = this.elementTypes[i];
        this.makeStartDraggable(typeModel.type, i);

        if (typeModel.startPop && typeModel.startPop.length === 2){
          var min = typeModel.startPop[0];
          var max = typeModel.startPop[1];
          var random = Math.round(Math.random() * (max - min) + min);
          for (var j = 0; j < random; j++){
            this.makeRandomDraggable(typeModel.type, i, false);
          }
        }
      }
    }, 100);
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/game.scss";

.scene{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    user-select: none;
}

.elements{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
}

.creators{
    position: absolute;
    display: inline;
    right: 2vw;
    top: 30vh;

    .draggableCreator{
      margin-top: 15px;
    }

    @media screen and (max-height: 600px) {
      top: 0;
      .draggableCreator{
        margin-top: 11px;
      }
    }
    @media screen and (max-width: 700px) and (orientation: landscape){
      right: 4vw;
    }
}

.moose-enter-active, .moose-leave-active {
    transition: all 500ms ease-in-out;
}

.moose-enter, .moose-leave-to {
    opacity: 0;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.circle{
  position: absolute;
  background-color:red;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  left: 85px;
  top: 45px;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 800ms ease-in-out;
  z-index: -1
}

@keyframes expand {
  80% {
    width: 100px;
    height: 100px;
    opacity: .4;
  }
  100% {
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}

</style>
