<template>
  <div class="captions">
      <div class="container" :class="{'show': show}">
        <!-- {{ captions }} -->
        <Caption v-for="(c,i) in captions" :key="i" :delay="c.delay" :phrase="c.phrase" :order="i" v-on:finished="reset" />
      </div>
  </div>
</template>

<script>

import Caption from "@/components/game/system/Caption"
import { mapGetters } from "vuex"

export default {
  name: 'Captions',
  props: {
    show: Boolean
  },
  components: {
    Caption
  },
  computed: {
    ...mapGetters({
      showCaptions: "global/captions",
      captionText: "global/captionText",
    }),
    captions () {
      let captionsArray = this.captionText ? this.captionText.split(',') : [];
      let newCaptions = captionsArray.map((c,i) => {
        return {
          delay: i * 2200,
          phrase: c
        }
      })
      return newCaptions;
    }
  },
  methods: {
    reset (order) {
      if ((order + 1) == this.captions.length) {
        this.$store.dispatch('global/setCaptionText', '');
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.captions{
  position: absolute;
  z-index: 10000;

  div.container {
    position: absolute;
    left: 50vw;
    width: 200px;
    transform: translateX(-50%);
    letter-spacing: 0px !important;
    top: 20vh;

    &.show {
      top: -95vh;
    }

  }
}

</style>
