<template>
  <div class="chart-container">
      <chartist
          class="ct-chart"
          :class="ratioClass"
          type="Line"
          :data="chartData"
          :options="chartOptions" >
      </chartist>
  </div>
</template>

<script>
import 'chartist/dist/chartist.css';

export default {
  name: 'Chart',
  props: {
    chartData: Object,
    chartOptions: Object,
    ratioClass: String
  }
}
</script>

<style scoped lang="scss">
.chart-container {
  width: 100%;
  // height: 450px;

  .golden-section {
    height: 100%;
  }
}


/deep/ {
  .ct-grids line {
    stroke: $color-black;
    stroke-dasharray: 0;
    opacity: .25;
  }

  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: $color-orange;
  }

  .ct-series-b .ct-point,
  .ct-series-b .ct-line {
    stroke: $color-bright-green;
  }

  .ct-label {
    font-size: 1rem;
    letter-spacing: 1.5px;
  }

  .ct-axis-title {
    font-family: 'Acumin', sans-serif;
    font-size:  1.25rem; //20px
    line-height: 2rem; //32px
    letter-spacing: 1px;
  }

}

.ct-labels span {
  color: $color-black;
}

</style>
