var grid = [];
var availableGrid = [];
var gridWidth = 5;
var gridHeight = 5;
var gridCount;

var appWidth = 100; 
var appHeight = 100;
var randomPosition = {x: 0, y: 0};

export default {

    setup(){
        gridCount = gridWidth * gridHeight
        for (var i = 0; i < gridCount; i++){
            grid.push([]);
            availableGrid.push(i);
        }
        //console.log("set with grid count: " + gridCount)
    },

    lockScale(scale, min, max){
        return Math.min(Math.max(min, scale), max);
    },

    removeAvailableIndex(index){
        var i = availableGrid.indexOf(index);
        availableGrid.splice(i, 1);
    },

    addRandom(){
        console.log("availableGrid.length: " + availableGrid.length);
        var random = Math.floor(Math.random() * availableGrid.length);
        console.log("random: " + random);

        if (random < availableGrid.length){
            var index = availableGrid[random];
            var xIndex = Math.floor(index % gridWidth);
            var yIndex = Math.floor(index / gridWidth);
            console.log("random grid index: " + xIndex + ", " + yIndex);
            var x = this.lockScale(appWidth / xIndex, 0, appWidth);
            var y = this.lockScale(appHeight / yIndex, 0, appHeight);
            console.log("random grid position: " + x + ", " + y);
            return {"x": x, "y": y};
            
        }
        else{
            console.log("out of available spots left");
            return null;
        }
    },

    setAppSize(width, height){
        appWidth = width;
        appHeight = height;
    },

    addDraggable(data){
        var scaledX = this.lockScale(data.position.x / appWidth, 0, 1);
        var scaledY = this.lockScale(data.position.y / appHeight, 0, 1);
        // console.log("scaledPosition: " + scaledX + ", " + scaledY);
        
        var xIndex = Math.floor(scaledX * gridWidth);
        var yIndex = Math.floor(scaledY * gridHeight);

        var index = yIndex * gridWidth + xIndex;
        // console.log("adding to index: " + xIndex + ", " + yIndex);
        // console.log("array index: " + index);
        // console.log("object: " + data.id + ", " + data.type);

        if (index < grid.length){
            grid[index].push({id: data.id, type: data.type});
            this.removeAvailableIndex(index);
        }
    }
}