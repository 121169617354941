<template>
    <div class="badgeAlert">
        <transition appear name="fade">
            <div class="box" v-if="show">
                <div class="content">
                  <img :src="icon"/>
                  <p> {{ message }} </p>
                </div>
                <div class="triangle"></div>
            </div>
        </transition>

        <audio ref="chimeSfx" :src="chimeSource"  preload/>
    </div>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"

export default {
  name: 'BadgeAlert',
  // data(){
  //   return{
  //     badge: {
  //        "id" : "pond-badge",
  //        "name" : "Last Water Bender",
  //        "message": "You were awarded the Last Water Bender Badge!",
  //        "description" : "Lakes and ponds were plentiful in your habitat! Water sources are important for moose to eat, drink, and keep cool during the summer.",
  //        "hint" : "Moose are just like us—they also like to cool off in the lake during a hot day.",
  //        "icon" : "water-bender-badge",
  //        "levels" : [{"elementType" : "pond", "value" : 10}],
  //        "seasonId" : "summer"
  //     },
  //   }
  // },
  computed:{
      ...mapGetters({
          show : "ecosystem/showBadgeAlert",
          badge : "ecosystem/latestBadge",
          muted : "global/muted",
      }),
      icon(){
        if (this.badge && this.badge.icon){
          return Assets.load("badges", this.badge.icon, "png");
        }
        return "";
      },
      message(){
        if (this.badge && this.badge.message){
          return this.badge.message;
        }
        return "";
      },
      chimeSource(){
          return Assets.load("sfx", "play_event_badge", "mp3")
      },
  },
  methods: {
    setMute() {
      this.$refs.chimeSfx.muted = this.muted;
    }
  },
  watch:{
    muted (newVal, oldVal) {
      if (newVal != oldVal) {
          this.setMute();
      }
    },
    show(){
      if (this.show){
        if (this.$refs.chimeSfx){
          this.$refs.chimeSfx.play();
          this.$announcer.set(this.message);
          this.$store.dispatch("global/setCaptionText", this.$g('badge_cc'))
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.badgeAlert{
    position: absolute;
    width: 100vw;

    z-index: 9999;
}

.box{
  position: absolute;
  margin-left: 50%;
  bottom: 165px;
  transform: translate(-50%, 50%);

  @media screen and (max-width: $mq-max-width-tablet) {
    bottom: 135px;
  }
}

.content{
   width: 250px;
   background-color: $color-white;
   padding: 20px;
   border-radius: 15px;
}

img{
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
}

p {
    display: inline-block;
    vertical-align: middle;
    width: 180px;
    text-align: left !important;
    font-size: 18px !important;
    line-height: 22px !important;
    margin: 0;
    margin-left: 20px;
    color: $color-teal !important;
}

.triangle{
  width: 0;
  height: 0;
  margin-left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 12px 10px 0 10px;
  border-color: $color-white transparent transparent transparent;
}


</style>
