<template>
  <button :class="['muteButton', 'simple']" @click="toggle" ref="mute" aria-pressed="false" >
    <img :src="muted ? getIcon('sound-off-icon') : getIcon('sound-on-icon')" aria-hidden="true" />
    <span class="sr-only">{{$g("acc-mute-button")}}</span>
  </button>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"


export default {
  name: 'MuteButton',
  computed:{
    ...mapGetters({
        muted: "global/muted",
    })
  },
  methods: {
    getIcon(id){
      return Assets.load("icons", id, "svg")
    },
    toggle(){
      this.$refs.mute.setAttribute('aria-pressed', !this.muted);
      this.$store.dispatch("global/setMuted", !this.muted);
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.muteButton{
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 10px;
  left: 140px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $color-teal !important;
  border-radius: 50%;

  img{
    width: 50%;
    height: 50%;
    margin-top: 0;
    pointer-events: none;
  }

    @media screen and (max-width: $mq-max-width-tablet) {
      left: 10px;
      bottom: 55px;
      height: 40px;
      width: 40px;
    }

    @media screen and (max-height: 600px) {

      img {
        width: 20px;
        height: 20px;
        margin-top: 0;
        pointer-events: none;
      }
    }
}


</style>
