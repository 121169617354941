<template>
  <div class="mooseGauge" :style="[{'--percent' : percent}]">
    <h2 class="sr-only">{{ $g('acc_bar_status_title') }}</h2>
    <p class="sr-only">{{ $g('acc_bar_description') }}</p>
    <p class="sr-only" id="meter-status">{{ $g('acc_bar_status_intro') }} {{ accStatus }}</p>

    <img class="marker" :src="arrow" aria-hidden="true"/>

    <div class="bar" aria-describedby="meter-status">
        <div class="block low">
          <label>{{$g("bar-low-pop")}}</label>
        </div>
        <div class="block medium">
          <label>{{$g("bar-medium-pop")}}</label>
        </div>
        <div class="block high">
          <label>{{$g("bar-high-pop")}}</label>
        </div>
    </div>

    <label class="mainLabel">{{$g("bar-label")}}</label>

    <audio ref="chimeSfx" :src="chimeSource"  preload/>
  </div>
</template>

<script>
import Assets from "@/components/game/utils/assets"
import { mapGetters } from "vuex"

export default {
  name: 'MooseGauge',
  data() {
      return {
        belowTarget: true,
        padding: 10,
        healthyOnce: false,
        previousPopulation: 0
      }
  },
  computed:{
      ...mapGetters({
          moosePopulation : "ecosystem/moosePopulation",
          minPopulation : "ecosystem/minMoose",
          minTargetPopulation : "ecosystem/minTargetMoose",
          maxTargetPopulation : "ecosystem/maxTargetMoose",
          maxPopulation : "ecosystem/maxMoose",
          currentSeason : "season/currentSeason",
          muted : "global/muted",
      }),
      population(){
          return this.moosePopulation.length;
      },
      arrow(){
        return Assets.load("icons", "arrow-icon-short", "svg")
      },
      percent(){ //value from 0 to 1
        var p = (this.population - this.minPopulation) / (this.maxPopulation - this.minPopulation);
        return Math.max(0, Math.min(.95, p));

      },
      label(){
          if (this.percent < 1 / 3){
              return this.$g("low-moose-pop");
          }
          else if (this.percent < 2 / 3){
              return this.$g("medium-moose-pop");
          }
          else{
              return this.$g("high-moose-pop");
          }
      },
      chimeSource(){
        return Assets.load("sfx", "play_event_threshold", "mp3");
      },
      accStatus(){
        var statusArray = this.$g("acc_bar_statuses");
        var length = statusArray.length;
        var increment = 1 / length

        for (var i = 0; i < length; i++){
          if (this.percent - .05 < i * increment){
            return statusArray[i];
          }
        }
        return "status: not found"
      }
  },
  methods: {
      setMute() {
        this.$refs.chimeSfx.muted = this.muted;
      }

  },
  watch: {
      population(){
        if (this.population === this.minTargetPopulation + 2 && this.previousPopulation < this.population){
          if (this.healthyOnce && this.$refs.chimeSfx){
            this.$refs.chimeSfx.play();
          }
          this.healthyOnce = true;
        }
        this.previousPopulation = this.population;
      },
      currentSeason(){
        if (this.currentSeason.id === "spring"){
          this.healthyOnce = false;
        }
      },
      accStatus(){
        this.$announcer.set(this.$g('acc_bar_status_intro_updated') + this.accStatus);
        this.$store.dispatch('ecosystem/setMoosePopHealth', this.accStatus);
      },
      muted (newVal, oldVal) {
        if (newVal != oldVal) {
            this.setMute();
        }
      },
  },
  mounted(){
      this.healthyOnce = false;
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.mooseGauge{
    --width: 400px;
    --barWidth: 350px;
    --percent: 0;

    position: absolute;
    right: 50vw;
    bottom: 0;
    width: var(--width);
    height: 100px;
    z-index: 9999;
    background-color: $color-teal;
    transform: translateX(50%);
    border-radius: 7px 7px 0px 0px;
    user-select: none;

    @media screen and (max-width: $mq-max-width-tablet) {
      height: 72px;
    }

    @media screen and (max-height: 600px) {
      bottom: -22px;
    }
}

label{
    position: absolute;
    text-align: center;
    width: 100%;

    &.mainLabel{
      margin-top: 75px;
      color: $color-white;

      @media screen and (max-width: $mq-max-width-tablet) {
        margin-top: 48px;
      }

      @media screen and (max-height: 600px) {
        display: none;
      }
    }
}

.bar{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    width: var(--barWidth);
    height: 15px;
    border-radius: 8px;

    .block{
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        height: 100%;
        label{
          width: 33.33%;
          margin-top: 20px;
        }

        &.low{
            background-color: $color-aqua;
            border-radius: 7px 0px 0px 7px;
            label{
              color: $color-aqua !important;
            }

        }
        &.medium{
            background-color: $color-bright-green;
            label{
              color: $color-bright-green !important;
            }
        }
        &.high{
            background-color: $color-orange;
            border-radius: 0px 7px 7px 0px;
            label{
              color: $color-orange !important;
            }
        }
    }

    @media screen and (max-width: $mq-max-width-tablet) {
      top: 12px;

      .block {
        height: 60%;
         label {
           font-size: 1rem;
           margin-top: .75rem;
         }
      }
    }
}

.marker{
    --iconSize: 35px;
    position: absolute;
    width: var(--iconSize);
    height: var(--iconSize);
    margin-top: -10px;
    margin-left: calc(var(--barWidth) * var(--percent));
    transform: translateX(50%) rotate(90deg);
    z-index: 1;
    transition: all 500ms ease-in-out;
    pointer-events: none;
    filter: drop-shadow(0 0 12px rgba(0, 0, 0, .4));
    @media screen and (max-width: $mq-max-width-tablet) {
      margin-top: -17px;
    }
}


</style>
