<template>
    <section class="site-component">
        <div id="moose">
        <h1 class="sr-only">{{ content.meta.page_title }}</h1>

        <!-- biology section -->
        <article class="biology content-container">
            <div class="column">
                <div class="header">
                    <h2 class="h6 eyebrow-label">{{ bio_content.eyebrow_label }}</h2>
                    <h3 id="bio-section-title" class="h1 text-orange">
                        <span>{{ bio_content.headline_line1 }}</span>
                        <span>{{ bio_content.headline_line2 }}</span>
                    </h3>
                </div>
                <div class="biology-fact-0">
                    <img class="framed-image" :src="first_fact.image_filename" :alt="first_fact.image_alt_text">
                    <h4 class="text-aqua">{{ first_fact.title }}</h4>
                    <p>{{ first_fact.text }}</p>
                </div>
            </div>
            <div class="column">
                <div :class="`biology-fact-${i + 1}`" v-for="(f, i) in other_facts" :key="i">
                    <img class="framed-image" :src="f.image_filename" :alt="f.image_alt_text">
                    <h4 class="text-aqua">{{ f.title }}</h4>
                    <p>{{ f.text }}</p>
                </div>
            </div>
        </article>
        <img class="illustration" :src="bio_content.illustration_filename" :alt="bio_content.illustration_alt" >

        <!-- hotspot section -->
        <article class="hotspot">
            <div class="content-container">
                <div class="centered-header">
                    <h2 class="h6 eyebrow-label text-yellow">{{ hotspot_content.eyebrow_label }}</h2>
                    <h3 id="hotspot-section-title" class="h1 text-white">{{ hotspot_content.headline }}</h3>
                </div>
                <div>
                    <div class="moose-diagram">
                        <span class="diagram-num num-1" aria-hidden="true">1</span>
                        <span class="diagram-num num-2" aria-hidden="true">2</span>
                        <span class="diagram-num num-3" aria-hidden="true">3</span>
                        <span class="diagram-num num-4" aria-hidden="true">4</span>
                        <img class="moose-diagram-img" :src="hotspot_content.main_image_filename" :alt="hotspot_content.main_image_alt_text">
                    </div>
                    <div class="spot-container">
                        <div class="hotspots-left" aria-labelledby="hotspot-section-title">
                            <div class="spot" v-for="(h, i) in left_hotspots" :key="i">
                                <h4 class="h5 text-yellow" :class="`header-${i + 1}`">
                                    <span>{{ i+1 }}. </span>
                                    <span>{{ h.title }}</span>
                                </h4>
                                <p class="text-white">{{ h.description }}</p>
                            </div>
                        </div>
                        <div class="hotspots-right" aria-labelledby="hotspot-section-title">
                            <div class="spot" v-for="(h, i) in right_hotspots" :key="i">
                                <h4 class="h5 text-yellow" :class="`header-${i + 3}`">
                                    <span>{{ i+3 }}. </span>
                                    <span>{{ h.title }}</span>
                                </h4>
                                <p class="text-white">{{ h.description }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>

        <!-- ecosystem section -->
        <article id="ecosystem" class="ecosystem bg-off-white">
            <div class="content-container">
                <div class="centered-header">
                    <h2 class="h6 eyebrow-label">{{ ecosystem_content.eyebrow_label }}</h2>
                    <h3 id="ecosystem-section-title" class="h1 text-orange">{{ ecosystem_content.headline }}</h3>
                </div>
                <ul aria-labelledby="ecosystem-section-title">
                    <li v-for="(a, i) in ecosystem_content.animal_factors" :key="i">
                        <img :src="a.image_filename" :alt="a.image_alt_text">
                        <p>{{ a.description }}</p>
                        <a :href="a.button_link" target="_blank" class="button-round with-arrow">
                            <span>{{ a.button_text }}</span>
                            <span class="icon-arrow" aria-hidden="true"></span>
                        </a>
                    </li>
                </ul>

            </div>
            <aside class="survey-tout" v-if="survey_content.show_section" >
                <figure class="survey-bg">
                    <img :src="survey_bg_1" :alt="survey_content.background_image_alt_text">
                    <img :src="survey_bg_2" :alt="survey_content.background_image2_alt_text">
                    <img :src="survey_bg_3" :alt="survey_content.background_image3_alt_text">
                </figure>
                <div class="survey-text">
                    <div>
                        <h3 class="text-white">{{ survey_content.headline }}</h3>
                        <p class="text-white">{{ survey_content.description }}</p>
                        <a :href="survey_content.link_url" target="_blank" class="button-round with-arrow">
                            <span>{{ survey_content.link_text }}</span>
                            <span class="icon-arrow" aria-hidden="true"></span>
                        </a>
                    </div>
                </div>
                <figure class="mobile-survey-bg">
                    <img :src="survey_bg_2" :alt="survey_content.background_image2_alt_text">
                    <img :src="survey_bg_3" :alt="survey_content.background_image3_alt_text">
                </figure>
            </aside>
        </article>

        <!-- zoo sections: meet our moose, resources -->
        <article>
            <div class="zoo-moose bg-gold">
                <div class="content-container">
                    <div class="content">
                        <h2 class="h3 text-green">{{ zoo_content.headline }}</h2>
                        <p>{{ zoo_content.text }}</p>
                    </div>
                    <div class="images">
                        <div class="image-container" v-for="(img,i) in zoo_content.images" :key="i">
                            <img class="framed-image" :src="img.filename" :alt="img.alt_text">
                        </div>
                    </div>
                </div>
            </div>
        </article>
        </div>
    </section>
</template>

<script>

export default {
    computed: {
        content () {
            let pageContent =  require("@/assets/data/about-moose.json");
            let globalContent =  require("@/assets/data/global-content.json");

            return {...pageContent, ...globalContent}
        },
        bio_content () {
            return this.content.biology_section;
        },
        first_fact () {
            return this.bio_content.facts[0];
        },
        other_facts () {
            return this.bio_content.facts.slice(1);
        },
        hotspot_content () {
            return this.content.hotspot_section;
        },
        right_hotspots () {
            return this.hotspot_content.hotspots.slice(2,);
        },
        left_hotspots () {
            return this.hotspot_content.hotspots.slice(0,2);
        },
        ecosystem_content () {
            return this.content.ecosystem_section;
        },
        survey_content () {
            return this.content.survey_section;
        },
        zoo_content () {
            return {...this.content.our_moose_section, ...this.content.resource_section};
        },
        survey_bg_1 () {
            return this.survey_content.background_image_filename;
        },
        survey_bg_2 () {
            return this.survey_content.background_image2_filename;
        },
        survey_bg_3 () {
            return this.survey_content.background_image3_filename;
        }

     },
    head: {
        title: function() {
            return {
                inner: this.content.meta.page_title,
                separator: '|',
                complement: this.content.site_name
            }
        },
        meta: function() {
            return [
                {rel: 'canonical', href: this.content.meta.canonical_url, id: 'rel-con'},
                {name: 'description', content: this.content.meta.description, id: 'desc'},

                {property: 'og:title', content: this.content.meta.page_title, id: 'og-title'},
                {property: 'og:description', content: this.content.meta.description, id: 'og-desc'},
                {property: 'og:type', content: 'website', id: 'og-type'},
                {property: 'og:image', content: `${this.content.site_url}${this.content.meta.preview_image}`, id: 'og-image'}
            ]
        }
    },
    mounted () {
        console.log('moose page mounted');
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/microsite.scss";

.site-component {
    margin-top: 100px;
}



.framed-image {
    border: $color-white 10px solid;
    width: 100%;
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.2));
    margin-bottom: $twentysixpx;
}

.biology {
    max-width: $max-width-content;
    display: flex;
    // position: relative;
    
    .header {
        max-width: 500px;
        margin-bottom: $sixtyfivepx;

        h3 span {
            display: block;
            &:last-child {
                padding-left: $sixtyfivepx;
            }
        }
    }

    .column {
        width: 50%;
        display: inline-block;
    }

    [class*=biology-fact] {
        max-width: 480px;
        width: 60%;
        margin-left: $onesixteenpx;
        margin-bottom: $onesixteenpx;

        p, h4 {
            width: 77%;
            margin-left: $twentysixpx;
            min-width: 300px;
        }

        p {
            margin-top: .5rem;
        }

        img {
            max-width: 375px;
        }
    }

    .biology-fact-0 {
        margin-left: auto;
        img {
            transform: rotate(-4deg);
        }
    }
    .biology-fact-1 {
        img {
            margin-top: $fortypx;
            transform: rotate(3deg);
        }
    }
    .biology-fact-2 {
        margin-bottom: $fortypx;
        img {
            transform: rotate(2deg);
        }
    }

    @media screen and (max-width: $mq-max-width-desktop-sml) {
        .biology-fact-0 {
            margin-left: unset;
        }
    }
    @media screen and (max-width: $mq-max-width-tablet) {
        flex-direction: column;
        .column {
            width: 100%;

            &:nth-child(2) {
                margin-bottom: $onesixteenpx;
            }
        }

        [class*=biology-fact] {
            max-width: unset;
            width: 100%;
        }

        .biology-fact-0, 
        .biology-fact-2 {
            text-align: right;
            width: unset;

            h4,
            p {
                margin-left: auto;
            }
        }

        .biology-fact-1 {
            margin-left: 0;
        }
    }

    @media screen and (max-width: $mq-max-width-mobile) {

        .header {
            margin-bottom: $fortypx;
            h3 span {
                &:last-child {
                    padding-left: $twentysixpx;
                }
            }
        }

        [class*=biology-fact] {
            margin-bottom: $fortypx;
        }

        .biology-fact-0, 
        .biology-fact-1,
        .biology-fact-2 {
            text-align: left;
            width: unset;
            margin-left: 0;

            h4,
            p {
                margin: 0 auto .5rem;
                width: 100%;
            }
        }
    }

}
.illustration {
    margin-top: -280px;
    margin-left: 10rem;
    margin-bottom: 120px;

    @media screen and (max-width: $mq-max-width-tablet) {
        height: auto;
        width: 55%;
        min-width: 200px;
        margin: -140px auto $fortypx;
        display: block;
    }
}


.hotspot {
    position: relative;
    background: #268787;

    .moose-diagram {
        $moose-size: 550;
        $half-moose: $moose-size / 2;
        position: absolute;
        width: $moose-size + px;
        height: auto;
        left: calc(50% - #{$half-moose}px);

        img {
            width: 85%;
            height: auto;
            position: relative;
            top: 80px;
            left: 30px;
        }

        .diagram-num {
            border-radius: 50%;
            border: solid 2px $color-yellow;
            height: 50px;
            width: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: $color-yellow;
            font-size: $large-text;
            font-family: 'acumin', sans-serif;

            position: absolute;

            &.num-1 {
                top: 20px;
                left: 30px;
            }
            &.num-2 {
                top: 340px;
                left: 30px;
            }
            &.num-3 {
                top: 100px;
                right: 100px;
            }
            &.num-4 {
                top: 320px;
                right:  0px;
            }
        }
    }

    .spot-container {
        max-width: 1280px;
        margin: 0 auto;
        list-style: none;
        display: flex;
        align-items: space-between;
        flex-direction: column;
        flex-wrap: wrap;
        height: 600px;


        .spot {
            max-width: 300px;
            h4 {
                position: relative;
                span {
                    display: inline-block;
                    padding-right: 1rem;
                }
            }
        }

        .header-1 {
            &:after {
                content: url('../assets/images/diagram-line-antlers.png');
                position: absolute;
            }
        }
        .header-2 {
            margin-top: 80px;
            &:after {
                content: url('../assets/images/diagram-line-snout.png');
                position: absolute;
                top: -70px;
            }
        }
        .header-3 {
            &::before {
                content: url('../assets/images/diagram-line-coat.png');
                position: absolute;
                left: -160px;
                top: 20px;
            }
        }
        .header-4 {
            margin-top: 100px;

            &::before {
                content: url('../assets/images/diagram-line-legs.png');
                position: absolute;
                left: -100px;
                top: -10px;
            }
        }

        .hotspots-right {
            padding-top: 100px;
            .spot {
                margin-left: auto;
            }
        }
    }

    @media screen and (max-width: $mq-max-width-desktop) {
        .moose-diagram {
            $moose-size: 400;
            $half-moose: $moose-size / 2;
            position: absolute;
            width: $moose-size + px;
            height: auto;
            left: calc(50% - #{$half-moose}px);
            top: 31%;

            img {
                height: auto;
                width: 90%;
                top: 65px;
                left: 0;
            }

            .diagram-num {
                height: 40px;
                width: 40px;
                font-size: $large-text;

                position: absolute;

                &.num-1 {
                    top: -10px;
                    left: 50px;
                }
                &.num-2 {
                    top: 250px;
                    left: 50px;
                }
                &.num-3 {
                    top: 30px;
                    right: 100px;
                }
                &.num-4 {
                    top: 240px;
                    right:  20px;
                }
            }

        }
        .spot-container {
            .header-2 {
                margin-top: 50px;
            }
            .header-4 {
                margin-top: 30px;
            }
        }
    }
    @media screen and (max-width: $mq-max-width-desktop-sml) {
        .moose-diagram {
            position: relative;
            margin-bottom: 1rem;

            img {
                top: 0;
            }

            .diagram-num {
                font-size: 1.4rem;
                height: 38px;
                width: 38px;

                &.num-1 {
                    top: -14%;
                    left: -20px;
                }
                &.num-2 {
                    top: 50%;
                    left: 0;
                }
                &.num-3 {
                    top: 5%;
                }
                &.num-4 {
                    top: 65%;
                    right:  10px;
                }
            }
        }

        .spot-container {
            flex-wrap: nowrap;
            height: unset;

            .spot {
                max-width: 600px;
                margin: 0 auto;

                p {
                    margin-top: .4rem;
                }

                &:nth-child(2) {
                    margin: 1.8rem auto;
                }
            }

            .header-1,
            .header-2,
            .header-3,
            .header-4 {
                margin-top: 0;

                &:before,
                &:after {
                    display: none;
                }
            }

            .hotspots-right {
                padding-top: 0;
            }
        }
    }

    @media screen and (max-width: $mq-max-width-tablet) {
        .moose-diagram {
            left: unset;
            width: 100%;
            max-width: 480px;
            margin: 1rem auto;
            display: block;
        }
    }
    @media screen and (max-width: $mq-max-width-mobile) {
        .moose-diagram {
            left: 5%;

            .diagram-num {
                &.num-3 {
                    top: 0;
                }
                &.num-4 {
                    top: 60%;
                    right:  10px;
                }
            }
        }
    }
}

.ecosystem {
    .centered-header {
        text-align: center;
        margin-bottom: $twentysixpx;

        h3 {
            max-width: 20ch;
        }    
    }
    ul {
        margin-top: 0;
        display: flex;

        li {
            padding: 0;
            flex-grow: 0;
            flex-basis: 33%;

            &:not(:last-child) {
                margin-right: $twentysixpx;
            }
            &:not(:first-child) {
                margin-left: $twentysixpx;
            }
            img {
                margin: 0 auto $fortypx;
                display: block;
                width:  260px;
            }

            a.button-round {
                margin: 0 auto;
            }
        }

        @media screen and (max-width: $mq-max-width-tablet) {
            flex-direction: column;

            li {
                max-width: 600px;
                margin: $twentysixpx auto;

                &:not(:last-child),
                &:not(:first-child) {
                    margin: $twentysixpx auto;
                }

                img {
                    margin-bottom: 1rem;
                }

            }
        }
    }

    .survey-tout {
        margin: 0 auto;
        position: relative;
        width: 100%;

        .survey-text {
            max-width: $max-width-content;
            margin: 0 auto;
            position: relative;
            height: 600px;
            z-index: 2;

            div {
                width: 40%;
                height: 100%;
                padding: $sixtyfivepx $section-padding;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                p {
                    font-size: $text-size;
                    max-width: 600px;
                    line-height: 36px;
                }

                @media screen and (min-width: $mq-max-width-desktop-lg) {
                    padding: $sixtyfivepx 0;
                }
            }

            @media screen and (max-width: $mq-max-width-tablet) {
                height: 400px;

                div {
                    padding: $mobile-section-padding;
                    width: unset;
                }
            }

            @media screen and (max-width: $mq-max-width-mobile) {
                h3 {
                    font-size: 2rem;
                    line-height: 2rem;
                }

                div {
                    width: unset;
                    p {
                        line-height: 26px;
                    }
                }
            }
        }

        .button-round {
            background: $color-bg-aqua;

            &:hover {
                background: #4DA3A6;
            }
        }

        .mobile-survey-bg {
            display: none;
            margin: 0;
            height: 280px;
            overflow: hidden;

            @media screen and (max-width: $mq-max-width-tablet) {
                height: 420px;
                display: flex;

                img:nth-child(1) {
                    width: 50%;
                    height: 420px;
                    object-fit: cover;
                    object-position: center -80px;
                }
                img:nth-child(2) {
                    width: 50%;
                    height: 420px;
                    object-fit: cover;
                    object-position: center -80px;
                }
            }
            @media screen and (max-width: $mq-max-width-mobile) {
                height: 300px;

                img:nth-child(1) {
                    height: 300px;
                    object-position: center -10px;
                }
                img:nth-child(2) {
                    height: 300px;
                    object-position: center -10px;
                }
            }
        }

        .survey-bg {
            margin: 0;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: flex;
            justify-content: center;

            img {
                object-fit: cover;
            }

            img:nth-child(1) {
                object-position: right;
                width: 50%;
            }
            img:nth-child(2) {
                width: 25%;
            }
            img:nth-child(3) {
                width: 25%;
            }

            @media screen and (max-width: $mq-max-width-tablet) {
                height: unset;
                height: 400px;


                img:nth-child(1) {
                    object-position: right;
                    width: 100%;
                }
                img:nth-child(2) {
                    display: none;
                }
                img:nth-child(3) {
                    display: none;
                }

            }

            // @media screen and (max-width: $mq-max-width-mobile) {
            //      img:nth-child(1) {
            //         object-position: right;
            //         width: 50%;
            //     }
            //     img:nth-child(2) {
            //         width: 25%;
            //     }
            //     img:nth-child(3) {
            //         width: 25%;
            //     }
            // }
        }
    }
}

.zoo-moose {
    .content-container {
        display: flex;

        .content {
            flex-basis: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding-right: $fortypx;

            p {
                margin-top: 1rem;
            }
        }

        .images {
            flex-basis: 60%;
            padding-top: $twentysixpx;
            img {
                max-width: 400px;
            }
            .image-container:first-child {
                img {
                    margin-left: auto;
                    margin-right: 3rem;
                    display: block;
                    transform: rotate(3deg);
                } 
            }
            .image-container:nth-child(2) {
                margin-left: 4rem;
                display: block;
                transform: translateY(-80px) rotate(-3deg);
            }
        }

        @media screen and (max-width: $mq-max-width-tablet) {
            flex-direction: column;
            padding-bottom: 0;

            .images {
                padding-top: 0;
                img {
                    max-width: 215px;
                }
                .image-container:first-child {
                    img {
                        margin-bottom: 3.75rem;
                        transform: rotate(3deg) translateY(40px);
                    } 
                }

                .image-container:nth-child(2) {
                    transform: translateY(-40px) rotate(-3deg);
                }
            }
        }
    }
}

</style>
