<template>
  <div class="wrapupTakeover" v-show="show">
    <div class="scrim"></div>
    <div class="content">
        <SeasonDial class="dial" :overrideSeasonIndex="0"/>
        <h2>{{ $g("wrapup-headline") }}</h2>
        <p>{{ $g("wrapup-body") }}</p>
        <button ref="report" v-if="screenshot" class="simple fill" @click="next"> {{$g("wrapup-button")}} </button>
        <span class="button simple" v-else> {{$g("wrapup-loading-text")}} </span>
    </div>

    <audio ref="wrapupSfx" :src="chimeSource"  preload/>
  </div>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"
import SeasonDial from "@/components/game/system/SeasonDial"

export default {
  name: 'WrapupTakeover',
  components:{
    SeasonDial
  },
  computed:{
      ...mapGetters({
          show: "season/showWrapup",
          muted : "global/muted",
          screenshot : "ecosystem/screenshot",
      }),
      chimeSource(){
        return Assets.load("sfx", "play_event_ending", "mp3");
      },
  },
  methods:{
      next(){
          this.$router.push({ name: 'conclusion'});
      },
      setMute() {
        this.$refs.wrapupSfx.muted = this.muted;
      }

  },
  watch:{
    muted (newVal, oldVal) {
      if (newVal != oldVal) {
          this.setMute();
      }
    },
    show(){
      if (this.show){
        if (this.$refs.wrapupSfx){
          this.$refs.wrapupSfx.play();
        }
        
        this.$announcer.set(this.$g("wrapup-headline") + " " + this.$g("wrapup-body"));
        
        setTimeout(() => {
          this.$refs.report.focus();
        }, 200);

      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.wrapupTakeover{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}

.scrim{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .6;
}

.content{
    position: absolute;
    min-width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    border-radius: 15px;
    padding: 60px;

    @media screen and (max-height: 600px) {
      width: 85vw;
      min-height: unset;
      height: 95vh;
      overflow: auto;
      padding-bottom: 2rem;
      box-sizing: border-box;
    }
}

.dial{
    position: absolute;
    width: 150px;
    height: 150px;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-height: 600px) {
      width: 100px;
      height: 100px;
      top: 10px;
    }
}

h2{
    font-size: 40px !important;
    color: $color-orange !important;
    margin-top: 180px;
    margin-bottom: 0;

    @media screen and (max-height: 600px) {
    margin-top: 70px;
    }
}

p{
    color: $color-black !important;
    width: 500px;
    font-size: 18px !important;
    line-height: 24px;
    margin-left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: $mq-max-width-tablet) {
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
    }

    @media screen and (max-height: 600px) {
      margin-bottom: 0;
    }
}

.button {
  display: block;
  width: fit-content;
  margin: 10px auto;
  background-color: white !important;
  color: $color-orange !important;
}

button{
    margin-top: 10px;
    margin-left: 50%;
    transform: translateX(-50%);
}

</style>
