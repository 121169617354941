<template>
  <transition appear name="fade">
    <div class="seasonTakeover" v-show="show && !showWrapup">
      <div class="scrim"></div>
      <div class="content">
          <SeasonDial class="dial" />
          <h2>{{ seasonHeader }}</h2>
          <p>{{ seasonDescription }}</p>
          <button autofocus ref="playbtn" class="simple fill" @click="play"> {{"start " + season.id}} </button>
      </div>

     <audio ref="natureSfx" :src="natureSource"  preload/>
    </div>

  </transition>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"
import SeasonDial from "@/components/game/system/SeasonDial"

export default {
  name: 'SeasonTakeover',
  components:{
    SeasonDial
  },
  computed:{
      ...mapGetters({
          season : "season/currentSeason",
          show: "season/showTakeover",
          showWrapup: "season/showWrapup",
          muted : "global/muted",
      }),
      seasonHeader(){
        if (this.season && this.season.header){
          return this.season.header;
        }
        return "";
      },
      seasonDescription(){
        if (this.season && this.season.description){
          return this.season.description;
        }
        return "";
      },
      dial(){
          return Assets.load("icons", "season-dial", "png")
      },
      natureSource(){
        if (this.season && this.season.sfx){
          return Assets.load("sfx", this.season.sfx, "mp3");
        }
        return "";
      },
  },
  methods:{
      play(){
          this.$store.dispatch("ecosystem/resetPlayCount");
          this.$store.dispatch("season/setTakeover", false);
      },
      setMute() {
        this.$refs.natureSfx.muted = this.muted;
      }

  },
  watch:{
      muted (newVal, oldVal) {
        if (newVal != oldVal) {
            this.setMute();
        }
      },
    show(){
      if (this.show){
        this.$announcer.set(this.seasonHeader + " " + this.seasonDescription);
        
        setTimeout(() => {
          this.$refs.playbtn.focus();
        }, 200);

        if (this.$refs.natureSfx){
          var player = this.$refs.natureSfx;
          player.source = this.natureSource;
          player.addEventListener('canplaythrough', () => {
            player.play();
            this.$store.dispatch("global/setCaptionText", this.season.sfx_captions)
          }, false);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.seasonTakeover{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;

  .scrim{
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: .6;
  }

  .content{
      position: absolute;
      min-width: 400px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $color-white;
      border-radius: 15px;
      padding: 60px;

      @media screen and (max-height: 600px) {
        width: 85vw;
        min-height: unset;
        height: 95vh;
        overflow: auto;
        padding-bottom: 2rem;
        box-sizing: border-box;
      }
  }

  .dial{
      position: absolute;
      width: 150px;
      height: 150px;
      left: 50%;
      transform: translateX(-50%);

      @media screen and (max-height: 600px) {
        width: 100px;
        height: 100px;
        top: 10px;
      }
  }

  h2{
      font-size: 40px !important;
      color: $color-orange;
      margin-top: 180px;
      margin-bottom: 0;

      @media screen and (max-height: 600px) {
      margin-top: 70px;
      }
  }

  p{
      color: $color-black;
      width: 500px;
      font-size: 18px;
      line-height: 24px;
      margin-left: 50%;
      transform: translateX(-50%);

      @media screen and (max-width: $mq-max-width-tablet) {
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
      }

      @media screen and (max-height: 600px) {
        margin-bottom: 0;
      }
  }

  button{
      margin-top: 10px;
      margin-left: 50%;
      transform: translateX(-50%);
  }
}


</style>
