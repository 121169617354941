<template>
    <div class="badgeProgress">
      <div class="header">
        <h2>Badges</h2>
        <label :aria-label="'earned ' + this.earnedBadgeCount + 'out of ' + allBadges.length + ' badges'"></label>
        <label class="score" :aria-hidden="true"> {{ this.earnedBadgeCount + '/' + allBadges.length}} </label>
      </div>
      <div class="badges-scroll-container">
        <ol>
            <li :class="['badge', badge.earned ? 'earned' : '']"
                v-for="badge in displayedBadges"
                :key="badge.id"
                :aria-label="badge.earned ? 'earned badge' : 'unearned badge'">
                <img :src="badge.earned ? getIcon(badge.icon) : getIcon('grey-badge')" aria-hidden="true" />
                <label>{{badge.name}}</label>
                <div class="line"></div>
                <p>{{badge.earned ? badge.description : badge.hint}}</p>
            </li>
        </ol>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"
import vue from "vue"

export default {
  name: 'badgeProgress',
  data(){
      return {
          displayedBadges: [],
          earnedBadgeCount: 0,
      }
  },
  computed:{
      ...mapGetters({
          allBadges : "ecosystem/badges",
          earnedBadges : "ecosystem/earnedBadges",
      }),

  },

  methods:{
      getIcon(name){
          return Assets.load("badges", name, "png");
      },
      inList(badge, list){
            for (var i = 0; i < list.length; i++){
                if (list[i].id === badge.id){
                    return true;
                }
            }
            return false;
      }
  },

  mounted(){
    // var earned = [     {
    //     "id" : "pond-badge",
    //     "name" : "Last Water Bender",
    //     "message": "You were awarded the Last Water Bender Badge!",
    //     "description" : "Freshwater ponds were plentiful in your habitat! Water sources are important for moose to eat, drink, and keep cool during warm weather.",
    //     "icon" : "tree-badge",
    //     "levels" : [{"elementType" : "pond", "value" : 3}]
    //  },
    //  {
    //     "id" : "wolf-badge",
    //     "name" : "Leader of the Pack",
    //     "message": "You were awarded the Leader of the Pack Badge!",
    //     "description" : "Your habitat had a robust population of gray wolves! They kept deer numbers in check, but they also preyed on moose.",
    //     "icon" : "wolf-badge",
    //     "levels" : [{"elementType" : "wolf", "value" : 3}]
    //  },
    //  {
    //     "id" : "deer-badge",
    //     "name" : "Oh, Deer!",
    //     "message": "You were awarded the Oh, Deer Badge!",
    //     "description" : "The white-tailed deer population was kept in check in your habitat. The moose thank you!",
    //     "icon" : "deer-badge",
    //     "levels" : []
    //  }]



      var earned = this.earnedBadges;

      if (this.allBadges && earned){
          var list = [];

          for (var k = 0; k < earned.length; k++){
              var next = earned[k];
              if (next !== null && typeof next !== "string"){
                  next.earned = true;
                  list.push(next);
              }
          }

          this.earnedBadgeCount = list.length;

          for (var i = 0; i < list.length; i++){
              list[i].earned = true;
          }

          for (var j = 0; j < this.allBadges.length; j++){
              var b = this.allBadges[j];
              if (!this.inList(b, list)){
                  b.earned = false;
                  list.push(b);
              }
          }

          this.displayedBadges = list;
      }
  }

}
</script>
<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.badgeProgress{
    width: 100%;

    @media screen and (max-height: 600px) {
        .badges-scroll-container {
            overflow-x: auto;
            height: 70vh;
        }
    }
}

.header{
  text-align: center;
  margin-top: 25px;
  margin-bottom: 5px;

  h2{
      color: $color-orange !important;
      margin: 0;
      display: inline-block;
  }

  .score{
    color: $color-medium-grey !important;
    display: inline-block;
    font-size: 30px;
    margin-left: 20px;
  }
}

ol{
  list-style-type: none;
  padding-left: 0px;
}

</style>
