<template>
  <div :class="['moose', flipped ? 'flipped' : '']"
        @mousedown="hold($event)"
        @touchstart="hold($event)"
        :style="[{left : currentPosition.x - this.size * .5 + 'px'},
                 {top : currentPosition.y - this.size * .5 + 'px'},
                 {width : this.size + 'px'},
                 {height : this.size + 'px'},
                 {zIndex : Math.floor(currentPosition.y -  this.size * .2)},
                 {'--mooseWidth': size + 'px'}, {'--mooseHeight': size + 'px'}]"
                 >

      <div :class="['circle', removed ? 'removed' : '', entering ? 'entering' : '', entered ? 'entered' : '']"></div>

      <div :class="['imageGroup', removed ? 'removed' : '', entering ? 'entering' : '', entered ? 'entered' : '']">
        <div class="image bg-image" :style="[{backgroundImage : `url('${loadedImage}')`}, {width: size + 'px'}, {height: size + 'px'}]"/>
        <Parasites class="parasites" v-if="infectedWithParasites" :width="size" :height="size" />
      </div>

      <audio ref="parasiteSfx" :src="parasiteSource" v-if="infectedWithParasites" preload/>

  </div>
</template>


<script>

import { mapGetters } from "vuex"
import Scene from "@/models/scene.js"
import Assets from "@/components/game/utils/assets"
import Parasites from './Parasites.vue'

export default {
    name: 'Moose',
    components:{
      Parasites
    },
    props:{
      id: String,
      bus: null,
      type: String,
      infectedWithParasites: Boolean,
      keySoundEffect: Boolean,
      startPosition: Object
    },
    data(){
        return{
            currentPosition: {x: 0, y: 0},
            scaledPosition: {x: 0, y: 0},
            currentOffset: {x: 0, y: 0},
            range: {x: 50, y: 280, width: 900, height: 450},
            currentImage: this.standingImage,
            eating: false,
            down: false,
            flipped: false,
            caughtParasites: false,

            entering: false,
            entered: false,
            removed: false,

            appHeight: 1,
            appScale: 1,

            //draggable
            moving: false
        }
    },
    computed:{
        ...mapGetters({
            mooseTypes : "ecosystem/mooseTypes",
            muted: "global/muted",
            interactedOnce: "global/interactedOnce",
        }),

        loadedImage(){
          return Assets.load("ecosystem", this.type, "png")
        },
        clickSource(){
          return Assets.load("sfx", "ui_click", "mp3")
        },
        parasiteSource(){
          return Assets.load("sfx", "play_event_parasites", "mp3")
        },
        baseSize(){
          if (this.mooseTypes && this.mooseTypes[this.type] && this.mooseTypes[this.type].size){
            return this.mooseTypes[this.type].size.width;
          }
          return 160;
        },
        size(){
          return 50 + this.currentPosition.y / this.appHeight * .75 * this.baseSize;
        }
    },
    methods: {
        setScaledPosition(){
          this.scaledPosition.x = this.currentPosition.x / window.innerWidth;
          this.scaledPosition.y = this.currentPosition.y / window.innerHeight;
        },

        randomizePosition(){
            this.currentPosition.x = Math.floor(Math.random() * this.range.width) + this.range.x;
            this.currentPosition.y = Math.floor(Math.random() * (this.range.height - 100)) + this.range.y;
            this.setScaledPosition();

            if (this.type === "cow"){
              this.$emit("addCowPosition", {x : this.currentPosition.x, y: this.currentPosition.y});
            }
        },
        random(min, max){
            return Math.random() * (max - min) + min;
        },
        resize(){
          this.currentPosition.x = this.scaledPosition.x * window.innerWidth;
          this.currentPosition.y = this.scaledPosition.y * window.innerHeight;
          this.appHeight = window.innerHeight;
          let desktopSmlWidth = 1090; // matches CSS breakpoint
    
          this.range.width = window.innerWidth - 280;
          this.range.height = window.innerHeight - 170;
          if (window.innerWidth < desktopSmlWidth) {
            this.range.y = 170;
          }
        },
      remove(id){
        if (id === this.id){
          if (this.infectedWithParasites && !this.removed){
              this.$store.dispatch("ecosystem/removeMoose", {"type": this.type});
          }
          this.entering = false;
          this.$emit("removed");

          var delay = Math.random() * 1500;
          setTimeout(() => {
            if (this.$refs.removeSfx && !this.muted){
              this.$refs.removeSfx.play();
            }
            this.removed = true;
            this.muteParasites();
          }, delay);

        }
      },
      hold(event) {

        this.$emit("hold");
        this.moving = true;

        let clientX = event.type.includes('touch') ? event.touches[0].clientX : event.clientX;
        let clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;

        this.currentOffset.x = clientX / this.appScale - this.currentPosition.x;
        this.currentOffset.y = clientY / this.appScale - this.currentPosition.y;

        document.addEventListener("mousemove", this.move);
        document.addEventListener("mouseup", this.drop);


        document.addEventListener("touchmove", this.move);
        document.addEventListener("touchend", this.drop);
      },

      drop(){
          document.removeEventListener("mousemove", this.move)
          document.removeEventListener("mouseup", this.drop)

          document.removeEventListener("touchmove", this.move)
          document.removeEventListener("touchend", this.drop)

          this.moving = false;
      },

      move(event){
            if (this.$refs.clickSfx && !this.muted){
              this.$refs.clickSfx.play();
            }
            let clientX = event.type.includes('touch') ? event.touches[0].clientX : event.clientX;
            let clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;

            var nextX = clientX / this.appScale - this.currentOffset.x;
            var nextY = clientY / this.appScale - this.currentOffset.y;

            //boundaries
            if (nextX < this.range.x - this.size * .5){
              nextX = this.range.x - this.size * .5
            }
            if (nextY < this.range.y - this.size * .5){
              nextY = this.range.y - this.size * .5
            }

            if (nextX > this.range.width + 150){
              nextX = this.range.width + 150;
            }

            if (nextY > this.range.height + this.size * .5){
              nextY = this.range.height + this.size * .5;
            }

            this.currentPosition.x = nextX;
            this.currentPosition.y = nextY;

        },
        muteParasites(){
          if (this.$refs.parasiteSfx){
            this.$refs.parasiteSfx.loop = false;
          }
        },
        setMute() {
          if (this.$refs.parasiteSfx) this.$refs.parasiteSfx.muted = this.muted;
        }
    },
    watch:{
      muted(newVal, oldVal) {
        if (newVal != oldVal) {
          this.setMute();
        }
      },
      infectedWithParasites(){
        if (this.infectedWithParasites){
          var delay = 0;
          if (!this.keySoundEffect){
            delay = Math.round(Math.random() * 2000) + 1000;
          }
          setTimeout(() => {
            if (this.$refs.parasiteSfx){
              this.$refs.parasiteSfx.loop = true;
              if (this.muted) this.$refs.parasiteSfx.muted = this.muted;
              this.$refs.parasiteSfx.play();
            }
          }, delay);
        }
        else{
          this.muteParasites();
        }
      }
    },
    mounted(){
        this.resize();
        this.$emit("added");


        if (this.bus){
          this.bus.$on("remove", this.remove);
        }

        if (this.type == "calf"){ //calf position is set manually to be by their moms
          this.currentPosition.x = this.startPosition.x;
          this.currentPosition.y = this.startPosition.y;
        }
        else{
          this.randomizePosition();
        }
        window.addEventListener('resize', this.resize);

        if (Math.random() < .5){
          this.flipped = true;
        }

        var delay = Math.random() * 1000;
          setTimeout(() => {
            this.entering = true;
            if (this.$refs.addSfx && this.interactedOnce && !this.muted){
              this.$refs.addSfx.play();
            }
        }, delay);
        setTimeout(() => {
            this.entered = true;
            this.entering = false;
        }, 2000);
    },

}
</script>

<style scoped lang="scss">
@import "@/styles/game.scss";
@import "@/styles/_vars.scss";

.moose{
    position: absolute;
    width: 200px;
    height: 200px;
    // pointer-events: none;

    @media screen and (max-width: $mq-max-width-tablet), screen and (max-height: 600px) {
      transform: scale(.5);
      transform-origin: top left;
    }
}

.image{
    position: absolute;
}

.flipped{
    transform: scaleX(-1) translateX(50%);

    @media screen and (max-width: $mq-max-width-tablet), screen and (max-height: 600px) {
      transform: scale(.5) translateX(50%) rotateY(180deg);
      transform-origin: top left;
    }

}

// .parasites{
//     top: -20px;
// }

/* img{
    position: absolute;
    width: 90px;
    height: 90px;
    user-select: none
} */

.imageGroup{
  opacity: 0;
  &.entered{
    opacity: 1;
  }
  &.entering{
   opacity: 1;
   animation: fadein 750ms ease-in-out;
  }

  &.removed{
    opacity: 0;
    animation: fadeout 1500ms ease-in-out;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50%{
    transform: scale(1.05, 1.05);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  50%{
    transform: scale(1.05, 1.05);
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

.circle{
  position: absolute;
  border-radius: 50%;
  width: 0px;
  height: 0px;
  left: calc(.5 * var(--mooseWidth));
  top: calc(.5 * var(--mooseHeight));
  transform: translate(-50%, -50%);
  z-index: -1;
  //background-color: $color-red;

  &.entering{
    background-color: $color-yellow;
    animation: bubble 1700ms ease-in-out;
  }
  &.removed{
    background-color: $color-red;
    animation: bubble 1700ms ease-in-out;
  }
}

@keyframes bubble {
  0% {
    width: calc(.25 * var(--mooseWidth);
    height: calc(.25 * var(--mooseHeight);
    opacity: 0
  }
  50% {
    opacity: .75;
  }
  100% {
    width: calc(1.1 * var(--mooseWidth);
    height: calc(1.1 * var(--mooseHeight);
    opacity: 0;
  }
}

</style>
