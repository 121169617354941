import { render, staticRenderFns } from "./CaptionsButton.vue?vue&type=template&id=3fb8df2e&scoped=true&"
import script from "./CaptionsButton.vue?vue&type=script&lang=js&"
export * from "./CaptionsButton.vue?vue&type=script&lang=js&"
import style0 from "./CaptionsButton.vue?vue&type=style&index=0&id=3fb8df2e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb8df2e",
  null
  
)

export default component.exports