<template>
  <div class="site-component" v-bind:class="{'home-page': !notHome}">
    <footer class="bg-off-white footer">
      <div class="content-container zoo-touts" v-if="notHome">
          <ul>
              <li class="zoo-link" v-for="(l, i) in content.zoo_links" :key="i">
                <a :href="l.link_url">
                  <div :style="`background-image: url(${l.background_image})`">
                      <p class="h3">{{ l.label }}</p>
                      <span class="button-round">{{ l.link_text }}</span>
                  </div>
                </a>
              </li>
          </ul>
      </div>
      <div class="content-container">
        <div>
          <div class="trust-fund-content">
            <img :src="content.trust_fund_logo" :alt="content.trust_fund_logo_alt_text">
            <p>{{ content.trust_fund_text }}</p>
          </div>
          <div class="copyright">
            <span>{{ content.copyright }}</span>
            <span>{{ content.rights_reserved }}</span>
            <span class="link">
              <a target="_blank" :href="content.privacy_policy_link">{{ content.privacy_policy_text }}</a>
            </span>
          </div>
        </div>
        <div>
          <ul class="social-links">
            <li v-for="(l,i) in content.social_links" :key="i">
              <a :href="l.link" target="_blank">
                <img class="icon-hover" :src="l.icon_hover" :alt="l.title">
                <img class="icon" :src="l.icon" :alt="l.title">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
      content () {
          let globalContent =  require("@/assets/data/global-content.json");

          return globalContent.footer;
      },
      notHome () {
        return this.$route.name != 'home';
      }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/microsite.scss";

footer .content-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  &.zoo-touts {
    padding-top: 0 !important;
    margin-bottom: 3rem;

    ul {
        display: flex;
        width: 100%;

        li.zoo-link {
            height: 20vw;
            max-height: 400px;
            flex-basis: 50%;

            a:focus-visible {
              div {
                outline: solid $color-blue-black 1px;
                outline-offset: 3px;
              }
            }

            a div {
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: right;
                padding: 40px;

                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                flex-direction: column;
            }
            &:first-of-type {
                margin-right: 1rem;
            }
            p {
                color: $color-white;
                max-width: 18ch;
                margin-bottom: 2rem;
            }
            &:nth-child(2) p {
                color: $color-bright-green;
            }
        }
    }
  }

  .trust-fund-content {
    display: flex;
    align-items: center;

    img {
      width: 100px;
      height: auto;
      margin-right: 1rem;
    }

    p {
      font-size: $small-text;
      line-height: 18px;
      flex-grow: 1;
      max-width: 280px;
    }
  }

  .copyright {
    margin-top: $fortypx;
    font-size: $small-text2;

    span {
      display: inline-block;
      margin: .5rem;
      line-height: 1rem;
      color: $color-black;
      opacity: .6;
      transition: opacity 150ms ease-out;

      &.link:hover {
        opacity: 1;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:not(:last-of-type) {
        padding-right: 1rem;
        border-right: solid 1px $color-black;
      }
    }
  }

  .social-links {
    display: flex;
    li {
      margin: 0 1rem;

      .icon-hover {
        display: none;
      }

      &:hover {
        .icon-hover {
          display: block;
        }

        .icon {
          display: none;
        }
      }
    }
  }
}
  
.site-component.home-page {
  padding-top: 0;

  footer .content-container {
    padding-top: 0 !important;
  }
}


@media screen and (max-width: $mq-max-width-desktop) {
  footer {
    padding-top: 100px;
  }
}
@media screen and (max-width: $mq-max-width-desktop-sml) {
  footer {
    padding-top: 40px;

    .zoo-touts {
      ul li.zoo-link a {

        p.h3 {
          font-size: 2rem;
          line-height: 2rem;
        }
      };
    }
  }
}

@media screen and (max-width: $mq-max-width-tablet) {
  
  footer .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    div {
      width: 100%;
    }

    
    .copyright {
      margin-top: 1rem;
    }

    ul.social-links {
      justify-content: flex-end;
      li {
        transform: scale(.8);
        margin: 0 .5rem;
      }
    } 

    &.zoo-touts {
      margin-bottom: 0;

      ul {
        flex-direction: column;

        li {
            &.zoo-link {
              height: unset;

              &:first-of-type {
                margin: 0 0 2rem;
              }

              a div {
                padding: 30px 20px;
                box-sizing: border-box;

                p.h3 {
                  font-size: 2.5rem;
                  line-height: 2.5rem;
                }
              }

            }
        }
      }
    }
  }
}
@media screen and (max-width: $mq-max-width-mobile) {
  footer .content-container {
    &.zoo-touts {
      ul li.zoo-link a div {
        background-position: left;

        p.h3 {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
      }
    }
  }
}
</style>
