<template>
  <div class="draggable" @mousedown="hold($event)" @touchstart="hold($event)"  
    :style="[ {left : currentPosition.x - size.width * .5 + 'px'},
            {top : currentPosition.y - size.height * .5 + 'px'},
            {zIndex : zIndex},
            {width: size.width + 'px'},
            {height: size.height + 'px'},
            {'--bubbleSize': bubbleSize + 'px'},
            {'--bubbleXOffset': size.height > size.width ? -bubbleSize * .5 + size.width * .5 + 'px' : '0px'},
            {'--bubbleYOffset': size.width > size.height ? -bubbleSize * .5 + size.height * .5 + 'px' : '0px'}]">


    <div v-show="!hidden" :class="['circle', removed ? 'removed' : '', addedByEvent ? 'entering' : '']"><span class="sr-only">{{ type }}</span></div>
    <div :class="['image', 'bg-image', removed ? 'removed' : '', entering ? 'entering' : '']" :style="[{backgroundImage: `url('${loadedImage}')`}]" aria-hidden="true"></div>

    <Parasites v-if="infectedWithParasites" :width="size.width" :height="size.height"/>

    <audio ref="parasiteSfx" :src="parasiteSource" v-if="infectedWithParasites" preload/>
  </div>
</template>
<script>


import { mapGetters } from "vuex"
import Scene from "@/models/scene.js"
import Assets from "@/components/game/utils/assets"
import Parasites from './Parasites.vue'

export default {
  name: 'Draggable',
  components:{
      Parasites
  },
  props: {
      creator: Object,
      id: String,
      bus: null,
      type: String,
      locked: Boolean,
      hiddenOnStart: Boolean,
      initial: Boolean,
      createdByKeyboard: Boolean,
      startX: Number,
      startY: Number,
      model: Object,
      addedByEvent: Boolean,
      infectedWithParasites: Boolean,
      infectedWithVirus: Boolean
  },
  data(){
      return{
          appScale: 1,
          currentPosition: {x: 0, y: 0},
          scaledPosition: {x: 0, y: 0},
          currentOffset: {x: 0, y: 0},
          droppedOnce: false,
          added: false,
          hidden: false,

          windowSize: {width: 1, height: 1},
          selectedOnce: false,
          entering: false,
          removed: false,
          inScene: false,

          moving: false,
          toolbarWidth: 150,
          scrollable: true
      }
  },
  computed:{
      ...mapGetters({
          deerParasiteInfectionRate : "ecosystem/deerParasiteInfectionRate",
          season: "season/currentSeason",
          muted: "global/muted",
      }),
      seasonId(){
        if (this.season && this.season.id){
          return this.season.id;
        }
        return "";
      },
      loadedImage(){
        var imageId;
        if (this.type == "wolf" && this.infectedWithVirus){
          imageId = this.type + "-infected";
        }
        else if (this.type === "deer" || this.type === "wolf"){
          imageId = this.type;
        }
        else{
          imageId = this.type + '-' + this.seasonId;
        }
        return Assets.load("ecosystem", imageId, "png")
      },
      parasiteSource(){
        return Assets.load("sfx", "play_event_parasites", "mp3")
      },
      size(){
          if (this.inScene){
            var base = 40;
            var scale = this.currentPosition.y / this.windowSize.height * 2.5;
            var size = { width : base + this.baseWidth * scale, height: base + this.baseHeight * scale};

            return size;
          }

          // sets draggable behind creator size
          // mobiile size is larger to offset shrinking all draggables
          let useMobileSize = this.windowSize.width < 956 || this.windowSize.height < 600;
          return { width: useMobileSize ? 88 : 60, height: useMobileSize ? 88 : 60 }
      },
      bubbleSize(){
        return Math.max(this.size.width, this.size.height);
      },
      baseWidth(){
        if (this.model && this.model.width){
          return this.model.width;
        }
        return 0;
      },
      baseHeight(){
        if (this.model && this.model.height){
          return this.model.height;
        }
        return 0;
      },
      zIndex(){
        if (this.moving){
          return 9999;
        }
        else{
          if (this.type === "pond"){
            return 1;
          }
          if (this.type === "wolf"){
            return Math.floor(this.currentPosition.y - this.size.height * .5);
          }
          else{
            return Math.floor(this.currentPosition.y);
          }
        }
      }
  },
  methods: {
    setPosition(id, randomPosition){
      if (id === this.id){
        this.hidden = true;
        this.added = true;
        this.droppedOnce = true;
        this.currentPosition.x = randomPosition.x;
        this.currentPosition.y = randomPosition.y;
      }
    },
    preventDefault(event) {
      if (!this.scrollable) {
        event.preventDefault(); 
      }
    },
    hold(event) {
        this.scrollable = false;
        if (!this.locked){

          this.moving = true;
          this.hidden = false;

          this.selectedOnce = true;

          let clientX = event.type.includes('touch') ? event.touches[0].clientX : event.clientX;
          let clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;

          this.currentOffset.x = clientX / this.appScale - this.currentPosition.x;
          this.currentOffset.y = clientY / this.appScale - this.currentPosition.y;

          document.addEventListener("mousemove", this.move);
          document.addEventListener("mouseup", this.drop);

          document.addEventListener("touchmove", this.move, {passive: false});
          document.addEventListener('touchforcechange', this.preventDefault, {passive: false});

          document.addEventListener("touchend", this.drop);

          this.$emit('click');

          // player is grabbing new draggable from creator
          if (!this.droppedOnce) this.$emit("hold", this.type); 
      }
    },

    drop(){
        this.scrollable = true;
        document.removeEventListener("mousemove", this.move)
        document.removeEventListener("mouseup", this.drop)

        document.removeEventListener("touchmove", this.move)
        document.removeEventListener("touchend", this.drop)

        this.inTrashLimbo = false;

        this.setScaledPosition();
        if (!this.droppedOnce){
          this.$emit("drop", this.type);
          if (this.inScene){
            this.$store.dispatch("ecosystem/addDraggable", {id: this.id, type: this.type, position: this.currentPosition});
            Scene.addDraggable({id: this.id, type: this.type, position: this.currentPosition});
            this.droppedOnce = true;
            this.$emit("firstDrop");
          }
          else{
            this.currentPosition.x = this.startX + this.size.width * .5 + 5;
            this.currentPosition.y = this.startY + this.size.height * .5 + 5;
          }
        }

        this.moving = false;

    },

    move(event) {
        let clientX = event.type.includes('touch') ? event.touches[0].clientX : event.clientX;
        let clientY = event.type.includes('touch') ? event.touches[0].clientY : event.clientY;

        if(event.type.includes('touch')) {
          this.preventDefault(event);
        }

          var nextX = clientX / this.appScale - this.currentOffset.x;
          var nextY = clientY / this.appScale - this.currentOffset.y;

          if (!this.inScene){
            if (nextX < this.windowSize.width - this.toolbarWidth){
              this.inScene = true;
            }
          }

          //boundaries
          if (nextX < 0){
            nextX = 0;
          }

          //left boundary changes after draggable is added to scene, to avoid overlapping with toolbar
          if (!this.inScene && nextX > this.windowSize.width){
            nextX = this.windowSize.width;
          }
          else if (this.inScene){
            var invisibleBorder = this.windowSize.width - this.toolbarWidth - this.size.width * .5 + 50;

            if (nextX > invisibleBorder){
              nextX = invisibleBorder;
            }

            this.inScene = true;
          }

          if (this.inTrashLimbo && nextY < this.windowSize.height - 90){
            nextY = this.windowSize.height - 90;
          }

          // set maxY 
          let desktopSmlWidth = 1090; // matches CSS breakpoint
          let typeMaxY = {
            'aspen': {
              desktop: 230,
              mobile: 120
            },
            'spruce': {
              desktop: 215,
              mobile: 120
            },
            'wolf': {
              desktop: 250,
              mobile: 130
            },
            'deer': {
              desktop: 230,
              mobile: 120
            },
            'pond': {
              desktop: 280,
              mobile: 150
            },
          }
          let maxY = this.windowSize.width > desktopSmlWidth ? typeMaxY[this.type].desktop : typeMaxY[this.type].mobile;
          if (nextY < maxY){
            nextY = maxY;
          }

          if (nextY > this.windowSize.height ){
            nextY = this.windowSize.height;
          }

          this.currentPosition.x = nextX;
          this.currentPosition.y = nextY;

          this.$emit("moved");
      },

      resize(){
        if (this.inScene){ //handled by setStartPosition if draggable hasn't been moved
            this.currentPosition.x = this.scaledPosition.x * window.innerWidth;
            this.currentPosition.y = this.scaledPosition.y * window.innerHeight;
        }
        this.windowSize.width = window.innerWidth;
        this.windowSize.height = window.innerHeight;

        if (this.windowSize.height < 600) {
          this.toolbarWidth = 100;
        }
      },

      setScaledPosition(){
          this.scaledPosition.x = this.currentPosition.x / window.innerWidth;
          this.scaledPosition.y = this.currentPosition.y / window.innerHeight;
      },

      remove(id){
        if (id === this.id){
          var delay = Math.random() * 1000;
          setTimeout(() => {
            this.entering = false;
            this.removed = true;
          }, delay);

        if ((this.infectedWithParasites && !this.removed) || (this.infectedWithVirus && !this.removed)){
            this.$store.dispatch("ecosystem/removeDraggable", {type: this.type});
            setTimeout(() => {
              this.$el.parentNode.removeChild(this.$el);
              this.$destroy();
            }, 2000);
          }
        }
      },
      setStartPosition(type, position){
        if (this.type === type && !this.inScene){
          this.currentPosition.x = position.x + this.size.width * .5 + 5;
          this.currentPosition.y = position.y + this.size.height * .5 + 5;
          this.setScaledPosition();

          //this.selectedOnce = true;
        }
      },
      setMute() {
        if (this.$refs.parasiteSfx) this.$refs.parasiteSfx.muted = this.muted;
      }
  },
  watch:{
    muted (newVal, oldVal) {
      if (newVal != oldVal) {
        this.setMute();
      }
    },
    infectedWithParasites(){
      if (this.infectedWithParasites){

        var delay = 0;
        if (!this.keySoundEffect){
          delay = Math.round(Math.random() * 2000) + 1000;
        }
        setTimeout(() => {
          if (this.$refs.parasiteSfx){
            this.$refs.parasiteSfx.loop = true;
            if (this.muted) this.$refs.parasiteSfx.muted = this.muted;
            this.$refs.parasiteSfx.play();
            this.$store.dispatch("global/setCaptionText", this.$g('parasites_cc'))
          }
        }, delay);
      }
      else{
        //var fadeoutDelay = Math.round(Math.random() * 4000);
        //setTimeout(() => {
          if (this.$refs.parasiteSfx){
            this.$refs.parasiteSfx.loop = false;
          }
        //}, fadeoutDelay);
      }
    }
  },
  mounted(){
    this.resize();
    window.addEventListener('resize', this.resize);
    if (this.bus){
      this.bus.$on("setStartPosition", this.setStartPosition);
      this.bus.$on("remove", this.remove);
    }

    // if (this.hiddenOnStart){
    //   this.hidden = true;
    // }

    if (this.createdByKeyboard) {
      this.inScene = true;
      this.droppedOnce = true;
      this.$emit('click');
    }

    this.currentPosition.x = this.startX + this.size.width * .5 + 5;
    this.currentPosition.y = this.startY + this.size.height * .5 + 5;
    // this.currentPosition.x += this.size.width * .5;
    // this.currentPosition.y += this.size.height * .5;

    if ((this.addedByEvent || this.initial) && !this.inScene){
        this.entering = true;
        this.inScene = true;
        this.droppedOnce = true;

        var delay = Math.random() * 1000;
          setTimeout(() => {
            this.entering = true;
        }, delay);
      }

    if (this.initial || this.createdByKeyboard) {
        this.$store.dispatch("ecosystem/addDraggable", {id: this.id, type: this.type, position: this.currentPosition, initial: this.initial});
        Scene.addDraggable({id: this.id, type: this.type, position: this.currentPosition});
    }

    this.setScaledPosition();
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.draggable{
    position: absolute;
    touch-action: manipulation;
    //transition: (width, height) 250ms ease-in-out;
    // outline: 1px dashed blue;

      @media screen and (max-width: $mq-max-width-tablet), screen and (max-height: 600px) {
        transform: scale(.5);
        transform-origin: top left;
      }
}

.image{
    position: absolute;
    width: 100%;
    height: 100%;
    //transform: translate(-50%, -50%);

    &.removed{
      opacity: 0;
      animation: fadeout 1000ms ease-in-out;
    }
    &.entering{
      opacity: 1;
      animation: fadein 1000ms ease-in-out;
    }
}


@keyframes fadeout {
  0% {
    opacity: 1;
  }
  50%{
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50%{
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(1, 1);
    opacity: 100;
  }
}

.circle{
  position: absolute;
  border-radius: 50%;
  // width: 0px;
  // height: 0px;
  width: var(--bubbleSize);
  height: var(--bubbleSize);
  // opacity: .5;

  margin-left: var(--bubbleXOffset);
  margin-top: var(--bubbleYOffset);
  //transform: translate(-50%, -50%);
  z-index: -1;
  //background-color: white;
  transform-origin: center center;

  &.entering{
    background-color: $color-yellow;
    opacity: 0;
    animation: bubble 1700ms ease-in-out;
    //transform: translate(25%, 25%);
  }
  &.removed{
    background-color: $color-red;
    opacity: 0;
    //transform: scale(1.5, 1.5);
    animation: bubble 1700ms ease-in-out;
    //transform: translate(25%, 25%);
  }
}

@keyframes bubble {
  0% {
    transform: scale(.25, .25);
    opacity: 0
  }
  50% {
    opacity: .75;
  }
  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}

</style>
