import Vue from 'vue'
import router from './router'

import Root from './plugins/root'
import VueHead from 'vue-head'
import App from './App.vue'
import store from "./gamestore"
import Chartist from "vue-chartist"
import GetGameText from "./plugins/getgametext"
import Capture from './plugins/capture'
import VueAnnouncer from 'vue-announcer'
import 'core-js'
import 'core-js/shim'
import '@babel/polyfill'

// require('@/styles/app.scss')


Vue.use(VueHead)
Vue.use(Root)
Vue.use(Chartist)
Vue.use(GetGameText);
Vue.use(Capture)
Vue.use(VueAnnouncer)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store: store
}).$mount('#app')
