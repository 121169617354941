var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"draggable",style:([ {left : _vm.currentPosition.x - _vm.size.width * .5 + 'px'},
          {top : _vm.currentPosition.y - _vm.size.height * .5 + 'px'},
          {zIndex : _vm.zIndex},
          {width: _vm.size.width + 'px'},
          {height: _vm.size.height + 'px'},
          {'--bubbleSize': _vm.bubbleSize + 'px'},
          {'--bubbleXOffset': _vm.size.height > _vm.size.width ? -_vm.bubbleSize * .5 + _vm.size.width * .5 + 'px' : '0px'},
          {'--bubbleYOffset': _vm.size.width > _vm.size.height ? -_vm.bubbleSize * .5 + _vm.size.height * .5 + 'px' : '0px'}]),on:{"mousedown":function($event){return _vm.hold($event)},"touchstart":function($event){return _vm.hold($event)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hidden),expression:"!hidden"}],class:['circle', _vm.removed ? 'removed' : '', _vm.addedByEvent ? 'entering' : '']},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.type))])]),_c('div',{class:['image', 'bg-image', _vm.removed ? 'removed' : '', _vm.entering ? 'entering' : ''],style:([{backgroundImage: ("url('" + _vm.loadedImage + "')")}]),attrs:{"aria-hidden":"true"}}),(_vm.infectedWithParasites)?_c('Parasites',{attrs:{"width":_vm.size.width,"height":_vm.size.height}}):_vm._e(),(_vm.infectedWithParasites)?_c('audio',{ref:"parasiteSfx",attrs:{"src":_vm.parasiteSource,"preload":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }