<template>
    <div class="badgeResults" v-if="loaded">
        <h2> Badges </h2>
        <label class="score"> {{ this.earnedBadgeCount + '/' + allBadges.length}} </label>
        <ol>
          <li :class="['badge', badge.earned ? 'earned' : '']"
              v-for="badge in displayedBadges"
              :key="badge.id"
              :aria-label="badge.earned ? 'earned badge' : 'unearned badge'">
              <img :src="badge.earned ? getIcon(badge.icon) : getIcon('grey-badge')" :alt="badge.iconDescription" />
              <label>{{badge.name}}</label>
              <div class="line"></div>
              <p v-if="badge.earned">{{badge.description}}</p>
              <p v-else>{{badge.hint}}</p>
          </li>
        </ol>
    </div>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"
import vue from "vue"

export default {
  name: 'BadgeResults',
  data(){
      return {
          displayedBadges: [],
          earnedBadgeCount: 0,
          loaded: false
      }
  },
  computed:{
      ...mapGetters({
          allBadges : "ecosystem/badges",
          earnedBadges : "ecosystem/earnedBadges",
      }),
  },

  methods:{
      getIcon(name){
          return Assets.load("badges", name, "png");
      },
      inList(badge, list){
            for (var i = 0; i < list.length; i++){
                if (list[i].id === badge.id){
                    return true;
                }
            }
            return false;
      }
  },

  mounted(){
    // var earned = [     {
    //     "id" : "pond-badge",
    //     "name" : "Last Water Bender",
    //     "message": "You were awarded the Last Water Bender Badge!",
    //     "description" : "Freshwater ponds were plentiful in your habitat! Water sources are important for moose to eat, drink, and keep cool during warm weather.",
    //     "icon" : "tree-badge",
    //     "levels" : [{"elementType" : "pond", "value" : 3}]
    //  },
    //  {
    //     "id" : "wolf-badge",
    //     "name" : "Leader of the Pack",
    //     "message": "You were awarded the Leader of the Pack Badge!",
    //     "description" : "Your habitat had a robust population of gray wolves! They kept deer numbers in check, but they also preyed on moose.",
    //     "icon" : "wolf-badge",
    //     "levels" : [{"elementType" : "wolf", "value" : 3}]
    //  },
    //  {
    //     "id" : "deer-badge",
    //     "name" : "Oh, Deer!",
    //     "message": "You were awarded the Oh, Deer Badge!",
    //     "description" : "The white-tailed deer population was kept in check in your habitat. The moose thank you!",
    //     "icon" : "deer-badge",
    //     "levels" : []
    //  }]


    this.$store.dispatch("ecosystem/load");

    setTimeout(() => {
        //console.log(this.allBadges);
        var earned = this.earnedBadges;

        // console.log(JSON.stringify(earned));

        if (this.allBadges && earned){
            var list = [];

            for (var k = 0; k < earned.length; k++){
                var next = earned[k];
                if (next !== null && typeof next !== "string"){
                    next.earned = true;
                    list.push(next);
                }
            }

            this.earnedBadgeCount = list.length;

            for (var i = 0; i < list.length; i++){
                list[i].earned = true;
            }

            for (var j = 0; j < this.allBadges.length; j++){
                var b = this.allBadges[j];
                if (!this.inList(b, list)){
                    b.earned = false;
                    list.push(b);
                }
            }

            this.displayedBadges = list;
        }

        this.loaded = true;

    }, 100);
  }

}
</script>
<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

@mixin verticalCenterer{
    position: relative;
    display: inline-block;
    vertical-align: top;
    top: 50%;
    transform: translateY(-50%);
}

.badgeResults{
    width: 100%;

    @media screen and (max-width: $mq-max-width-desktop) {
        .badge {
            p {
                width: 56%;
            }
        }
    }
    @media screen and (max-width: $mq-max-width-desktop-sml) {
        .badge {
            height: 100px;
            p {
                width: 40%;
            }
        }
    }
    @media screen and (max-width: $mq-max-width-tablet) {
        .badge {
            p {
                width: 50%;
            }
       }
    }
    @media screen and (max-width: $mq-max-width-tablet) {
        .badge {
            height: 100px;
            p {
                width: 40%;
            }
       }
    }
    @media screen and (max-width: $mq-max-width-mobile) and (orientation: portrait) {
        .badge {
            height: unset;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            img,
            label,
            p,
            .line {
                transform: unset;
                margin: 1rem 0;
            }
            label {
                text-align: center;
                width: unset;
                margin-top: 0;
            }
            .line {
                height: 1px;
                width: 25%;
                margin: 0;
            }
            p {
                width: 75%;
            }
        }
    }
}

.score{
    position: absolute;
    color: $color-medium-grey;
    font-size: 25px;
    right: 30px;
    top: 30px;
}

h2{
    color: $color-orange !important;
    margin: 0;
    margin-bottom: 20px;
}

ol{
  list-style-type: none;
  padding-left: 0px;
}

</style>
