import GameText from '@/models/gametext'

/**
 * GetText: Provide global `$t` to get a text string based on the provided
 * string `id`.
 */
export default {
    install (Vue, options) {
        Vue.prototype.$g = function(id) {
            return GameText.get(id);
        }
    }
}