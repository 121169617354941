<template>
    <section id="home" class="site-component bg-off-white">
        <section id="home" class="site-component bg-off-white">
            <div class="content-container">
                <section class="intro">
                    <div>
                        <h1 class="text-orange">{{ content.headline }}</h1>
                        <p>{{ content.site_intro_description }}</p>
                    </div>
                </section>
                <section class="game" :style="`background-image: url(${content.game_intro_background_image});`">
                    <img :src="content.game_intro_background_image" :alt="content.game_intro_background_alt" class="sr-only" >
                    <a href="/game/introduction" @click="launchGame($event)" class="big-button">
                        <span class="h5">{{ content.game_start_button_text }}</span>
                        <span class="icon-arrow" aria-hidden="true"></span>
                    </a>
                </section>
            </div>
        </section>
    </section>
</template>

<script>
export default {
    computed: {
        content () {
            let pageContent =  require("@/assets/data/index.json");
            let globalContent =  require("@/assets/data/global-content.json");

            return {...pageContent, ...globalContent}
        }
    },
    head: {
        title: function() {
            return {
                inner: this.content.meta.seo_title,
                separator: '|',
                complement: this.content.site_name
            }
        },
        meta: function() {
            return [
                {rel: 'canonical', href: this.content.meta.canonical_url, id: 'rel-con'},
                {name: 'description', content: this.content.meta.description, id: 'desc'},

                {property: 'og:title', content: this.content.meta.seo_title, id: 'og-title'},
                {property: 'og:description', content: this.content.meta.description, id: 'og-desc'},
                {property: 'og:type', content: 'website', id: 'og-type'},
                {property: 'og:image', content: `${this.content.site_url}${this.content.meta.preview_image}`, id: 'og-image'}
            ]
        }
    },
    methods: {
        launchGame(e) {
            e.preventDefault();

            const match = window.matchMedia('(pointer:fine)');
            if (match && !match.matches && document.body.requestFullscreen) {
                document.body.requestFullscreen()
                    .then(
                        success => {
                            this.$store.dispatch('orientation/fullScreen', true)
                            this.$router.push({ name: 'introduction'});
                        },
                        failure => {console.log('failed make site full screen', failure)}
                    )
            } else {
                this.$router.push({ name: 'introduction'});    
            }            
        }
    },
    mounted () {
        if (document.fullscreenElement) {
            window.screen.orientation.unlock()
            this.$store.dispatch('orientation/fullScreen', false)
            this.$store.dispatch('orientation/setLock', false)

            setTimeout( () => {
                document.exitFullscreen();
            }, 0 );
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/microsite.scss";

#home {
    .content-container {
        display: flex;
        text-align: left;
        padding-bottom: 0;

        .intro {
            padding-right: $sixtyfivepx;
            box-sizing: border-box;
        }
    }
}

.intro, .game {
    flex-basis: 50%;
    height: 80vh;
    min-height: 600px;
}

.intro {
    display: flex;
    align-items: center;

    div {
        height: 65%;
        max-width: 600px;
    }
}

.game {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    mix-blend-mode: multiply;
    display: flex;
    align-items: flex-end;

    div {
        padding: 1.75rem 1.65rem 2.25rem 1.65rem;
        width: 40%;
        height: 30%;
        background-color:#fff;
        position: relative;
        left: -60px;

        p {
            margin: 0;
        }
    }

    .big-button {
        margin-top: 1rem;
        color: $color-white;
        display: block;
        text-decoration: none;
        text-align: center;
        padding-top: 55px;
        background: url('../assets/images/play-bg.png');
        height: 170px;
        width: 280px;
        background-size: contain;
        background-repeat: no-repeat;
        transition: background 150ms ease-in-out;

        .icon-arrow {
            display: block;
            margin-top: .4rem;

            &:after {
                content: url('../assets/icons/go-arrow.svg');
            }
        }

        &:hover {
            background: url('../assets/images/play-bg-hover.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
}

@media screen and (max-width: $mq-max-width-desktop) {
    #home {
        .content-container {
            padding-top: 120px;

            .intro {
                padding-right: $onesixteenpx;
            }

            .intro, .game {
                height: 100%;
            }
        }
    }
}
@media screen and (max-width: $mq-max-width-desktop-sml) {
    #home {
        .content-container {

            .intro {
                padding-right: $onesixteenpx;
                h1 {
                    font-size: 3.5rem;
                    line-height: 3.8rem;
                }
                p {
                    height: 100%;
                }
            }

            .intro, .game {
                height: 100%;
            }
        }
    }
}

@media screen and (max-width: $mq-max-width-tablet) {
    #home {
        .content-container {
            flex-direction: column;

            .intro {
                min-height: 460px;
                padding-right: 0;

                div {
                    max-width: unset;
                }
                h1 {
                    max-width: 20ch;
                }
            }

            .game {
                background-position: right center;
                align-items: flex-start;
                justify-content: flex-end;
            }
        }
    }
}

@media screen and (max-width: $mq-max-width-mobile) {
    #home {
        .content-container {
             .game {
                background-position: right 80px;
                .big-button {
                    transform: scale(.65);
                }

            }
        }
    }
}

</style>
