var model = {};

export default {

    /**
     * @param txtid of text field
     * @description given txtid returns matching text field, if one exists
     * @returns text field
     */
    get(txtid){
        // console.log("model: " + JSON.stringify(model));
        // console.log("obj: " +  JSON.stringify(model[txtid]));
        if (model && model[txtid]){
            
            return model[txtid];
         }
         return "MISSING: " + txtid;
    },

    hydrate(data){
        model = data;
        //console.log(JSON.stringify(model));
    },

}