<template>
    <div class="eventMask">
        <div :class="['event',  this.currentClass]" @click="click">
                <div class="sidebar">
                    <img :class="['icon',  this.currentClass]" :src="arrowIcon"/>
                </div>
                <div class="content">
                    <h3>{{ header }}</h3>
                    <p>{{ description }} </p>
                </div>
        </div>

        <audio ref="eventSfx" :src="eventSource"  preload/>
        <audio ref="chimeSfx" :src="chimeSource"  preload/>
    </div>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"

export default {
  name: 'Event',
  data(){
      return {
          shownOnceInSeason: false,
          reread: false,
      }
  },
  computed:{
      ...mapGetters({
          open: "ecosystem/showEvent",
          currentEvent: "ecosystem/currentEvent",
          accEventDescription: "ecosystem/accEventDescription",
          currentSeason: "season/currentSeason",
          playCount: "ecosystem/playCount",
          muted: "global/muted",
      }),
      arrowIcon(){
          return Assets.load("icons", "arrow-icon-short", "svg")
      },
      chimeSource(){
          return Assets.load("sfx", "play_event_notice", "mp3")
      },
      eventSource(){
          if (this.currentEvent && this.currentEvent.sfx){
            return Assets.load("sfx", this.currentEvent.sfx, "mp3");
          }
          return "";
      },
      currentClass(){
          if (!this.shownOnceInSeason){
              return 'offscreen';
          }
          else if (this.open || this.reread){
              return 'open';
          }
          return 'closed';
      },
      header(){
          if (this.currentEvent && this.currentEvent.header){
              return this.currentEvent.header;
          }
          return "";
      },
      description(){
          if (this.currentEvent && this.currentEvent.description){
              return this.currentEvent.description;
          }
          return "";
      },
  },
  methods:{
      click(){
          if (this.reread){
              this.reread = false;
          }
          else if (this.open){
               this.$store.dispatch("ecosystem/hideEvent");
          }
          else{
              this.reread = true;
          }
      },
      setMute() {
        this.$refs.eventSfx.muted = this.muted;
        this.$refs.chimeSfx.muted = this.muted;
      }
  },
    watch:{
      muted (newVal, oldVal) {
        if (newVal != oldVal) {
            this.setMute();
        }
      },
      open(){
          if (this.open){
              this.$store.dispatch("global/setCaptionText", this.currentEvent.sfx_captions);

              if (this.$refs.chimeSfx){
                this.$refs.chimeSfx.play();
              }
              setTimeout(() => {
                if (this.$refs.eventSfx && this.currentEvent && this.currentEvent.sfx){
                  this.$refs.eventSfx.play();
                }
              }, 500);

              this.$announcer.set(this.$g("acc-event-intro") + this.header + " " + this.description);
              setTimeout(() => {
                  this.$announcer.set(this.accEventDescription);
              }, 500);

              this.shownOnceInSeason = true;
              var header = this.header;
                setTimeout(() => {
                   if (header === this.header){
                     this.$store.dispatch("ecosystem/hideEvent");
                   }
                }, 8000);
            }
      },
      currentEvent(){
         setTimeout(() => {
            if (!this.reread){
                this.$store.dispatch("ecosystem/hideEvent");
            }
        }, 8000);
      },
      currentSeason(){
          this.shownOnceInSeason = false;
      }
  }

}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.eventMask{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.event{
    position: fixed;
    background-color: $color-teal;
    z-index: 9999;
    right: 0;
    margin-top: 3vh;
    border-radius: 7px 0px 0px 7px;
    transition: all 500ms ease-in-out;
    transform: translateX(420px);
    width: 420px;

    &.closed{
        transform: translateX(380px);
    }

    &.open{
        transform: translateX(0);
    }
}


.sidebar{
    display: inline-block;
    vertical-align: top;
    width: 40px;
    .icon{
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        margin-left: 10px;
        margin-top: -10px;
        transition: all 500ms ease-in-out;
        transform-origin: center center;

        &.closed{
            transform: rotate(180deg);
        }
    }
}

.content{
    display: inline-block;
    vertical-align: top;
    width: 330px;
    border-left: #ffffff44 1px solid;
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 5px;

    h3{
        font-size: 30px !important;
        color: $color-orange;
        margin-bottom: 5px;
        text-align: left;
    }

    p{
        font-size: 18px !important;
        line-height: 21px !important;
        margin-top: 0px;
    }
}

@media screen and (max-height: 600px) {

    .event{
        top: 0;
        right: calc(50% - 205px);
        margin-top: 0;
        border-radius: 0px 0px 7px 7px;
        transition: all 500ms ease-in-out;
        transform: translateY(-190px);
        min-width: 420px;
        height: 180px;
        justify-content: center;

        display: flex;
        flex-direction: column-reverse;

        &.closed{
            transform: translateY(-148px);
        }

        &.open{
            transform: translateY(0);
        }
    }

    .content{
        height: 152px;
        border-left: none;
        width: unset;
        border-bottom: #ffffff44 1px solid;

        h3,
        p {
            margin: .5rem 0;
        }
    }

    .sidebar{
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 28px;

        .icon{
            position: relative;
            width: 18px;
            height: 18px;
            margin-left: 10px;
            margin-top: -10px;
            transition: all 500ms ease-in-out;
            transform-origin: center center;
            transform: rotate(270deg);

            display: block;
            margin: 0 auto;
            top: 6px;

            &.closed{
                transform: rotate(90deg);
            }
        }
    }
}



</style>
