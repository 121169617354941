<template>
  <div class="seasonDial">
      <img :src="dial" :style="[{'--angle' : currentAngle + 'deg'}]" />
  </div>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"

export default {
  name: 'SeasonDial',
  props:{
    overrideSeasonIndex: {
      default: -1,
      type: Number
    }
  },
  computed:{
      ...mapGetters({
          season : "season/currentSeason",
          seasonIndex : "season/currentIndex",
      }),
      seasonLabel(){
        if (this.season && this.season.label){
          return this.season.label;
        }
        return "";
      },
      dial(){
          return Assets.load("icons", "season-dial", "png")
      },
      currentAngle(){
        if (this.overrideSeasonIndex !== -1){
          return this.overrideSeasonIndex * -90;
        }
        return this.seasonIndex * -90;
      }
  },
  // mounted(){
  //     document.onkeydown = (evt) => {
  //     if (evt.key === "s") {
  //         console.log("s pressed!");
  //         this.$store.dispatch("season/increment");
  //     }
  //   }
  // }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

  img{
    width: 100%;
    height: 100%;
    transform-origin: center;
    transform: rotate(var(--angle));
  }

</style>
