<template>
  <div class="site-component">
    <nav class="bg-off-white nav" :class="{'fixed-shaded': stickyWithShade}">
    <a href="#main" class="skip-button button-squarish sr-only sr-only-focusable">Skip to content</a>
      <div class="content-container">
        <div class="mobile-menu-container" :class="{'menu-open': openMenu}">
          <router-link :to="{name: 'home'}" class="logo-link">
            <img v-if="!openMenu" class="site-logo" :src="content.header_logo_image" :alt="content.header_site_name">
            <img v-else class="site-logo" :src="content.header_logo_image_white" :alt="content.header_site_name">
            <span class="sr-only">{{ content.header_site_name }}</span>
          </router-link>
          <a class="horizontal-logo" :href="content.mn_zoo_link" target="_blank">
            <img v-if="!openMenu" :src="content.zoo_logo_horizontal" :alt="content.mn_zoo_logo_alt_text">
            <img v-else :src="content.zoo_logo_horizontal_white" :alt="content.mn_zoo_logo_alt_text">
          </a>          <button @click="toggleMenu" class="mobile-menu-toggle" aria-controls="menu">
            <span class="sr-only">Menu</span>
            <span class="menu-icon" :class="{'open': openMenu}" aria-hidden="true"></span>
          </button>
        </div>
        <ul id="menu" :class="{'open': openMenu}">
          <li><router-link :to="{name: 'home'}" exact :tabindex="canTabMenu">{{ content.home_link_text }}</router-link></li>
          <li><router-link :to="{name: 'about-moose'}" :tabindex="canTabMenu">{{ content.moose_page_link_text }}</router-link></li>
          <li><router-link :to="{name: 'research'}" :tabindex="canTabMenu">{{ content.research_page_link_text }}</router-link></li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  data () {
    return {
      openMenu: false,
      windowWidth: window.innerWidth
    }
  },
  watch: {
    '$route': function (oldRoute, newRoute) {
      if (this.windowWidth < 956 && this.openMenu) { // compare width to tablet breakpoint 
        this.openMenu = false;
      }
    }
  },
  computed: {
    content () {
      let content = require("@/assets/data/global-content.json");
      return content.nav;
    },
    stickyWithShade () {
      return this.$route.name != 'home';
    },
    canTabMenu () {
      if (this.windowWidth > 956) return 0;
      return this.openMenu ? 0 : -1
    }
  },
  methods: {
    toggleMenu () {
      this.openMenu = !this.openMenu;
    },
    setWidth () {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted () {
    window.addEventListener('resize', this.setWidth);
  },
  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
}
</script>

<style scoped lang="scss">
@import "@/styles/microsite.scss";

.skip-button {
  position: absolute;
  top: 0;
  left: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: absolute;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

nav.nav {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;

  &.fixed-shaded {
    position: fixed;
    border-bottom: solid 1px $color-medium-grey;
  }

  .mobile-menu-toggle {
    display: none;
  }

  .logo-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: $twentysixpx;
    justify-content: center;
    height: 100%;


    img {
      height: 50px;
    }

    .site-name {
      text-transform: uppercase;
      font-family: 'Acumin';
      font-weight: bold;
      letter-spacing: 1.4px;
    }
  }

  .content-container {
    padding: 0 $section-padding;
    height: 100%;
    display: flex;
    justify-content: space-between;

    .mobile-menu-container {
      display: flex;
      align-items: center;
    }

    .site-logo {
      width: 160px;
      height: auto; 
    }

    .horizontal-logo {
      padding-left: 2rem;
      margin-left: .8rem;
      border-left: solid 1px $color-medium-grey;
      margin-top: 12px;

      img {
        width: 230px;
        height: auto;
      }
    }
  }

  ul {
    display: flex;
    padding-left: 0;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    height: 100%;
    margin: 0;

    li {
      margin: 0 $twentysixpx;
      a {
        font-size: 1.375rem;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        text-decoration: none;
        color: $color-blue-black;
        font-weight: bold;
        font-family: 'Acumin';
        transition: color 150ms ease-out;

        &:hover,
        &.router-link-active {
          color: $color-darker-orange;
          text-decoration: underline;
          text-underline-offset: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: $mq-max-width-desktop) {
  nav.nav {
    .content-container {
      .site-logo {
        width: 120px;
      }

      .horizontal-logo {
        padding-left: 1rem;
        margin-left: .5rem;
        margin-top: 12px;

        img {
          width: 180px;
        }
      }
    }

    ul li {
      margin: 0 1.2rem;
    }
  }
}


@media screen and (max-width: $mq-max-width-tablet) {
  nav.nav {
    position: fixed;
    border-bottom: solid 1px $color-medium-grey;

    .content-container {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }

    .mobile-menu-container {
      padding: 0 $mobile-section-padding;
      display: flex;
      justify-content: flex-start;
      z-index: 3;
      background: $color-off-white;
      flex-grow: 1;

      &.menu-open {
        background: $color-teal;
        border-bottom: solid 1px rgba($color-light-blue, .5);
   
        .horizontal-logo {
          border-left: solid 1px rgba($color-light-blue, .5);
        }
      }

      .mobile-menu-toggle {
        display: block;
        background: transparent;
        border: none;
        cursor: pointer;
        margin-left: auto;

        .menu-icon {
          &:after {
            content: url('../assets/icons/menu-icon.svg');
          }
          &.open {
            &:after {
              content: url('../assets/icons/menu-close-white.svg');
            }
          }
        }

      }

      .horizontal-logo {
        margin-left: 0rem;
        margin-top: 12px;

        img {
          width: 30vw;
        }
      }

      .logo-link {
        img {
          width: 20vw;
          height: auto;
        }
      }
    }

    ul {
      position: absolute;
      right: -100vw;
      padding-top: 2rem;
      top: 100px;
      flex-direction: column;
      align-items: flex-end;
      background: $color-teal;
      width: 100%;
      height: calc(100vh - 100px);
      justify-content: flex-start;
      z-index: 2;
      transition: right 300ms ease-out;
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));

      
      &.open {
        right: 0;
      }

      li {
          text-align: left;
          padding-left: 48px;
          width: 100vw;
          box-sizing: border-box;
          margin: 0;

          a {
            height: 100%;
            width: 100%;
            display: block;
            margin: 1rem 0;
            font-size: 32px;
            color: $color-white;
          }
      }
    }
  }
}
</style>
