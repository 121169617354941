<template>
  <div class="ecosystem game-component" v-if="loaded">
    <Background />

    <router-link class="homeLink" to="/">
      <button class="simple homeButton" tabindex="-1">
        {{$g("quit-button")}}
      </button>
    </router-link>

    <audio ref="natureSfx" :src="natureSource" preload />

    <div class="content">
      <Scene/>

      <MooseGauge/>

      <Event/>
      <Captions :show="showCaptions"/>
      <Hint :show="showHint"/>
      <BadgeAlert/>
      <div>
        <h2 class="sr-only">{{ $g('acc_badge_hint_title') }}</h2>
        <CaptionsButton/>
        <MuteButton/>
        <BadgePanel/>
      </div>
      <SeasonTakeover/>
      <WrapupTakeover/>
    </div>
    <div class="rotate-prompt">
      <h2> {{$g("rotate-prompt")}} </h2>
    </div>
  </div>
</template>

<script>
import Scene from "@/components/game/elements/Scene"
import scene from "@/models/scene"
import SeasonTakeover from "@/components/game/system/SeasonTakeover"
import MooseGauge from "@/components/game/system/MooseGauge"
import Event from "@/components/game/system/Event"
import Background from "@/components/game/Background"
import BadgePanel from "@/components/game/system/badges/BadgePanel"
import BadgeAlert from "@/components/game/system/badges/BadgeAlert"
import WrapupTakeover from "@/components/game/system/WrapupTakeover"
import Hint from "@/components/game/system/Hint"
import MuteButton from "@/components/game/system/MuteButton"
import CaptionsButton from "@/components/game/system/CaptionsButton"
import Captions from "@/components/game/system/Captions"
import LandscapeLockMixin from '../../mixins/LandscapeLockMixin'

import Assets from "@/components/game/utils/assets"

import { mapGetters } from "vuex"

export default {
  name: 'Ecosystem',
  components: {
    Scene,
    SeasonTakeover,
    MooseGauge,
    Event,
    Background,
    BadgePanel,
    BadgeAlert,
    WrapupTakeover,
    Hint,
    MuteButton,
    CaptionsButton,
    Captions
  },
  mixins: [LandscapeLockMixin],
  data(){
    return{
      firstMoveofSeason: false,
      showHint: false,
      seasonIncrementAttempts: 5

    }
  },
  computed:{
      ...mapGetters({
          playCount: "ecosystem/playCount",
          eventPlayCount: "ecosystem/eventPlayCount",
          currentSeason: "season/currentSeason",
          showSeasonTakeover: "season/showTakeover",
          lastEventInSeason: "ecosystem/lastEventInSeason",
          ecosystemLoaded: "ecosystem/loaded",
          textLoaded: "global/loaded",
          showBadgeAlert: "ecosystem/showBadgeAlert",
          muted: "global/muted",
          interactedOnce: "global/interactedOnce",
          showCaptions: "global/captions",
      }),
      seasonId(){
        if (this.currentSeason && this.currentSeason.id){
          return this.currentSeason.id;
        }
        return "";
      },
      homeIcon(){
          return Assets.load("icons", "home-icon", "svg")
      },
      natureSource(){
          return Assets.load("sfx", "season_spring", "mp3");
      },
      loaded(){
        return this.ecosystemLoaded && this.textLoaded;
      },
      content () {
          let textContent =  require("@/assets/data/game/text.json");
          let pageContent =  require("@/assets/data/index.json");
          let globalContent =  require("@/assets/data/global-content.json");

          return {...textContent, ...pageContent, ...globalContent}
      }
  },
  methods:{
    resize(){
       scene.setAppSize(700, 700);
    },
    attemptSeasonSwitch(){ //prevents season switch from happening while badge alert is shown
      if (!this.showBadgeAlert || this.seasonIncrementAttempts > 5){ //seasonIncrementAttempts keeps this from getting out of control
        console.log("incrementing season...")
        setTimeout(() => {
          this.$store.dispatch("season/increment");
        }, 2000);
        this.seasonIncrementAttempts = 0;
      }
      else{
       console.log("wait count: " + this.seasonIncrementAttempts);
       this.seasonIncrementAttempts++;
       setTimeout(() => {
         this.attemptSeasonSwitch();
       }, 5000);
      }
    },
    async capture(){
      this.firstMoveofSeason = false;
      this.$store.dispatch("ecosystem/setEvent", {show : false});
      this.$store.dispatch("season/increment");

      var screenshot = await this.$capture(this.$g("downloaded-image-name"));
      this.$store.dispatch("ecosystem/setScreenshot", {"screenshot" : screenshot});
    },
    loadSpring(){
      if (this.$refs.natureSfx && this.interactedOnce){
          var player = this.$refs.natureSfx;
          if (player.paused){
            //player.source = this.natureSource;
            player.play();
            this.$store.dispatch('global/setCaptionText', this.currentSeason.sfx_captions)
        }
      }
    },
    setMute() {
      this.$refs.natureSfx.muted = this.muted;
    }

  },
  watch:{
      muted (newVal, oldVal) {
        if (newVal != oldVal) {
            this.setMute();
        }
      },
      interactedOnce(){
        if (this.interactedOnce){
          this.loadSpring();
        }
      },
      playCount(){
          if (this.playCount === this.eventPlayCount){
            if (this.lastEventInSeason){
              if (this.seasonId === "winter"){
                  this.capture();
              }
              else{
                this.firstMoveofSeason = false;
                //this.$store.dispatch("ecosystem/setEvent", {show : false});
                this.attemptSeasonSwitch();
              }
            }
            else{
              //console.log("looking for event...");
              setTimeout(() => {
                this.$store.dispatch("ecosystem/setEvent", {show : true, season : this.seasonId});
            }, 5000);
            }
          }
      },
      currentSeason(){
        this.$store.dispatch("ecosystem/setSeason", this.currentSeason.id);
      },
  },
  head: {
      title: function() {
          return {
              inner: this.content.ecosystem_page_title,
              separator: '|',
              complement: this.content.site_name
          }
      },
      meta: function() {
          return [
              {rel: 'canonical', href: this.content.meta.canonical_url, id: 'rel-con'},
              {name: 'description', content: this.content.meta.description, id: 'desc'},

              {property: 'og:title', content: this.content.meta.page_title, id: 'og-title'},
              {property: 'og:description', content: this.content.meta.description, id: 'og-desc'},
              {property: 'og:type', content: 'website', id: 'og-type'},
              {property: 'og:image', content: `${this.content.site_url}${this.content.meta.preview_image}`, id: 'og-image'}
          ]
      }
  },
  mounted(){
    console.log("ecosystem mounted!");
    this.firstMoveofSeason = false;
    this.$store.dispatch("ecosystem/load");
    this.$store.dispatch("ecosystem/reset");
    this.$store.dispatch("season/load");
    this.$store.dispatch("season/reset");
    this.$store.dispatch("global/load");
    this.$store.dispatch("ecosystem/setSeason", this.currentSeason.id);

    this.resize();
    window.addEventListener('resize', this.resize);


    setTimeout(() => {
      this.loadSpring();
    }, 10);


    setTimeout(() => {
      this.showHint = true;

      setTimeout(() => {
        this.showHint = false;
      }, 8000);

    }, 2000);

    // setTimeout(() => {
    //   this.$store.dispatch("season/increment");
    //   this.$store.dispatch("season/increment");
    //   this.$store.dispatch("season/increment");
    //   this.$store.dispatch("season/increment");
    //
    //   //this.capture();
    // }, 3000);

  }
}
</script>

<style scoped lang="scss">
@import "@/styles/game.scss";

.ecosystem{
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.rotate-prompt {
  display: none;
}


  @media screen and (orientation: portrait) and (pointer: coarse) {
  .ecosystem {
    width: 100vh;
    height: 100vw;
    transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%)) rotate(90deg);

    /deep/ .background {
      width: 100vh;
      height: 100vw;

      .tile {
        width: 100vh;
        height: 100vw;
      }
    }
  }

  .content {
    display: none;
  }

  .rotate-prompt {
    display: block;
    position: absolute;

    overflow: hidden;
    background: rgba(0,0,0,.25);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    transform: unset;
    padding-left: 5%;
    box-sizing: border-box;

    h2 {
      margin-top: 20%;
      color: $color-yellow;
      font-size: 35px !important;
      line-height: 35px !important;
    }
  }
}

.scene{
    position: absolute;
    width: 100vw;
    height: 100vh;
}


</style>
