export default{
    load(subfolder, filename, extension){
        //console.log("looking for: " + subfolder + "/" + filename + "." + extension)
        var file;
        try {
            file = require("../../../assets/" + subfolder + "/" + filename + "." + extension);
            //console.log(file);
        } 
        catch (e) {
            file = "";
        }
        return file;
    }
}