<template>
  <div class="draggableCreator" :class="{'focused': focused}">
      <div class="background shadow">
        <div :is="childType"
             class="draggable"
             :id="type + 'key'"
             :type="type"
             :locked="true"
             v-if="!labelVisible">
        </div>
        <div v-if="labelVisible">
          <label aria-hidden="true">{{ label }}</label>
        <img :src="icon" :aria-label="accDescription" />
        <button @focus="focus" @blur="blur" ref="button" class="hidden" :aria-label="$g('acc-add-draggable-intro') + type" @click="add"/>
      </div>
      </div>
  </div>
</template>

<script>
import Draggable from "./Draggable.vue"
import Assets from "@/components/game/utils/assets"
import { mapGetters } from "vuex"
import Vue from "vue"

export default {
  name: 'DraggableCreator',
  components:{
      Draggable
  },
  props: {
      type: String,
      childType: String,
      bus: null,
      model: Object
  },
  data(){
      return {
        labelVisible: true,
        focused: false
      }
  },
  computed:{
      ...mapGetters({
          season : "season/currentSeason",
          elementTypes: "ecosystem/currentElementTypes",
      }),
      seasonId(){
        if (this.season && this.season.id){
          return this.season.id;
        }
        return "";
      },
      label(){
        if (this.model && this.model.label){
          return this.model.label;
        }
        return "";
      },
      icon(){
          var seasonalIcon = Assets.load("ecosystem", this.type + "-" + this.seasonId + "-icon", "png");
          if (seasonalIcon === ""){
            return Assets.load("ecosystem", this.type + "-icon", "png");
          }
          return seasonalIcon;
      },
      accDescription(){
        if (this.model && this.model.acc_description && this.model.acc_description[this.seasonId]){
          return this.model.acc_description[this.seasonId];
        }
        return "";
      }
  },
  methods:{
    getPosition(){
      var element = document.getElementsByClassName("draggable")[0];

      if (element){
        var rect = element.getBoundingClientRect()
        return {x: rect.left, y: rect.top};
      }
      else{
        return {x: 0, y: 0};
      }
    },
    showLabel(){
      this.labelVisible = true;
    },
    hideLabel(type){
      if (type === this.type){
        this.labelVisible = false;
      }
    },
    add(){
      let typeIndex = this.elementTypes.findIndex(element => element.type == this.type);
      this.$emit('addRandom', this.type, typeIndex);
    },
    focus () {
      this.focused = true;
    },
    blur () {
      this.focused = false;
    }
  },
  mounted(){
    if (this.bus){
      this.bus.$on("showLabel", this.showLabel);
      this.bus.$on("hideLabel", this.hideLabel);
    }
  }

}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";


.draggableCreator {
    position: relative;
    z-index: 999;
    pointer-events: none;

    &.focused {
      outline: solid $color-black 1px;
      outline-offset: 2px;
    }
}

.background{
    width: 70px;
    height: 70px;
    border-radius: 8px;
    background-color: #CFF6F8;
}

.draggable{
   position: absolute;
   margin-left: 48px;
   margin-top: 48px;

   @media screen and (max-width: $mq-max-width-tablet), screen and (max-height: 600px) {
    margin-left: 48px;
    margin-top: 48px;
   }
}

label{
  position: absolute;
  width: 70px;
  font-size: 18px !important;
  color: $color-teal !important;
  text-align: center !important;
  margin-top: 5px;
}

img{
  position: absolute;
  width: 70px;
  height: 70px;
}

@media screen and (max-width: $mq-max-width-tablet), screen and (max-height: 600px) {
  $mobile-dimension: 50px;
  .background{
      width: $mobile-dimension;
      height: $mobile-dimension;
  }


  label{
    width: $mobile-dimension;
    font-size: 17px !important;
    margin-top: 2px;
  }

  img{
    width: $mobile-dimension;
    height: $mobile-dimension;
  }

}

</style>
