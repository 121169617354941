<template>
    <section id="not-found">
      <Nav></Nav>
      <h1>404 - not found</h1>
      <Footer></Footer>
    </section>
</template>

<script>
import Nav from '@/components/Nav';
import Footer from '@/components/Footer';

export default {
  components: {
    Nav,
    Footer
  },
}
</script>

<style scoped lang="scss">

</style>
