import domtoimage from "dom-to-image"

var appElement = null;
var captureImage = "";
var captureName = "";

var appWidth = -1;
var appHeight = -1;

/**
 * Capture: Provide global `$capture` to capture screenshots and `$download` to download most recent one
 */
export default {
    install (Vue, options) {
        Vue.prototype.$setAppElement = function(element) {
            return setAppElement(element);
        },
        Vue.prototype.$setAppSize = function(width, height) {
            return setAppSize(width, height);
        },
        Vue.prototype.$capture = async function(filename) {
            return capture(filename);
        },
        Vue.prototype.$captureElement = function(filename, element) {
            return captureElement(filename, element);
        },
        Vue.prototype.$download = function() {
            return download();
        },
        Vue.prototype.$captureAndDownload = function(filename) {
            return captureAndDownload(filename);
        },
        Vue.prototype.$downloadAccessible = function(filename, text) {
            return downloadAccessible(filename, text);
        }
    }
}

/**
 * @param element to be captured -- will capture all child elements
 */
function setAppElement(element){
    appElement = element;
}

/**
 * @param width of app
 * @param height of app
 */
function setAppSize(width, height){
    appWidth = width;
    appHeight = height;
}

/**
 * @description wrapper for capture + download
 * @param filename name of file to download
 */
async function captureAndDownload(filename){
    await capture(filename);
    await download();
}

/**
 * @description captures screenshot of base app element and converts it to image file
 * @param filename name of file to download later
 * @returns converted image file
 */
async function capture(filename){
    return await captureElement(filename, appElement);
}

/**
 * @description captures screenshot and converts it to image file
 * @param filename name of file to download later
 * @returns converted image file
 */
async function captureElement(filename, element){
    if (element){
        captureName = filename;

        var params = {};

        if (appWidth !== -1){
          params = { width : appWidth, height : appHeight }
        }

        params.filter = (node) => {
            if (node.classList) {
                return !node.classList.contains('wrapupTakeover') &&
                       !node.classList.contains('event') &&
                       !node.classList.contains('badgeAlert') &&
                       !node.classList.contains('badgePanel') &&
                       !node.classList.contains('muteButton') &&
                       !node.classList.contains('homeButton');
            } else {
                return true;
            }
        };

        var image = await domtoimage.toPng(element, params)
        .then(function (data) {
            captureImage = data;
            return data;
        })
        .catch(function (error) {
            console.error('ERROR image capture failed: ', error);
            return;
        });

        return image;
    }
}

/**
 * @description downloads captureImage to file named captureName if both exist
 */
async function download(){
    if (captureImage === ""){
        console.log("ERROR: no image saved for download")
    }
    else if (captureName === ""){
        console.log("ERROR: captured file is not named, will not download.")
    }
    else {
        const url = captureImage;
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', captureName);
        document.body.appendChild(link)
        link.click();
    }
}

async function downloadAccessible(filename, text){
    const link = document.createElement('a');
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    link.setAttribute('download', filename);

    link.style.display = 'none';
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}
