/**
 * pulls text data, hands off to TextModel for hydration
 */
import GameText from "../models/gametext"
var namespace = "Global.Store:: "


export default {
    namespaced: true,
    state: {
        error: false,
        error_msg: null,
        loading: false,
        loaded: false,
        elementIntros: [],
        muted: false,
        captions: false,
        captionText: null,
        interactedOnce: false
    },
    getters: {
        error: state => state.error,
        error_msg: state => state.error_msg,
        loading: state => state.loading,
        loaded: state => state.loaded,
        elementIntros: state => state.elementIntros,
        muted: state => state.muted,
        captions: state => state.captions,
        captionText: state => state.captionText,
        interactedOnce: state => state.interactedOnce
    },
    mutations: {
        hydrateTextData(state, data) {
            if (typeof data === "object") {
                GameText.hydrate(data);
            }
            else{
                state.error = true
                state.error_msg = "ERROR: text data missing or malformatted."
                return;
            }
        },

        setLoading(state, status) {
            state.loading = status;
        },
        setLoaded(state, status) {
            state.loaded = status;
        },

        setError(state, errorMessage){
            state.error = true;
            state.error_msg = errorMessage;
        },
        setMuted(state, data){
            state.muted = data;
        },
        setCaptions(state, data){
            state.captions = data;
        },
        setCaptionText(state, data){
            state.captionText = data;
        },
        setInteractedOnce(state, data){
            state.interactedOnce = data;
        }
    },

    actions: {
        load({commit, state}) {
            if (state.loaded) {
                return;
            }

            commit('setLoading', true)

            var data = require("@/assets/data/game/text.json");
            commit('hydrateTextData', data)

            state.elementIntros = require("@/assets/data/game/element-intros.json");

            commit('setLoading', true)
            commit('setLoaded', true)
        },
        setMuted({commit, state}, data) {
          commit('setMuted', data);
        },
        setCaptions({commit, state}, data) {
          commit('setCaptions', data);
        },
        setCaptionText({commit, state}, data) {
          commit('setCaptionText', data);
        },
        setInteractedOnce({commit, state}, data) {
          commit('setInteractedOnce', data);
        }
    },
}
