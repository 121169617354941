<template>
  <div class="background">
    <h1 class="sr-only">{{ $g('acc_scene_description') }}</h1>
    <p class="sr-only" id="ecosystem-desc"> {{ $g('acc_scene_status_intro') }} {{ accStatus }}</p>
    <div v-for="season in seasons"
         :key="season.id + '-background'"
         aria-hidden="true"
         class="tile"
         :style="[{backgroundImage: `url('${getBackground(season.id)}'), url('${getGroundTile(season.id)}')`},
                  {opacity: (season.id === seasonId || (overrideSeasonId !== '' && season.id === overrideSeasonId)) ? 1 : 0}]">
    </div>
  </div>
</template>

<script>

//:style="[{backgroundImage: `url('${background}')`}

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"

export default {
  name: 'Introduction',
  props:{
    overrideSeasonId: {
      default: '',
      type: String
    }
  },
  data(){
    return {
      accStatus: "",
    }
  },
  computed:{
      ...mapGetters({
        currentSeason : "season/currentSeason",
        seasons : "season/seasons",
        moosePopulation: "ecosystem/moosePopulation",
        elementPopulation: "ecosystem/elementPopulation",
        elementTypes: "ecosystem/currentElementTypes",
        playCount: "ecosystem/playCount"
      }),
      seasonId(){
        if (this.currentSeason && this.currentSeason.id){
          return this.currentSeason.id;
        }
        return "";
      },
  },
  methods:{
    getBackground(season){
      return Assets.load("backgrounds", season, "jpg")
    },
    getGroundTile(season){
      return Assets.load("backgrounds", `ground-tile-${season}`, "jpg")
    },
    updateAccStatus(){
      var status = this.moosePopulation.length + " moose";

      var elementCount = this.elementTypes.length;
      var index = 0;
      for (var element in this.elementPopulation){
        var divider = " ";
        if (index < elementCount - 1){
          divider = ", "
        }
        status += divider + this.elementPopulation[element] + " " + element;
      }

      this.accStatus = status;
    }
  },
  watch:{
    playCount(){
      this.updateAccStatus();
    }
  },
  mounted(){
    this.$store.dispatch("season/load");
    setTimeout(() => {
      this.updateAccStatus();
    }, 500);
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";

.background{
    position: absolute;
    width: 100%;
    height: 100%;

}

.tile{
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: 1600px 925px, 120px 120px;
  background-repeat: repeat-x, repeat;

  @media screen and (max-width: $mq-max-width-desktop-sml) {
    background-size: 832px 481px, 80px 80px;
  }
}

</style>
