<template>
  <div class="conclusion game-component" v-if="loaded">
    <router-link class="homeLink" to="/">
      <button class="simple homeButton" tabindex="-1">
        {{$g("quit-button")}}
      </button>
    </router-link>

    <div class="rightButtons">
      <button class="downloadButton simple outline" @click="download">{{$g("download-button")}}</button>
      <button class="replayButton simple fill" @click="replay">{{$g("replay-button")}}</button>
    </div>

    <div class="content">
        <div class="left">
          <section>
            <h2>{{$g("conclusion-headline")}}</h2>
            <p class="cta">{{$g("conclusion-body")}}</p>
            <img class="screenshot" :src="screenshot" :alt="$g('screenshot-description')" :style="[{'border' : screenshot === null ? '1px solid #AFB0A8' : ''}]"/>
            <MooseChart class="mooseChart"/>
            <h4> {{$g("report-headline")}}</h4>
            <p class="description" v-html="$g('report-body')"></p>
          </section>
        </div>

        <div class="right">
          <section>
            <BadgeResults class="badgeResults" />
          </section>
        </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

import MooseChart from "@/components/game/system/MooseChart"
import BadgeResults from "@/components/game/system/badges/BadgeResults"
import Assets from "@/components/game/utils/assets"

export default {
  name: 'Conclusion',
  components: {
    MooseChart,
    BadgeResults
  },
  computed:{
      ...mapGetters({
         badges : "ecosystem/badges",
         screenshot : "ecosystem/screenshot",
         loaded: "global/loaded",
         elementPopulation: "ecosystem/elementPopulation",
         moosePopulation: "ecosystem/moosePopulation",
         moosePopHealth: "ecosystem/moosePopHealth",
      }),
      homeIcon(){
          return Assets.load("icons", "home-icon", "svg")
      },
      content () {
          let textContent =  require("@/assets/data/game/text.json");
          let pageContent =  require("@/assets/data/index.json");
          let globalContent =  require("@/assets/data/global-content.json");
          return {...textContent, ...pageContent, ...globalContent}
      },
      pageTitle(){
        return this.$g("conclusion-page-title");
      }
  },
  methods:{
      async download(){
        this.$setAppSize(window.innerWidth, window.innerHeight);

        await Promise.all(
          [
            this.$download(), 
            this.$downloadAccessible("moose-mission-report.txt", 
                                        this.createAccessibleDescription())
          ]
        );
      },
      replay(){
          this.$router.push("introduction");
      },
      createAccessibleDescription () {
          let elementCount = `${this.$g('acc_scene_status_intro')}`;
          let elements = Object.keys(this.elementPopulation);
          let english = {
            spruces: 'spruce trees',
            aspens: 'aspen saplings',
            deers: 'deer',
            ponds: 'lakes',
            wolfs: 'wolves',
            spruce: 'spruce tree',
            aspen: 'aspen sapling',
            deer: 'deer',
            pond: 'lake',
            wolf: 'wolf'
          }
          elements.forEach((ele,i) => {
            let count = this.elementPopulation[ele];
            elementCount += ` ${count} ${count > 1 ? english[`${ele}s`] : english[ele]}`;
            if (i < elements.length) elementCount += ',';
          })
          elementCount += ` and ${this.moosePopulation.length} moose.`

          let healthy = `\r\n${this.$g('acc-download-health-status')} ${this.moosePopHealth}.`
          return elementCount + healthy;
      }
  },
  head: {
      title: function() {
          return {
              inner: this.content.conclusion_page_title,
              separator: '|',
              complement: this.content.site_name
          }
      },
      meta: function() {
          return [
              {rel: 'canonical', href: this.content.meta.canonical_url, id: 'rel-con'},
              {name: 'description', content: this.content.meta.description, id: 'desc'},

              {property: 'og:title', content: this.content.meta.page_title, id: 'og-title'},
              {property: 'og:description', content: this.content.meta.description, id: 'og-desc'},
              {property: 'og:type', content: 'website', id: 'og-type'},
              {property: 'og:image', content: `${this.content.site_url}${this.content.meta.preview_image}`, id: 'og-image'}
          ]
      }
  },
  mounted(){
      this.$store.dispatch("global/load");
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.conclusion{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: $color-teal;
    overflow: hidden;

    @media screen and (max-width: $mq-max-width-tablet) {
      overflow: auto;
    }
}

.outlineButton{
  position: absolute;
  left: 10px;
  top: 10px;
  padding: 6px 16px 3px 16px;
  border: 2px solid $color-orange !important;
  border-radius: 20px;
  background-color: transparent;
  filter: drop-shadow(0 0 4px rgba(0, 0, 0, .4));

  .homeLink{
    color: $color-orange !important;
  }
}

.rightButtons{
  position: absolute;
  top: 10px;
  right: 10px;

  .downloadButton{
    color: $color-orange !important;
    padding: 6px 16px 3px 16px;
    border: 2px solid $color-orange !important;
    border-radius: 20px;
    background-color: transparent;
    margin-right: 10px;
  }
}

.content{
    position: absolute;
    width: 90vw;
    height: 85vh;
    min-height: 500px;
    left: 50%;
    top: 50%;
    margin-top: 20px;
    transform: translate(-50%, -50%);
    //background-color: $color-medium-teal;

    @media screen and (max-width: $mq-max-width-tablet) {
      width: 85vw;
      min-height: unset;
      height: 95vh;
      padding-bottom: 2rem;
      box-sizing: border-box;
      margin-top: 60px;
    }
}

.left, .right{
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 48%;
  min-width: 400px;
  height: 100%;
  border-radius: 15px;
  background-color: $color-white;
  overflow-y: auto;

  section{
    padding: 30px;
  }

}

.left{
  margin-right: 20px;

  h2{
    color: $color-orange !important;
  }

  .cta{
    color: $color-black !important;
    width: 100%;
    text-align: center;
  }

  .screenshot{
    width: 100%;
    min-width: 100%;
    height: 30vh;
    min-height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
  }

  h4{
    color: $color-orange !important;
    margin: 0;
    margin-top: 20px;
    text-align: left;
    z-index: 10;
  }

  .description{
    color: $color-black !important;
    margin-top: 10px;
    z-index: 1;

    /deep/ a {
      color: $color-orange;
      text-decoration: none;

      &:hover {
        color: $color-darker-orange;
      }
    }
  }

  label{
    position: absolute;
    color: $color-black;
    font-size: 12px;
    top: 60%;
    left: 1vh;
    transform: rotate(-90deg);
  }
}

.right{

}

@media screen and (max-width: $mq-max-width-tablet) {
  .left, .right {
    width: 100%;
    min-width: unset;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 2.5rem;
    height: unset;
  }
}

@media screen and (max-width: $mq-max-width-tablet) {
  .left, .right {
    h4 {
      margin-top: 55px;
    }
  }
}

h2{
  color: $color-orange;
  margin: 0;
  width: 100%;
}

p{
  color: $color-black;
}


</style>
