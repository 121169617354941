<template>
    <div>
      <div ref="text" class="content shadow">
        <p class="caption"> {{ phrase }} </p>
      </div>
    </div>
</template>

<script>


export default {
  name: 'Caption',
  props: {
    delay: Number,
    order: Number,
    phrase: String
  },
  watch: {
    'phrase' () {
      this.play();
    }
  },
  methods: {
    play () {
      setTimeout(() => {
        this.$refs.text.classList.add('play');
        setTimeout(() => {
          this.$refs.text.classList.remove('play');
          this.$emit('finished', this.order);
        }, 2500)
      }, this.delay)
    }
  },
  mounted () {
    this.play();
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.content{
  position: absolute;
  top: 0;
  opacity: 0;
  transition: opacity 250ms;
  padding: 5px;
  background-color: rgba($color-black, .75);

  &.play {
    opacity: 1;
  }
}

p{
  font-size: 16px !important;
  max-width: 30ch;
  color: $color-white;
  text-transform: none !important;
  text-align: center;
  margin: 0px;
  padding: 5px 10px 0px 10px;
}


</style>
