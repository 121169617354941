<template>
  <div class="introduction game-component" v-if="loaded">
    <Background overrideSeasonId="summer"/>

    <router-link class="homeLink" to="/">
      <button class="simple homeButton" tabindex="-1">
        {{$g("quit-button")}}
      </button>
    </router-link>

    <div class="content">
        <h2> {{$g("intro-headline")}} </h2>

        <div class="element-mobile-container">
          <section v-for="intro in elementIntros" :key="intro.description">
            <img :src="getIcon(intro.icon)" :alt="intro.iconDescription"/>
            <span class="label"> {{ intro.description }} </span>
          </section>
        </div>

        <button class="introButton simple"  @click="play($event)">
          <div class="buttonText">
            <h3> {{$g("button-header")}} </h3>
            <p> {{$g("button-body")}} </p>
          </div>
          <img class="startIcon" :src="arrowIcon" />
        </button>
    </div>

     <div style="display: none;" aria-hidden="true">
      <router-link to="/game/ecosystem" ></router-link>
      <router-link to="/game/conclusion"></router-link>
    </div>
  </div>
</template>

<script>

import Background from "@/components/game/Background"
import Assets from "@/components/game/utils/assets"

import { mapGetters } from "vuex"
import LandscapeLockMixin from '../../mixins/LandscapeLockMixin'

export default {
  name: 'Introduction',
  components:{
    Background
  },
  mixins: [LandscapeLockMixin],
  data(){
    return {
      // intros: [{description : "deer-intro", icon : "deer-intro-icon"},
      //          {description : "wolf-intro", icon : "wolf-intro-icon"},
      //          {description : "resource-intro", icon : "resource-intro-icon"} ]
    }
  },
  computed:{
      ...mapGetters({
        //   season : "season/currentSeason",
          loaded: "global/loaded",
          elementIntros: "global/elementIntros"
      }),
      arrowIcon(){
          return Assets.load("icons", "arrow-icon-yellow", "svg")
      },
      homeIcon(){
          return Assets.load("icons", "home-icon", "svg")
      },
      content () {
          let textContent =  require("@/assets/data/game/text.json");
          let pageContent =  require("@/assets/data/index.json");
          let globalContent =  require("@/assets/data/global-content.json");
          return {...textContent, ...pageContent, ...globalContent}
      },
  },
  methods:{
      getIcon(name){
          return Assets.load("icons", name, "png");
      },
      play(e){
        e.preventDefault();

        const match = window.matchMedia('(pointer:fine)');
        if (match && !match.matches && document.body.requestFullscreen) {
          document.body.requestFullscreen()
            .then(
              success => {
                this.$store.dispatch('orientation/fullScreen', true)
                    this.$router.push({ name: 'ecosystem'});
                },
              failure => {console.log('failed make site full screen', failure)}
            )
        } else {
          this.$router.push({ name: 'ecosystem'});
        }
      }
  },
  head: {
      title: function() {
          return {
              inner: this.content.intro_page_title,
              separator: '|',
              complement: this.content.site_name
          }
      },
      meta: function() {
          return [
              {rel: 'canonical', href: this.content.meta.canonical_url, id: 'rel-con'},
              {name: 'description', content: this.content.meta.description, id: 'desc'},

              {property: 'og:title', content: this.content.meta.page_title, id: 'og-title'},
              {property: 'og:description', content: this.content.meta.description, id: 'og-desc'},
              {property: 'og:type', content: 'website', id: 'og-type'},
              {property: 'og:image', content: `${this.content.site_url}${this.content.meta.preview_image}`, id: 'og-image'}
          ]
      }
  },
  mounted(){
    this.$store.dispatch("global/load");
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.introduction{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
}

.content{
    position: absolute;
    width: 600px;
    height: 320px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    h2 {
        font-size: 50px !important;
        line-height: 56px !important;
        width: 650px;
        margin-top: 0;
        margin-left: 50%;
        transform: translateX(-50%);
        margin-bottom: 30px;
        line-height: 50px;
        letter-spacing: 1.1px;
    }

    .element-mobile-container {
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;
    }

    section {
      width: 160px;
      margin-right: 40px;
      display: inline-block;

      img{
        width: 160px;
        height: 100px;
        object-fit: contain;
      }
      
      .label{
        width: 160px;
        margin-top: 10px;
        display: inline-block;
        color: $color-white;
        font-family: $font-family-body !important;
        text-transform: none !important;
        font-size: 18px;
        text-align: center !important;
      }
    }

    .introButton{
        width: 450px;
        margin-left: 50%;
        margin-top: 40px;
        transform: translateX(-50%);

        .buttonText{
          display: inline-block;
          vertical-align: middle;
          margin-right: 30px;

          h3{
            font-size: 50px;
            line-height: 56px;
            color: $color-yellow !important;
            margin: 0;
          }

          p{
            font-size: 20px;
            color: $color-yellow !important;
            text-transform: none;
            text-align: center;
            margin: 0;
          }
        }

        .startIcon{
          display: inline-block;
          vertical-align: middle;
          width: 60px;
          height: 60px;
          object-fit: contain;
          transform: scaleX(-1);
        }
    }


    // tablet and mobile
    @media screen and (max-height: 600px) and (max-width: $mq-max-width-tablet) {
      overflow: hidden;
      background: rgba(0,0,0,.25);
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      transform: unset;
      box-sizing: border-box;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      -webkit-flex-direction: column;
      justify-content: center;
      -webkit-justify-content: center;

      h2 {
        font-size: $large-text !important;
        line-height: 2rem !important;
        width: 35ch;
        margin-bottom: 1rem;
      }

      .element-mobile-container {
        width: 80vw;

        section {
          width: 33%;
          margin: 0;
          display: inline-  block;

          .label {
            width: 100%;
            font-size: .85rem;
          }

          img {
            width: 60px;
            height: 60px;
            display: block;
            margin: 0 auto;
          }
        }
      }

      .introButton {
          margin-top: 1rem;
          .buttonText {
            h3 {
              font-size: $large-text;
              line-height: 2rem;
            }
            p {
              font-size: $small-text2;
              line-height: 1rem;
            }
          }
      }
    }
}


@media screen and (orientation: portrait) {
  .introduction {
    overflow: hidden;
  }

  .content {
    background: rgba(0,0,0,.25);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    transform: unset;
    padding-left: 0;
    box-sizing: border-box;
    display: block;
    padding-top: 1.25rem;

    h2 {
      margin: 30px auto 0;
      transform: unset;
      width: 29ch;
      font-size: 30px !important;
      line-height: 32px !important;
    }

    .element-mobile-container {
      section {
        width: 100%;
        margin: .45rem 0;    
        display: inline-flex;
        display: -webkit-inline-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: center;
        -webkit-justify-content: center;
  
        
        img {
          margin: 0 1rem;
          display: inline-block;
        }

        .label {
          width: 45% !important;
          max-width: 200px;
          font-size: 16px !important;
          text-align: left !important;
        }
      }
    }

    .introButton.simple {
      margin: 0 auto;
      display: block;
      width: initial;
      transform: translateX(0);

      h3 {
        font-size: 32px !important;
        line-height: 32px;
      }

      p {
        font-size: 18px;
        line-height: 20px;
      }

      .startIcon {
        width: 30px;
      }
    }
  }
}


</style>
