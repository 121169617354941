<template>
  <div class="badgePanel" :style="[{pointerEvents : opened ? 'auto' : 'none'}]">
      <button class="openButton simple" @click="open">
          <img class="icon" :src="badgeIcon" />
          <label>badges</label>
    </button>
    <div class="scrim" v-if="opened" @click="close"></div>
    <div class="content" v-if="opened">
        <button class="closeButton simple" @click="close"> close </button>
        <BadgeProgress class="badgeProgress" />
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex"
import Assets from "@/components/game/utils/assets"
import BadgeProgress from "./BadgeProgress"

export default {
  name: 'BadgePanel',
  components:{
      BadgeProgress,
  },
  data(){
      return{
          opened: false
      }
  },
  computed:{
      ...mapGetters({
        //   season : "season/currentSeason",
      }),
      badgeIcon(){
          return Assets.load("badges", "badge-icon", "svg")
      },
  },
  methods:{
      open(){
        this.opened = true;
        this.$announcer.set(this.$g("acc_badge_panel_opened"));
      },
      close(){
        this.opened = false;
        this.$announcer.set(this.$g("acc_badge_panel_closed"));
      },
      restart(){
        this.$store.dispatch("ecosystem/reset");
        this.$store.dispatch("season/reset");
        this.close();
      }
  }
}
</script>

<style scoped lang="scss">
@import "@/styles/_vars.scss";
@import "@/styles/game.scss";

.badgePanel{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
}

.openButton{
    position: absolute;
    left: 10px;
    bottom: 10px;
    pointer-events: auto;
    background-color: $color-teal !important;
    border-radius: 30px;
    padding: 8px;


    img{
      width: 35px;
      height: 35px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      margin-right: 10px;

    }

    label{
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }

    @media screen and (max-width: $mq-max-width-tablet) {
      width: 108px;
      // bottom: 60px;

      img {
        height: 18px;
        width: 18px;
      }

      label {
        margin-right: 0;
      }
    }
}

.scrim{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .6;
}

.content{
    position: absolute;
    padding: 60px;
    min-width: 450px;
    min-height: 600px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-white;
    border-radius: 15px;
    padding: 20px 50px 20px 50px;

    @media screen and (max-height: 600px) {
      min-height: unset;
      height: 90vh;
    }
}

.closeButton{
    position: absolute;
    right: 30px;
    top: 30px;
    font-size: 18px !important;
    color: $color-orange;
}

</style>
