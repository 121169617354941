<template>
    <section id="research" class="site-component">
        <h1 class="sr-only">{{ content.meta.page_title }}</h1>
        <article class="current-report content-container">
            <div class="context">
                <h2 class="h6 eyebrow-label">{{ report_content.eyebrow_label }}</h2>
                <h3 class="h2 text-orange">{{ report_content.headline }}</h3>
                <p>{{ report_content.body }}</p>
                <a class="button-round with-arrow" target="_blank" :href="report_content.report_url">
                    <span>{{ report_content.button_text }}</span>
                    <span class="icon-arrow" aria-hidden="true"></span>
                </a>
            </div>
            <aside class="chart">
                <h3 class="h5">{{ chart.title }}</h3>
                <figure>
                    <Chart :chartData="chart_data" :chartOptions="chart_options" ratioClass="ct-major-sixth"></Chart>
                    <figcaption>
                        <div class="key">
                            <span class="h6">
                                <span class="orange-key" aria-hidden="true"></span>{{ chart.data.datasets[0].name }}</span>
                            <span class="h6">
                                <span class="green-key" aria-hidden="true"></span>{{ chart.data.datasets[1].name }}</span>
                        </div>
                        <span>{{ chart.caption }}</span>
                    </figcaption>
                </figure>
            </aside>
        </article>
        <article class="research bg-gold">
            <div class="content-container">
                <div class="centered-header">
                    <h2 class="h6 eyebrow-label">{{ video_content.eyebrow_label }}</h2>
                    <h3 class="h1 text-green">{{ video_content.headline }}</h3>
                </div>
                <div class="videos">
                    <div v-for="(video, i) in video_content.videos" :key="i" class="video-container">
                        <h4 class="text-green">{{ video.title }}</h4>
                        <VideoPlayer
                            v-if="!video.use_image"
                            :index="i"
                            :video="video"
                            :video_content="video_content">
                        </VideoPlayer>
                        <figure class="alternate-image" v-else>
                            <img :src="video.alternate_image_filename" :alt="video.image_alt_text">
                        </figure>
                        <p v-if="!video.use_image">{{ video.description_header }}</p>
                        <p>{{ video.description }}</p>
                    </div>
                </div>
            </div>
        </article>
        <article class="info-section bg-light-aqua">
            <div class="content-container">
                <div class="centered-header">
                    <h2 class="h6 eyebrow-label">{{ research_links_content.eyebrow_label }}</h2>
                    <h3 class="h1 text-orange">{{ research_links_content.headline }}</h3>
                </div>

                <section class="report-links">
                    <div>
                        <h4 class="text-green">{{ research_links_content.reports_headline }}</h4>
                        <p>
                            {{ research_links_content.reports_text }}
                        </p>
                    </div>
                    <ul>
                        <li v-for="(r,i) in research_links_content.reports" :key="i">
                            <a :href="r.filename" target="_blank" class="button-squarish">{{ r.link_text }}</a>
                        </li>
                    </ul>
                </section>
                <section class="stats">
                    <div class="stat-block" v-for="(stat,i) in research_links_content.stats" :key="i">
                        <div class="stat-description">
                            <p>{{ stat.stat_description }}</p>
                        </div>
                        <figure>
                            <img :src="stat.image" :alt="stat.image_alt">
                            <figcaption class="stat-graphic-text">
                                <div v-if="stat.stat">
                                    <p class="stat text-orange">{{ stat.stat }}</p>
                                    <p class="description">{{ stat.stat_text}}</p>
                                </div>
                                <div v-else>
                                    <p class="stat">{{ stat.stat_line_1 }}</p>
                                    <p class="stat">{{ stat.stat_line_2 }}</p>
                                    <p class="description">{{ stat.stat_text}}</p>
                                </div>
                            </figcaption>
                        </figure>

                    </div>
                </section>
            </div>
        </article>
    </section>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer';
import Chart from '@/components/Chart';
import Chartist from 'chartist';
import ctAxisTitle from 'chartist-plugin-axistitle'

export default {
    name: "",
    components: {
        Chart,
        VideoPlayer
    },
    computed: {
        content () {
            let pageContent =  require("@/assets/data/research.json");
            let globalContent =  require("@/assets/data/global-content.json");

            return {...pageContent, ...globalContent}
        },
        report_content () {
            return this.content.report_section;
        },
        chart () {
            return this.report_content.graph;
        },
        video_content () {
            return this.content.mortality_section;
        },
        research_links_content () {
            return this.content.research_links_section;
        },
        chart_data () {
            return {
                labels: this.chart.data.labels,
                series: this.chart.data.datasets
            }
        },
        chart_options () {
            return  {
                lineSmooth: true,
                fullWidth: true,
                axisY: {
                    type: Chartist.FixedScaleAxis,
                    low: 0,
                    high: 10000,
                    onlyInteger: true,
                    offset: 60,
                    divisor: 10
                },
                chartPadding: {
                    right: 40,
                    left: 20,
                },
                plugins: [
                    Chartist.plugins.ctAxisTitle({
                        axisY: {
                            axisTitle: this.chart.y_axis_label,
                            axisClass: "ct-axis-title",
                            offset: {
                                x: 0,
                                y: 14   
                            },
                            flipTitle: true
                        }
                    })
                ]
            }
        }
    },
    head: {
        title: function() {
            return {
                inner: this.content.meta.page_title,
                separator: '|',
                complement: this.content.site_name
            }
        },
        meta: function() {
            return [
                {rel: 'canonical', href: this.content.meta.canonical_url, id: 'rel-con'},
                {name: 'description', content: this.content.meta.description, id: 'desc'},

                {property: 'og:title', content: this.content.meta.page_title, id: 'og-title'},
                {property: 'og:description', content: this.content.meta.description, id: 'og-desc'},
                {property: 'og:type', content: 'website', id: 'og-type'},
                {property: 'og:image', content: `${this.content.site_url}${this.content.meta.preview_image}`, id: 'og-image'}
            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/microsite.scss";

.site-component {
    margin-top: 100px;
}

.current-report {
    display: flex;

    .context {
        flex-basis: 40%;
        padding-right: $sixtyfivepx;

        .button-round {
            margin-top: $sixtyfivepx;
        }
    }

    .chart {
        flex-basis: 60%;
        figure {
            margin: $fortypx 0;
            
            .chart-container {
                height: unset;

                /deep/ foreignObject {
                    .ct-label.ct-horizontal {
                        display: none;
                    }
                    &:nth-child(5n+3) {
                        .ct-label.ct-horizontal {
                            display: block;
                        }
                    }
                }
            }

        }

        figcaption {
            font-size: $small-text;
            color: $color-dark-grey;
            line-height: $twentysixpx;
            opacity: .8;

            .key {
                display: flex;
                justify-content: space-evenly;
                margin-bottom: $fortypx;
                color: $color-blue-black;

                [class*='-key'] {
                    display: inline-block;
                    margin-right: .75rem;
                    height: 14px;
                    width: 14px;
                    border-radius: 50%;
                    background: $color-orange;

                    &.green-key {
                        background: $color-bright-green;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $mq-max-width-desktop-sml) {
        flex-direction: column;

        .context, .chart {
            flex-basis: unset;
        }

        .context {
            max-width: 600px;
            margin-bottom: $sixtyfivepx;
            padding-right: unset;
        }
    }

    @media screen and (max-width: $mq-max-width-mobile) {
        .context {
            .button-round {
                margin-top: $twentysixpx;
            }
        }
    }


}

.research {
    background: url('../assets/images/moose-tracks.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right 22rem;
    padding-bottom: $sixtyfivepx;

    h4 {
        margin-bottom: $twentysixpx;
    }
    .videos {
        display: flex;

        .video-container {
            flex-basis: 50%;

            &:first-child {
                padding-right: 25px;
            }
            &:last-child {
                padding-left: 25px;
            }

            .alternate-image {
                margin: 0;
                margin: 0;
                height: 24.9vw;
                overflow: hidden;
                max-height: 425px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        
        @media screen and (max-width: $mq-max-width-tablet) {
            flex-direction: column;

            .video-container {
                margin-bottom: $fortypx;
                &:last-child,
                &:first-child {
                    padding: 0;
                }

                .alternate-image {
                    width: 100vw;
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }

    @media screen and (max-width: $mq-max-width-mobile) {
        padding-bottom: 0;
    }
}

.info-section {
    .content-container {
        max-width: 1080px;
    }

    .report-links {
        display: flex;
        justify-content: space-between;
        padding-bottom: 4rem;
        border-bottom: solid 1px $color-white;

        div {
            flex-basis: 40%;
            padding-right: 2rem;

            h4 {
                max-width: 21ch;
            }
            p {
                font-size: $large-text;
                margin-top: 1rem;
            }
        }

        ul {
            height: 50px;
            flex-basis: 60%;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-top: 0;

            li {
                display: flex;
                align-items: flex-start;
                margin: .5rem;
            }
        }

        @media screen and (max-width: $mq-max-width-tablet) {
            padding-bottom: $fortypx;
        }

    }

    .stats {
        .stat-block {
            display: flex;
            height: 450px;
            margin: 2rem 0;

            .stat-description {
                display: flex;
                align-items: center;
            }

            p {
                margin-top: .3rem;
                margin-bottom: .3rem;
            }

            div {
                flex-basis: 40%;
            }

            figure {
                flex-basis: 60%;
                position: relative;
                display: flex;
                align-items: flex-end;
                margin: 0;

                img {
                    position: absolute;
                }

                .stat-graphic-text {
                    font-size: $large-text;
                    position: relative;
                    font-family: $font-family-header;
                    span {
                        display: block;
                        line-height: unset;
                    }
                    .stat {
                        font-size: 160px;
                        line-height: 120px;
                    }
                    .description {
                        text-transform: uppercase;
                        max-width: 18ch;
                        letter-spacing: .8px;
                    }
                }
            }

            &:nth-child(1) {
                figure {
                    img {
                        right: 0;
                        top: 0;
                    }
                    figcaption { 
                        margin-left: 120px;
                    }
                }
            }

            &:nth-child(even) {
                div {
                    order: 2;
                }

                figure {
                    .stat-graphic-text {
                        .description {
                            max-width: 26ch;
                        }
                    }
                }
            }

            &:nth-child(3) {
                height: 360px;

                .stat-graphic-text {
                    transform: translate(10px, -60px);
                    padding: 1rem;
                }
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                figcaption {
                    > div {
                        flex-direction: column;
                    }
                    background: $color-darker-orange;
                    padding: 3rem;
                    .stat {
                        color: $color-white;
                        font-size: 56px;
                        margin: 0;
                        line-height: 50px;
                    }
                    .description {
                        color: $color-blue-black;
                        max-width: 30ch;
                        width: 360px;
                    }
                }
            }
        }

    }

    @media screen and (max-width: $mq-max-width-desktop) {
        .stats {
            .stat-block {
                div {
                    flex-basis: 35%;
                }

                figure {
                    flex-basis: 55%;
                }
            }
        }
    }
    
    @media screen and (max-width: $mq-max-width-desktop-sml) {
        .stats {
            .stat-block {
                div {
                    flex-basis: 35%;
                    font-size: 1.35rem;
                }


                figure {
                    img {
                        width: 90%;
                    }
                }

                &:nth-child(1) {
                    figure figcaption {
                        margin-left: 0;
                    }
                }
                &:nth-child(2) {
                    margin-bottom: 40px;
                }
                &:nth-child(3) {
                    .stat-description {
                        flex-basis: 10%;
                    }
                }

            }
        }
    }

    @media screen and (max-width: $mq-max-width-tablet) {
        .report-links {
            ul {
                li {
                    .button-squarish {
                        margin: 0 .3rem;
                    }
                }
            }
        }

        .stats {
            .stat-block {
                display: block;
                margin: $sixtyfivepx 0;

                .stat-description {
                    max-width: 600px;
                    margin: 0 auto;
                }

                figure {
                    display: block;
                    width: 100%;
                    max-width: 500px;
                    height: 350px;
                    margin: 1rem auto;

                    img {
                        height: 100%;
                        width: auto;
                    }
                }

                &:nth-child(1) {
                    margin-top: $fortypx;
                    figure {
                        margin-left: auto;

                        figcaption {
                            margin-left: 0;
                        }
                    } 
                }
                &:nth-child(2) {
                    margin-bottom: 40px;
                    figure {
                        figcaption {
                            position: absolute;
                            bottom: -16px;
                        }
                    }
                }
                &:nth-child(3) {
                    .stat-description {
                        flex-basis: 10%;
                    }
                    figure {
                        margin: 2rem auto;
                        figcaption.stat-graphic-text {
                            width: 65%;
                            transform: translate(-10px, 80px);
                        }
                    }
                }

            }
        }
    }
    @media screen and (max-width: $mq-max-width-mobile) {
        .report-links { 
            flex-direction: column;
            ul {
                justify-content: space-between;

                li {
                    margin: .5rem 0;
                }
            }
        }
        .stats {
            .stat-block {
                margin: $twentysixpx 0;

                .stat-description {
                    text-align: left !important;
                }

                figure {
                    display: block;
                    width: 100%;
                    max-width: 500px;
                    height: 350px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }

                &:nth-child(1) {
                    margin-bottom: 200px;
                    figure {
                        margin-top: -10px;
                        figcaption {
                            position: absolute;
                            bottom: -80px;
                        }
                    } 
                }
                &:nth-child(2) {
                    margin-bottom: 240px;

                    .stat-description {
                        padding-bottom: 2rem;
                        box-sizing: border-box;
                    }

                    figure {
                        margin-top: -10px;
                         figcaption {
                            .stat {
                                font-size: 140px;
                            }
                         }

                        img {
                            object-position: left;
                        }
                    }

                }
                &:nth-child(3) {
                    margin-top: -4rem;
                    figure {
                        figcaption {
                            .stat {
                                font-size: 40px;
                                line-height: 40px;
                            }
                            .description {
                                width: unset;
                                line-height: 1.5rem;
                            }
                            &.stat-graphic-text {
                                transform: translate(0, 160px);                                
                                min-width: 210px;
                            }
                        }
                    }
                }

            }
        }
    }

    @media screen and (max-width: 340px) {
        .stats {
            .stat-block {

                &:nth-child(1) {
                    figure {
                        margin-top: -40px;
                    }
                }
                &:nth-child(2) {
                    figure {
                        margin-top: -40px;
                    }
                }
                &:nth-child(3) {
                    margin-top: 0;
                }
            }
        }
    }
}
</style>
