<script>
import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters({
          requiresLandscapeLock: "orientation/requiresLandscapeLock",
          fullScreen: "orientation/fullScreen",
          locked: "orientation/locked",
        })
    },
    methods: {
        //  makeFullScreen(e) {
        //     e.preventDefault();

        //     document.body.requestFullscreen()
        //         .then(
        //             success => {
        //                 this.$store.dispatch('orientation/fullScreen', true)
        //             },
        //             failure => {console.log('failed make site full screen', failure)}
        //         )
        // },
        lockOrientation () {
            if (process.browser) {

                window.screen.orientation 
                .lock("landscape")
                .then(
                    success => {
                        this.$store.dispatch('orientation/setLock', true)
                        console.log('rotated and locked in landscape');
                    },
                    failure => {console.log('failed to rotate and lock in landscape', failure)}
                )
            }
        },
        // unlockOrientation () {
        //     window.screen.orientation.unlock()
        //     setTimeout( () => {
        //         document.exitFullscreen();
        //     }, 0 );
        // },
        setLock (route) {
            let requiresLandscape = [
                'ecosystem',
                'introduction',
                'conclusion',
            ]
            
            if (requiresLandscape.includes(route)) {
                const match = window.matchMedia('(pointer:fine)');
                if (match && !match.matches) { 
                    this.$store.dispatch('orientation/requireLock', true)
                }
            } else {
                this.$store.dispatch('orientation/requireLock', false)
            }
        }
    },
    mounted () {
        if (this.locked) return;
        this.setLock(this.$route.name)

        if (this.fullScreen && this.requiresLandscapeLock) {
            this.lockOrientation();
        }
    }
}
</script>